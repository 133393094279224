import React from 'react'
import { RenderCell } from 'models/income.model'
import moment from 'moment'
import { history } from 'store'

const RenderBlockTable = ({
  row,
  rowIndex,
  column,
  isMultipharmaAccount,
  handleUpdateValidation,
  handleUpdatePaymentConfirmation,
  f,
  getTimestampPerMonth,
  transferPharmacyFees,
  handlePrint,
  setTimestampEnd,
  setTimestampStart
}: RenderCell): JSX.Element | any => {
  if (column.accessor === 'validation') {
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
        style={{ minWidth: '10.5rem', cursor: isMultipharmaAccount ? 'pointer' : 'auto' }}
        onClick={async () => (handleUpdateValidation != null) && await handleUpdateValidation(row[column.accessor], rowIndex, row.date)}
      >
        {row[column.accessor] === true ? f('yes') : f('waiting')}
      </td>
    )
  }

  if (column.accessor === 'payment_confirmed') {
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
        style={{ minWidth: '10.5rem', cursor: isMultipharmaAccount ? 'pointer' : 'auto' }}
        onClick={async () => (handleUpdatePaymentConfirmation != null) && await handleUpdatePaymentConfirmation(row[column.accessor], rowIndex, row.date)}
      >
        {row[column.accessor] === true ? f('yes') : f('no')}
      </td>
    )
  }
  if (column.accessor === 'détail') {
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
      >
        <button
          className='btn btn-light'
          onClick={() => {
            const times = getTimestampPerMonth?.(parseInt(moment(row.month).format('MM')), parseInt(moment(row.month).format('YYYY')))
            history.push({
              pathname: '/orders',
              search: `?start=${times.timestampStart}&end=${times.timestampEnd}&status=1`
            })
          }}
        >
          <span className='textDetail'>{f('seeOrders')}</span>
        </button>
      </td>
    )
  }

  if (column.accessor === 'Téléchargement') {
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
      >
        <button
          className='btn btn-light'
          onClick={() => {
            const times = getTimestampPerMonth?.(parseInt(moment(row.month).format('MM')), parseInt(moment(row.month).format('YYYY')))
            setTimestampStart?.(times.timestampStart)
            setTimestampEnd?.(times.timestampEnd)
            handlePrint()
          }}
        >
          <span className='textDetail'>Télécharger les commandes</span>
        </button>
      </td>
    )
  }

  if (column.accessor === 'facturation' && isMultipharmaAccount === true) {
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
      >
        <button
          className='btn btn-light'
          onClick={() => {
            transferPharmacyFees?.(parseInt(moment(row.month).format('MM')), parseInt(moment(row.month).format('YYYY')))
          }}
        >
          <span className='textDetail'>Effectuer le virement</span>
        </button>
      </td>
    )
  }
  if (column.accessor !== 'facturation') {
    return (
      <td key={`td${rowIndex}_${column.accessor}`} style={{ minWidth: '10.5rem' }}>
        {row[column.accessor]}
      </td>
    )
  } else {
    return (<span />)
  }
}

export default RenderBlockTable
