import moment, { Moment } from 'moment'
import 'moment/locale/es'

export interface OrdersRow {
  orderId: number
  pharmacyOrderId: number
  prescriptionId: number
  date: string
  dateOrder: Moment
  costsDetails: Number
  customerName: string
  customerPhone: string
  deliveryAddress: string
  deliveryAddress2: string
  deliveryMode: string
  deliveryScheduleEnd: string
  deliveryScheduleStart: string
  products: products[] | null
  state: number
  type: number
  tracking_code: string
  commentaryPharmacist: string
  shipped: boolean
  cancel_info?: string
  pharmacyName: string
  user_email: string
  pharmacyId: number
}

export interface products {
  EAN_code: Number
  //   category1: "hygiène & beauté";
  //   description: "le déodorant nuxe body est le premier déodorant efficacité minérale du laboratoire nuxe, sans alcool qui offre une protection longue durée sans laisser de traces blanches. 97% des ingrédients sont d'origine naturelle.profitez de -50% sur le 2... en savoir +";
  //   formattedPrice: "€16.04";
  //   id: 31481;
  //   img_link: "https://res.cloudinary.com/dal8dk91w/image/upload/v1582628832/PROD/img-product-4105.jpg";
  //   name: "nuxe body déodorant longue durée lot de 2";
  //   price: 16.04;
  //   priceNormal: 16.04;
  //   priceRaw: 14.99;
  //   priceReduced: 16.04;
  //   product_id: 4105;
  //   quantity: 1;
  //   reduction: 0;
  //   replace: true;
  //   subtitle: "nuxe";
}

export const typeWording = (type: number, language: string): string => {
  if (type == null) return 'Erreur'

  if (language === 'es') {
    switch (type) {
      case 0:
        return 'Para'
      case 1:
        return 'Receta'
      case 2:
        return 'Para & Receta'
      default:
        return 'Error'
    }
  } else {
    switch (type) {
      case 0:
        return 'Para'
      case 1:
        return 'Ordonnance'
      case 2:
        return 'Para & Ordonnance'
      default:
        return 'Erreur'
    }
  }
}
/**
 * Parses incoming orders from the API and generates data
 * @param para the orders with only para
 * @param prescription the orders with only prescription
 * @param paraAndPrescription the orders with para and prescription
 */
export const parseOrders = (
  para: Object[],
  prescription: Object[],
  paraAndPrescription: Object[],
  language: string
): OrdersRow[] => {
  // let tmpParaAndPrescription = paraAndPrescription;

  language === 'es' && moment.locale('es')
  const expeditionMsg = language === 'es' ? 'Envío D+1' : 'Expédition J+1'

  const tmpPara = para.map((order: any) => {
    const deliveryMode =
      order.deliveryTimeInformation.deliveryMode === '3 jours' ||
        order.deliveryTimeInformation.deliveryMode === '3 días'
        ? expeditionMsg
        : order.deliveryTimeInformation.deliveryMode

    return {
      orderId: order.orderId,
      pharmacyOrderId: order.pharmacyOrderId,
      prescriptionId: order.prescriptionId,
      date: moment(order.timestamp).format('ddd D MMM'),
      dateOrder: moment(order.timestamp),
      costsDetails: order.costsDetails.drugsCostRaw,
      state: order.state,
      customerName: `${order.deliveryAddress.firstname} ${order.deliveryAddress.lastname}`,
      customerPhone: order.deliveryAddress.phone_number,
      deliveryAddress: order.deliveryAddress.address,
      deliveryAddress2: order.deliveryAddress.address2,
      deliveryMode: deliveryMode,
      deliveryScheduleStart:
        order.deliveryTimeInformation.deliveryScheduleStart,
      deliveryScheduleEnd: order.deliveryTimeInformation.deliveryScheduleEnd,
      products: order.items,
      type: 0,
      tracking_code: order.tracking_code,
      commentaryPharmacist: order.commentaryPharmacist,
      shipped: order.shipped,
      cancelInfo: order.cancel_info,
      pharmacyName: order.pharmacyName,
      user_email: order.user_email,
      pharmacyId: order.pharmacyInformation.pharmacy_id ?? order.pharmacyInformation.pharmacyId
    }
  })

  const tmpPrescription = prescription.map((order: any) => ({
    orderId: order.orderId,
    pharmacyOrderId: order.pharmacyOrderId,
    prescriptionId: order.prescriptionId,
    date: moment(order.timestamp).format('ddd D MMM'),
    dateOrder: moment(order.timestamp),
    costsDetails: order.costsDetails.remainingCost != null ? order.costsDetails.remainingCost : order.costsDetails.finalPrice,
    state: order.state,
    customerName: `${order.userInformation.firstname} ${order.userInformation.lastname}`,
    customerPhone: order.userInformation.user_phone,
    deliveryAddress: order.deliveryAddressInformation.address,
    deliveryAddress2: order.deliveryAddressInformation.address2,
    deliveryMode: order.deliveryTimeInformation.deliveryMode === 'jPlusOne' ? expeditionMsg : order.deliveryTimeInformation.deliveryMode,
    deliveryScheduleStart: order.deliveryTimeInformation.deliveryScheduleStart,
    deliveryScheduleEnd: order.deliveryTimeInformation.deliveryScheduleEnd,
    products: null,
    type: 1,
    tracking_code: order.tracking_code,
    commentaryPharmacist: order.commentaryPharmacist,
    fileType: order.fileInformation.type,
    user_id: order.userInformation.user_id,
    recurrent: order.recurrent,
    shipped: order.shipped,
    cancelInfo: order.cancel_info,
    pharmacyName: order.pharmacyInformation.name,
    user_email: order.userInformation.user_email,
    pharmacyId: order.pharmacyInformation.pharmacy_id ?? order.pharmacyInformation.pharmacyId

  }))

  return tmpPara.concat(tmpPrescription)
}
