import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { RootState } from 'models/redux.model'
import SideMenu from 'components/SideMenu'
import SelectPharma from 'components/SelectPharma'
import WelComeTypeForm from 'components/WelcomeTypeForm'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { history } from 'store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'

const WelcomeMessagevoice = (): JSX.Element => {
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  return (
    <Wrapper className='dataContainer' style={{ height: '100%', background: '#FFF' }}>
      <SideMenu parent='welcomeMessage' />
      <ContainerWrapper>
        <Container fluid>
          <Row>
            <Col xs='8' className='bodyHeader'>
              <SelectPharma pharmacies={pharmacies} />
            </Col>
          </Row>

          <Row className='headerTitle'>
            <FontAwesomeIcon
              style={{ marginTop: '4px', cursor: 'pointer' }}
              icon={faArrowLeft} onClick={() => {
                history.push('/voicemail')
              }}
            />
            <h5>{f('updateWelcomeMessage2')}</h5>

          </Row>
          <Row>
            <WelComeTypeForm />
          </Row>
        </Container>
      </ContainerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0;
  width: 100%;
  display: block;
  .bodyWrapper {
    background: var(--gris-bleu);
    width: 100%;
    padding: 1rem;
    @media (min-width: 60rem) {
      min-width: 70%;
      max-width: calc(100% -20rem);
    }
    .bodyHeader {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      button {
        display: flex;
        align-items: center;
        svg {
          height: 1.5rem;
          margin-right: 0.2rem;
        }
      }
    }
  }


  .container-fluid {
    padding: 30px 20px 60px 20px;
    background-color: white;
  }

  @media (max-width: 769px) {
    flex-direction: column;
  }
  .headerTitle {
      
    h5 {
      color: var(--texte-bleu-fonce);
       margin-left: 1rem;

    }
    margin-left: 1rem;

  }

`

export default WelcomeMessagevoice
