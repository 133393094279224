import * as actionTypes from '../actions/actionTypes'

const initialState = {
  product: [],
  total: 0
}

const deleteProduct = (state, action) => {
  const newProducts = state.product.filter((elt) => elt.eanCode !== action.data.eanCode)
  const newPrice = state.total - action.data.price * action.data.quantity

  return {
    ...state,
    product: [...newProducts],
    total: newPrice
  }
}

const addProduct = (state, action) => {
  let check = false
  for (const product of state.product) {
    if (product.eanCode === action.data.eanCode) {
      product.quantity += 1
      check = true
    }
  }

  if (!check) {
    const _cart = action.data
    state.product.push(_cart)
  }

  return {
    ...state,
    total: state.total + action.data.price
  }
}

const updateProduct = (state, action) => {
  console.log(state)
  console.log(action)

  const index = state.product.findIndex((elt) => elt.eanCode === action.data.eanCode)
  state.product[index].quantity = action.data.quantity

  let newPrice = 0

  for (const product of state.product) {
    newPrice += product.price * product.quantity
  }

  return {
    ...state,
    total: newPrice
  }
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_PRODUCT_ITEM:
      return addProduct(state, action)
    case actionTypes.DELETE_PRODUCT_ITEM:
      return deleteProduct(state, action)
    case actionTypes.UPDATE_PRODUCT_ITEM:
      return updateProduct(state, action)
    case actionTypes.CLEAR_PRODUCTS:
      return { product: [], total: 0 }
    default:
      return state
  }
}

export default productReducer
