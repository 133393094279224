import React from 'react'
import { Col } from 'reactstrap'
import { FirstContent, RateContainer, SecondContent } from 'assets/styles/landingPage/Rates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { stylesLandingPage } from '../../constants/styles.constants'
import { useIntl } from 'react-intl'

const Rates: React.FC<{}> = () => {
  const { formatMessage, locale } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  return (
    <div className='container'>
      <div className='row mt-lg-4 align-items-center'>
        <div className='col-lg-4 col-md-12 text-center text-lg-start'>

          <FirstContent className=' mb-4 mb-lg-0 pb-2 pb-lg-0'>
            <h4 className=' mb-4'>{f('rates')}</h4>
            <p className='para-desc mx-auto mb-2'>{f('at')}<span className='text-primary'> Phacil</span> {f('ratesText1')} </p>
            <a href='https://share-eu1.hsforms.com/19Gqqz6YOSgK1GUNP1jKljgf2aid' target='_blank' className='btn btn-primary mt-4' rel='noreferrer'>{f('requestAquote')}
              {/* <span className='badge rounded-pill bg-danger ms-2'>v4.2</span> */}
            </a>
          </FirstContent>

        </div>

        <div className='col-lg-8 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0'>
          <RateContainer className='ms-lg-5'>
            <div className='row align-items-center'>
              <SecondContent className='col-md-6 col-12 px-md-0 rounded'>

                <div className='card  rounded border-0'>
                  <div className='card-body py-4 rounded' style={{ borderBottom: '3px solid #513af2' }}>
                    <h6 className='text-uppercase mb-4'>{f('rateTitle2')}</h6>
                    <div className='d-flex mb-4' style={{ justifyContent: 'center' }}>
                      <span className='h4 mb-2'>{f('ratesSubtitle2')}</span>
                    </div>

                    <ul className='list-unstyled mb-2 ps-0'>
                      <li className='h6 text-muted mb-'>
                        <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                        <span className='icon h5 me-2' /> {f('ratesItem1')}
                      </li>
                      <li className='h6 text-muted mb-2'>
                        <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                        <span className='icon h5 me-2' />{f('ratesItem2')}
                      </li>
                      <li className='h6 text-muted mb-2'>
                        <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                        <span className='icon h5 me-2' />{f('ratesItem3')}
                      </li>
                      <li className='h6 text-muted mb-2'>
                        <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                        <span className='icon h5 me-2' />{f('ratesItem4')}
                      </li>
                    </ul>
                    <a href='https://share-eu1.hsforms.com/19Gqqz6YOSgK1GUNP1jKljgf2aid' target='_blank' className='btn btn-primary mt-4' rel='noreferrer'>{f('contactAnExpert')}</a>
                    <br />
                    <h5 className='mt-4'><a href={f('hrefTelContact')}>{f('telContact')}</a></h5>
                  </div>
                </div>
              </SecondContent>

              <Col className='col-md-6 col-12 mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0'>
                <SecondContent className='card pricing pricing-primary bg-light  rounded border-0'>
                  <div className='card-body py-5'>
                    <h6 className='title name fw-bold text-uppercase mb-4'>{f('rateTitle3')}</h6>
                    <div className='d-flex mb-4' style={{ justifyContent: 'center' }}>
                      <span className='price h1 mb-2'>{f('free')}</span>
                    </div>

                    {locale === 'es' ? (
                      <ul className='list-unstyled mb-2 ps-0'>
                        <li className='h6 text-muted mb-2'>
                          <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                          <span className='icon h5 me-2' /> {f('ratesItem1')}
                        </li>
                        <li className='h6 text-muted mb-2'>
                          <FontAwesomeIcon icon={faCheck} style={{ color: stylesLandingPage.color_phacil }} />
                          <span className='icon h5 me-2' /> {f('ratesItem4')}
                        </li>
                        <li className='h6 text-muted mb-2'>
                          <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                          <span className='icon h5 me-2' /> {f('ratesItem5')}
                        </li>
                        <li className='h6 text-muted mb-2'>
                          <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                          <span className='icon h5 me-2' /> {f('ratesItem6')}
                        </li>
                        <li className='h6 text-muted mb-2'>
                          <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                          <span className='icon h5 me-2' /> {f('ratesItem2')}
                        </li>
                      </ul>
                    )
                      : (
                        <ul className='list-unstyled mb-2 ps-0'>
                          <li className='h6 text-muted mb-2'>
                            <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                            <span className='icon h5 me-2' /> {f('ratesItem5')}
                          </li>
                          <li className='h6 text-muted mb-2'>
                            <FontAwesomeIcon icon={faCheck} style={{ color: stylesLandingPage.color_phacil }} />
                            <span className='icon h5 me-2' /> {f('ratesItem6')}
                          </li>
                          <li className='h6 text-muted mb-2'>
                            <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                            <span className='icon h5 me-2' /> {f('ratesItem7')}
                          </li>
                          <li className='h6 text-muted mb-2'>
                            <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                            <span className='icon h5 me-2' /> {f('ratesItem8')}
                          </li>
                          <li className='h6 text-muted mb-2'>
                            <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                            <span className='icon h5 me-2' /> {f('ratesItem9')}
                          </li>
                        </ul>
                      )}
                    <a href='https://share-eu1.hsforms.com/19Gqqz6YOSgK1GUNP1jKljgf2aid' target='_blank' className='btn btn-primary mt-4' rel='noreferrer'>{f('tryNow')}</a>
                  </div>
                </SecondContent>
              </Col>

            </div>
          </RateContainer>
        </div>
      </div>
    </div>

  )
}
export default Rates
