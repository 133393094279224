import React, { useState, useEffect } from 'react'
import SendCommentary from './SendCommentary'
import API from '../../utils/API'

import socket from '../../utils/socket'

import { MessageState } from 'models/message.model'

interface userInformation {
  user_name: string
}

interface CommentaryChatProps {
  orderId: number
  pharmacyOrderId: number
  messageList: MessageState
  userInformation: userInformation
  pharmacyName: string
  pharmacyId: number
  type: number
}

const CommentaryChat = (props: CommentaryChatProps): JSX.Element => {
  // Room initialized with the orderId to get a specific room for each order
  const room = `${props.orderId.toString()}t${props.pharmacyOrderId.toString()}`

  // Username initialized later with get response
  const [userName, setUserName] = useState('')
  const [message, setMessage] = useState('')
  const [messageList, setMessageList] = useState<Object[]>([])

  useEffect(() => { connectToRoom() }, [])
  useEffect(() => { socket.on('receive_message', (data: any) => setMessageList([...messageList, data])) })

  useEffect(() => {
    if (
      props.messageList.messages != null &&
      props.messageList.messages.length > 0 &&
      Object.values(props.userInformation).length > 0
    ) {
      setUserName(props.userInformation.user_name)

      setMessageList(
        props.messageList.messages.map((key: any) => {
          return {
            message: key.messages,
            author: key.author,
            time: key.time ?? key.time
          }
        })
      )
    } else if (Object.values(props.userInformation).length > 0) {
      setUserName(props.userInformation.user_name)
    }
  }, [props.messageList, props.userInformation])

  const connectToRoom = (): void => {
    socket.emit('join_room', room)
  }

  const quitRoom = (): void => {
    // Tells server user left room and triggers event associated
    socket.emit('quit_room', room)
    // Closes the socket
    socket.disconnect()
    // Clean messageList
    setMessageList([])
  }

  const sendMessage = async (): Promise<void> => {
    const current = new Date().toLocaleString()
    const messageContent = {
      room: room,
      content: {
        author: userName,
        message: message,
        time: current
      }
    }

    // Send a message to the user's current room
    socket.emit('send_message', messageContent)
    setMessageList([...messageList, messageContent.content])

    API.post(`/chat/commentaries/send/${room}`, {
      messages: messageContent.content.message,
      author: messageContent.content.author,
      time: messageContent.content.time,
      orderId: props.orderId,
      pharmacyOrderId: props.pharmacyOrderId,
      type: props.type,
      origin: 'pharmacist'
    })
      .catch((e: Error) => {
        console.log(`Send commentary to Phacil failed: ${e.message}`)
      })

    setMessage('')
  }

  return (
    <div>
      <div>
        <div>
          <SendCommentary
            messageList={messageList}
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
            quitRoom={quitRoom}
            orderId={props.orderId}
          />
        </div>
      </div>
    </div>
  )
}

export default CommentaryChat
