import React, { useState, useEffect } from 'react'
import SideMenu from '../../components/SideMenu'
import OrderManagemetStepOne from './OrderManagementStepOne'
import OrderManagemetStepTwo from './OrderManagementStepTwo'
import OrderManagementStepThree from './OrderManagementStepThree'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'reactstrap'
import { useIntl } from 'react-intl'
import JsBarcode from 'jsbarcode'

import API from 'utils/API'
import { alertError, alertLoading, alertSuccess } from 'utils/notifications'

import step from '../../assets/images/step.svg'
import { clearProduct } from '../../actions/product'

import { StyledContainer } from './OrderManagementStyledComponents'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'

const OrderManagement = () => {
  const selectedPharma = useSelector(state => state.user.selectedPharma) // the current pharmacy selected
  const listProduct = useSelector(state => state.product?.product)
  const [isDisabled, setIsDisabled] = useState(false)

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const dispatch = useDispatch()

  const date = new Date()
  const components = [
    date.getYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes()
  ]

  const order_id = components.join('')

  const [stepOrder, setStepOrder] = useState([])

  const [dataCreateOrder, setDataCreateOrder] = useState({
    order_id: order_id
  })

  const [dataUser, setDataUser] = useState({})
  const [errorStepOne, setErrorStepOne] = useState(false)
  const [valueCoupon, setValueCoupon] = useState('')
  const [checkCoupon, setCheckCoupon] = useState(0)

  useEffect(() => {
    const getUserMe = async () => {
      try {
        const res = await API.get('/mobile/admin/auth/me')
        if (res.data.success) {
          setDataCreateOrder(dataCreateOrder => ({
            ...dataCreateOrder,
            pharmacy_id: selectedPharma.pharmacy_id
          }))
        }
      } catch (err) {
        console.log(err)
      }
    }

    getUserMe()
  }, [selectedPharma.pharmacy_id])

  const nextStep = index => {
    const tmpArr = [...stepOrder]
    tmpArr.push(index)
    setStepOrder(tmpArr)
  }

  const previewStep = index => {
    const tmpArr = [...stepOrder]
    const indexTmp = tmpArr.indexOf(index)
    if (indexTmp > -1) {
      tmpArr.splice(indexTmp, 1)
    }
    setStepOrder(tmpArr)
  }

  const onSubmitBarcode = async value => {
    try {
      setValueCoupon(value)
      const res = await API.post('/mobile/admin/user/get-by-card', {
        pharmacy_id: dataCreateOrder.pharmacy_id,
        card_id: value
      })

      if (res?.data?.success) {
        setDataCreateOrder({
          ...dataCreateOrder,
          user_id: res?.data?.data?.user_id
        })
        setDataUser(res?.data?.data)
        // JsBarcode('#barcode', order_id)
        setErrorStepOne(false)

        nextStep(1)
      }
    } catch (err) {
      alertError(f('cardUserNotFound'))
      if (err?.success === false) {
        setErrorStepOne(true)
      }
    }
  }

  const confirmCreateOrder = async () => {
    setIsDisabled(true)
    alertLoading(f('orderInProgress'))
    try {
      const res = await API.post('/mobile/admin/order/create', {
        ...dataCreateOrder,
        card_id: valueCoupon,
        coupon_id: checkCoupon || '',
        product_ids: listProduct
      })

      if (res?.data?.success) {
        setStepOrder([])
        alertSuccess(f('orderConfirmed'))
        dispatch(clearProduct())
      } else {
        alertError(f('orderNotConfirmed'))
      }
      setIsDisabled(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <StyledContainer>
      <Helmet>
        <title>{f('manageOrderUserMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('manageOrderUsertMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>

      <div className='OrderManagementWrapper dataContainer'>
        <div className='bodyWrapper'>
          <SideMenu parent='User' />
          <div className='containerWrapper orderManager'>
            <Container fluid>
              <div className='block-bg-item'>
                {
                  dataUser?.user_id &&
                    <div className='block-bg-profile'>
                      <div className='profile'>

                        <FontAwesomeIcon
                          icon={faUser}
                          size='3x'
                          color='#2a8bf2'
                          style={{ marginBottom: '0.3rem' }}
                        />
                      </div>
                      <div className='informations'>
                        <p>{dataUser.user.lastname.toUpperCase()}</p>
                        <p>{dataUser.user.firstname}</p>
                        <p>{dataUser.user.email}</p>
                      </div>
                    </div>
                }
                <div className='process-bar-step'>
                  <div className='process-bar-step__item active'>
                    <span className='text'>{f('customerInfo')}</span>
                    <span className='step'>
                      <img src={step} alt='' />
                    </span>
                  </div>
                  <div
                    className={`process-bar-step__item ${
                    [1].every(elm => stepOrder.includes(elm)) ? 'active' : ''
                  }`}
                  >
                    <span className='text'>{f('productInfo')}</span>
                    <span className='step'>
                      <img src={step} alt='' />
                    </span>
                  </div>
                  <div
                    className={`process-bar-step__item ${
                    [2].every(elm => stepOrder.includes(elm)) ? 'active' : ''
                  }`}
                  >
                    <span className='text'>{f('paymentInfo')}</span>
                    <span className='step'>
                      <img src={step} alt='' />
                    </span>
                  </div>
                </div>
                {/* <div className="block-step-one">
                <div className="block-text-main">
                  <p className="block-text-main__title">
                    {f("ID Cart")}: {dataCreateOrder?.order_id}
                  </p>
                </div>
              </div> */}
                {stepOrder?.length === 0 && (
                  <OrderManagemetStepOne
                    error={errorStepOne}
                    onSubmitBarcode={onSubmitBarcode}
                    nextStepOne={() => nextStep(1)}
                    dataCreateOrder={dataCreateOrder}
                  />
                )}
                {stepOrder?.length === 1 && (
                  <OrderManagemetStepTwo
                    previewStepOne={() => previewStep(1)}
                    nextStepTwo={() => nextStep(2)}
                    dataCreateOrder={dataCreateOrder}
                  />
                )}
                {stepOrder?.length === 2 && (
                  <OrderManagementStepThree
                    previewStepTwo={() => previewStep(2)}
                    nextStepThree={() => nextStep(3)}
                    setCheckCoupon={setCheckCoupon}
                    valueCoupon={valueCoupon}
                    dataCreateOrder={dataCreateOrder}
                    checkCoupon={checkCoupon}
                    confirmCreateOrder={confirmCreateOrder}
                    isDisabled={isDisabled}
                  />
                )}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </StyledContainer>
  )
}

export default OrderManagement
