import React from 'react'
import { orderFileInfo, RenderCellOrder } from 'models/table.model'
import { statutWording, statutColor } from '../../utils/status'
import { typeWording } from 'utils/ordersHandler'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck, faCircleNotch, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import devKeys from '../../config/dev'

const OrdersTable = ({
  row,
  rowIndex,
  column,
  filesNames,
  locale,
  selectOrderId,
  iconCircle,
  setPharmacyOrderIdToShow,
  setOrderIdToShow,
  checkTextSelected,
  editShipped

}: RenderCellOrder): JSX.Element => {
  const handleClick = (): void => {
    if (setPharmacyOrderIdToShow != null) {
      setPharmacyOrderIdToShow(parseInt(row.pharmacyOrderId))
    }
    if (setOrderIdToShow != null) {
      setOrderIdToShow(parseInt(row.orderId))
    }
    if (checkTextSelected != null) {
      checkTextSelected()
    }
  }

  if (column.accessor === 'file') {
    return (
      <td key={`td${rowIndex}_${column.accessor}`} className='space'>
        {((filesNames?.[rowIndex]) != null)
          ? filesNames[rowIndex].map((elem: orderFileInfo) => {
            return (
              <div key={rowIndex}>
                <p
                  onClick={() => {
                    window.open(
                      devKeys.baseUrl + `/api/file/download/${elem.id}`
                    )
                  }}
                >
                  -{elem.name}
                </p>
                <br />
              </div>
            )
          })
          : null}
      </td>
    )
  } else if (column.accessor === 'state') {
    return (
      <td
        style={{ minWidth: '7rem' }}
        key={`td${rowIndex}_${column.accessor}`}
        onClick={() => {
          handleClick()
        }}
      >
        <MarkWrapper property={statutColor(row[column.accessor])}>
          {locale != null && statutWording(row[column.accessor], locale)}
        </MarkWrapper>
      </td>
    )
  } else if (column.accessor === 'orderId') {
    return (
      <td
        style={{ minWidth: '11rem' }}
        key={`td${rowIndex}_${column.accessor}`}
        onClick={() => {
          handleClick()
        }}
      >
        {row[column.accessor]}
        {
          (row.type === 1 && selectOrderId !== row.orderId && row.state > 1 && row.state < 6)
            ? <StyledFontAwesomeIconOrder size='sm' color='green' icon={faCheckCircle} />
            : (
              selectOrderId === row.orderId &&
                <StyledFontAwesomeIconOrder
                  size='sm'
                  color={iconCircle === 'faCheckCircle' ? row.state > 1 && row.state < 6 ? 'green' : 'white' : ''}
                  icon={iconCircle === 'faCircleNotch' ? faCircleNotch : faCheckCircle}
                  spin={iconCircle === 'faCircleNotch' && true}
                />
            )
        }
      </td>
    )
  } else if (column.accessor === 'type') {
    return (
      <td
        style={{ minWidth: '12rem' }}
        key={`td${rowIndex}_${column.accessor}`}
        onClick={() => {
          handleClick()
        }}
        className='space'
      >
        {locale != null && typeWording(parseInt(row[column.accessor]), locale)}
      </td>
    )
  } else if (column.accessor === 'deliveryMode') {
    return (
      <CenteredTd
        key={`td${rowIndex}_${column.accessor}`}
        onClick={() => {
          handleClick()
        }}
      >
        <span>{row[column.accessor]}</span>
        {row[column.accessor] !== undefined &&
          (row.deliveryMode === 'Expédition J+1' || row.deliveryMode === 'Envío D+1') &&
          (
            <StyledFontAwesomeIconOrder
              size='sm'
              icon={faTruck}
              color={row.shipped ? 'green' : 'red'}
              onClick={(e) => {
                e.stopPropagation()
                editShipped?.(
                  parseInt(row.orderId),
                  parseInt(row.pharmacyOrderId),
                  parseInt(row.type),
                  !row.shipped,
                  row.pharmacyName,
                  'pharmacist'
                )
              }}
            />
          )}
      </CenteredTd>
    )
  } else {
    let classNameValue = ''
    column.accessor !== 'state' && (classNameValue = 'space')
    if (column.accessor === 'date' || column.accessor === 'tracking_code') {
      (classNameValue = 'minWidth')
    }
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
        onClick={() => {
          handleClick()
        }}
        className={classNameValue}
      >
        {row[column.accessor]}
      </td>
    )
  }
}

const StyledFontAwesomeIconOrder = styled(FontAwesomeIcon)`
  margin-left: 1rem;
  margin-top: 0.25rem;
  cursor: pointer;
`

const CenteredTd = styled.td`
  min-width: 10rem;
  text-align: center;
`

const MarkWrapper = styled.mark`
background: ${props => props.property} !important;
padding: 4px 8px;
border-radius: 10px;
`

export default OrdersTable
