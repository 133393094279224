import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ModalHeader, ModalFooter, Row, Col, Modal } from 'reactstrap'
import config from '../../config/dev'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import moment, { Moment } from 'moment'
import API from '../../utils/API'
import fr from 'date-fns/locale/fr'
import { alertSuccess, alertError } from '../../utils/notifications'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { UpdatableUser, Patient } from 'models/user.model'

import { useIntl } from 'react-intl'

import socket, { CONNECTION_PORT } from 'utils/socket'
import { motifs } from 'models/appointment.model'
import { AnyAaaaRecord } from 'dns'

registerLocale('fr', fr)
setDefaultLocale('fr')

interface BookedPatientProps {
  roomPatientModal: string
  isOpen: boolean
  closeModal: () => void
  backdrop: boolean
  pharmaId: number
  pharmacyName: string
  getData: (id: number) => void
  slotInfoDate?: string | Date | null
}

interface Fields {
  name: string
  firstname: string
  email: string
  phone: string
  motif: string
  // startDate?: Date;
  user: string
}

interface Time {
  start: string
  end: string
  value: string
  hoursStart: number
  minutesStart: number
  hoursEnd: number
  minutesEnd: number
}

interface DatalistPatient {
  userId: number
  title: string
}

const BookedPatientModal: React.FC<BookedPatientProps> = ({
  roomPatientModal,
  isOpen,
  closeModal,
  backdrop,
  pharmaId,
  pharmacyName,
  getData,
  slotInfoDate
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [displayError, setDisplayError] = useState<boolean>(false)
  const [datalistPatients, setDatalistPatients] = useState<DatalistPatient[]>(
    []
  )
  const [isUser, setIsUser] = useState(false)
  const [user, setUser] = useState<UpdatableUser>()

  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const [appointmentStart, setAppointmentStart] = useState<Date>(new Date())
  const [appointmentEnd, setAppointmentEnd] = useState<Date>(new Date())
  const [indexPreselected, setIndexPreselected] = useState<number>(-1)
  const [motif, setMotif] = useState<string>('')
  const [optionMotifs, setOptionMotifs] = useState<any>('')
  const [display, setDisplay] = useState<string>('none')
  const [availableDates, setAvailableDates] = useState<any>()



  const [hours, setHours] = useState<Time[]>([])

  const preSelectedHours = (date: string | Date | null | undefined) => {
    let findIndex = false
    if (date) {
      const startHours = moment(date).format('H:mm')
      for (const index in hours) {
        if (hours[index].start === startHours.toString()) {
          setIndexPreselected(parseInt(index))
          findIndex = true
        }
      }
      if (!findIndex) {
        setIndexPreselected(-1)
      }
    } else {
      setIndexPreselected(-1)
    }
  }

  /**
   * Calculates the minimum date for the scheduled delivery
   */
  const calculateMinDate = () => {
    const today: Moment = moment()
    return today
  }

  const [selectedDay, setSelectedDay] = useState<Moment>(calculateMinDate())

  useEffect(() => {
    // If socket was needed to avoid undefined error
    // because socket's not initialized in useEffect
    if (socket) {
      socket.emit('join_room', roomPatientModal)
    }
  }, [CONNECTION_PORT])

  useEffect(() => {
    socket.on('reload_booked', () => {
      getAvailableDateAppointment()
      getAvailableAppointment(selectedDay)
      getData(pharmaId)
    })
  })

  const [schedule, setSchedule] = useState('')

  /**
   * Prepare format Date to db
   */

  const parseAppointmentDate = (item: Time) => {
    setIsSelected(true)

    const dateHoursStart = moment(selectedDay)
      .hours(item.hoursStart)
      .minutes(item.minutesStart)
      .seconds(0)
    const appointmentStart = new Date(moment(dateHoursStart).format())

    const dateHoursEnd = moment(selectedDay)
      .hours(item.hoursEnd)
      .minutes(item.minutesEnd)
      .seconds(0)
    const appointmentEnd = new Date(moment(dateHoursEnd).format())

    setAppointmentStart(appointmentStart)
    setAppointmentEnd(appointmentEnd)
  }

  /**
   * Disable color arround preselected time
   */
  const disableSelectedTime = () => {
    const element: HTMLElement | null = document.getElementById(
      `button-${indexPreselected.toString()}`
    )
    element?.classList.remove('creneauSelected')
    element?.classList.add('creneauButton')
  }

  /**
   * Compares if two dates are on the same day
   * @param {Date} first - the first date compared
   * @param {Date} second - the second date compared
   */
  const datesAreOnSameDay = (first: Moment, second: Moment) =>
    first.isSame(second, 'day')

  const buttonHours = hours.map(function (item, i) {
    const today = moment()

    if (datesAreOnSameDay(today, selectedDay)) {
      if (today.hour() < parseInt(item.value)) {
        return (
          <button
            type='button'
            className={
              indexPreselected !== -1
                ? 'creneauSelected'
                : 'creneauButton' || schedule
                  ? schedule.includes(item.start) && schedule.includes(item.end)
                    ? 'creneauSelected'
                    : 'creneauButton'
                  : 'creneauButton'
            }
            key={i}
            id={`button-${i.toString()}`}
            onClick={async () => {
              disableSelectedTime()
              parseAppointmentDate(item)
            }}
          >
            {item.start} - {item.end}
          </button>
        )
      }
    } else {
      return (
        <button
          type='button'
          className={
            indexPreselected === i
              ? 'creneauSelected'
              : 'creneauButton' || schedule
                ? schedule.includes(item.start) && schedule.includes(item.end)
                  ? 'creneauSelected'
                  : 'creneauButton'
                : 'creneauButton'
          }
          key={i}
          id={`button-${i.toString()}`}
          onClick={async () => {
            disableSelectedTime()
            parseAppointmentDate(item)
          }}
        >
          {item.start} - {item.end}
        </button>
      )
    }

    return null
  })

  const getUser = (
    id: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    API.get(`/prescription/user/${id}`)
      .then((res) => {
        if (res.data.success) {
          setUser(res.data.result)
          setFieldValue('name', res.data.result.lastname)
          setFieldValue('firstname', res.data.result.firstname)
          setFieldValue('email', res.data.result.email)
          setFieldValue('phone', res.data.result.phone_number)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getPatients = () => {
    API.get('/patient')
      .then((res) => {
        if (res.data.success) {
          parseDatalistUser(res.data.patients)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const createAppointment = (fields: Fields, user: UpdatableUser) => {
    API.post('/appointment/createAdminPharmacist', {
      pharmacy_id: pharmaId,
      pharmacyName: pharmacyName,
      user,
      appointment_start: appointmentStart,
      appointment_end: appointmentEnd,
      date: moment(selectedDay).format('YYYY-MM-DD'),
      motif: fields.motif
    })
      .then(async (res) => {
        if (res.data.success) {
          closeModal()
          alertSuccess(f('rdvTaken'))
          setIsUser(false)
          getData(pharmaId)
          // getAppointmentBooked(selectedDay);
          await socket.emit('reload_booked', roomPatientModal)
        }
      })
      .catch((err) => {
        console.log(err)
        alertError(f('rdvError'))
      })
  }

  const createUser = (fields: Fields) => {
    API.post('/users/signup', {
      email: fields.email,
      password: 'test',
      firstname: fields.firstname,
      lastname: fields.name,
      phone_number: fields.phone,
      user_role: 1
    })
      .then(async (res) => {
        if (res.data.success) {
          createAppointment(fields, res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
        alertError(f('userCreateError'))
      })
  }

  const handleSubmit = async (fields: Fields) => {
    if (!isSelected) {
      setDisplayError(true)
    } else {
      setIsSelected(false)
      setDisplayError(false)

      isUser
        ? user?.user_id && createAppointment(fields, user)
        : createUser(fields)
    }
  }

  const getAvailableAppointment = (date: Moment) => {
    API.post('unlockedAppointment/getAvailableAppointment', {
      pharmacy_id: pharmaId,
      date: moment(date).format('YYYY-MM-DD'),
      motif
    })
      .then((res) => {
        if (res.data.success) {
          setHours(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const getMotifAvailablleByDate = (date: Moment) => {
    API.post('unlockedAppointment/getMotifAvailablleByDate', {
      pharmacy_id: pharmaId,
      date: moment(date).format('YYYY-MM-DD'),
    })
      .then((res) => {
        if (res.data.success) {
          setOptionMotifs(res.data.motifs)
          if (!res.data.motifs.includes(motif)) {
            setMotif("")
            setDisplay("none")
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {

    setDisplayError(false)
    getPatients()
    if (slotInfoDate) {
      getMotifAvailablleByDate(moment(slotInfoDate))
      setSelectedDay(moment(slotInfoDate))
      getAvailableAppointment(moment(slotInfoDate))
    } else {
      setOptionMotifs(motifs)
      getAvailableAppointment(selectedDay)
    }
    getAvailableDateAppointment()
    preSelectedHours(slotInfoDate)
  }, [isOpen, motif])

  const parseDatalistUser = (patients: Patient[]) => {
    // console.log("patient", patients);
    const result: any = []
    for (const patient of patients) {
      (patient.user != null) &&
        patient.user.firstname !== null &&
        patient.user.lastname !== null &&
        result.push({
          userId: patient.user_id,
          title: `${patient.user.firstname} ${patient.user.lastname}`
        })
    }
    setDatalistPatients(result)
  }

  const resetValue = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setIsUser(false)
    setFieldValue('name', '')
    setFieldValue('firstname', '')
    setFieldValue('phone', '')
    setFieldValue('email', '')
  }

  const defineDisplay = (choice: string) => {
    if (choice !== '' && choice !== '-') {
      setDisplay('')
    } else {
      setDisplay('none')
    }
  }

  const getAvailableDateAppointment = () => {
    API.post('unlockedAppointment/getAvailableDateAppointment', {
      pharmacy_id: pharmaId,
      motif

    })
      .then((res) => {
        if (res.data.success) {
          setAvailableDates(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }



  const defineExcludeDate = (date: Date) => {
    let disable = true
    const momentDate = moment(date).format('YYYY-MM-DD')

    if (availableDates) {
      for (const availableDate of availableDates) {
        const startDate = moment(availableDate.start_date).format('YYYY-MM-DD')
        const endDate = moment(availableDate.end_date).format('YYYY-MM-DD')

        if (momentDate >= startDate && momentDate <= endDate) {
          disable = false
        }
      }
    }
    return disable
  }


  const getDayClassName = (date: Date) => (defineExcludeDate(date) ? "disable" : "");


  return (
    <div>
      <ModalWrapper
        isOpen={isOpen}
        toggle={closeModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={backdrop}
      >
        <ModalHeader className='motif'>{f('rdvPatient')}</ModalHeader>
        <Formik
          initialValues={{
            user: '',
            name: '',
            firstname: '',
            email: '',
            phone: '',
            motif: motif,
            startDate: selectedDay.toDate()
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(f('pleaseLastName')),
            firstname: Yup.string().required(f('pleaseFirstName')),
            email: Yup.string()
              .email(f('validEmail'))
              .required(f('pleaseEmail')),
            phone: Yup.string()
              .length(10, f('validPhone'))
              .required(f('pleasePhone')),
            motif: Yup.string().required(f('pleaseRdvSubject')),
            startDate: Yup.date().required(f('pleaseRdvDate'))
          })}
          onSubmit={(fields) => {
            handleSubmit(fields)
          }}
        >
          {({ errors, status, touched, setFieldValue }) => (
            <Form>
              <Row className='motifInput'>
                <Col md='6'>
                  <div className='form-group'>
                    <label htmlFor='motif'>{f('subject')}</label>
                    <Field
                      name='motif'
                      as="select"

                      onChange={(e: any) => {
                        setFieldValue("motif", e.target.value)
                        setMotif(e.target.value)
                        defineDisplay(e.target.value)
                      }}
                      className={
                        'form-control' +
                        (errors.motif && touched.motif ? ' is-invalid' : '')
                      }
                    >
                      <option>-</option>
                      {optionMotifs.map((el: any, i: React.Key | null | undefined) => {
                        return el === motif ? <option selected key={i}>{el}</option> : <option key={i}>{el}</option>
                      })}

                    </Field>
                    <ErrorMessage
                      name='motif'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ display }}>
                <Col md='4'>
                  <div className='form-group'>
                    <label htmlFor='user'>{f('patient')}</label>

                    <Autocomplete
                      id='grouped-demo'
                      options={datalistPatients}
                      getOptionLabel={(option: DatalistPatient) => option.title}
                      renderInput={(params: any) => (
                        <TextField {...params} className='form-control' />
                      )}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: DatalistPatient | null
                      ) => {
                        (newValue != null) && getUser(newValue.userId, setFieldValue)
                        setIsUser(true)
                        setFieldValue('user', newValue?.title)
                        newValue?.title === undefined &&
                          resetValue(setFieldValue)

                        indexPreselected !== -1 &&
                          parseAppointmentDate(hours[indexPreselected])
                      }}
                    />
                  </div>
                </Col>

                <Col md='4'>
                  <div className='form-group'>
                    <label htmlFor='lastName'>{f('lastName')}</label>
                    <Field
                      name='name'
                      type='text'
                      // value={user && user.lastname}
                      className={
                        'form-control' +
                        (errors.name && touched.name ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage
                      name='name'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>
                <Col md='4'>
                  <div className='form-group'>
                    <label htmlFor='firstname'>{f('firstName')}</label>
                    <Field
                      name='firstname'
                      type='text'
                      // value={user ? user.firstname : ""}
                      className={
                        'form-control' +
                        (errors.firstname && touched.firstname
                          ? ' is-invalid'
                          : '')
                      }
                    />
                    <ErrorMessage
                      name='firstname'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ display }}>
                <Col md='4'>
                  <div className='form-group'>
                    <label htmlFor='email'>{f('mail')}</label>
                    <Field
                      name='email'
                      type='email'
                      // value={user ? user.email : ""}
                      className={
                        'form-control' +
                        (errors.email && touched.email ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage
                      name='email'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>

                <Col md='4'>
                  <div className='form-group'>
                    <label htmlFor='phone'>{f('phone')}</label>

                    <Field
                      name='phone'
                      type='text'
                      // value={user ? user.phone_number : ""}
                      className={
                        'form-control' +
                        (errors.phone && touched.phone ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage
                      name='phone'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>
                <Col md='4'>
                  <div className='form-group'>
                    <label htmlFor='date'>{f('date')}</label>
                    <Field
                      name='startDate'
                      type='date'
                      component={DatePicker}
                      locale='fr'
                      dateFormat='P'
                      selected={selectedDay.toDate()}
                      minDate={calculateMinDate().toDate()}
                      dayClassName={getDayClassName}
                      allowSameDay={false}
                      onSelect={(date: Date) => {
                        setSchedule('')

                        setSelectedDay(moment(date))
                        setDisplayError(false)
                        getAvailableAppointment(moment(date))
                      }}
                      className={
                        'form-control' +
                        ((errors.startDate != null) && (touched.startDate != null)
                          ? ' is-invalid'
                          : '')
                      }
                    />
                    <ErrorMessage
                      name='startDate'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ display }}>
                <Col md='12' className='hoursLab'>
                  <label>{f('whatTime')}</label>
                </Col>

                <AppointmentContainer>
                  <Row className='rowCreneau'>
                    <Col xs='12' md='12' className='colCreneauHours'>
                      {buttonHours}
                    </Col>
                    <br />
                    {displayError && (
                      <p className='errorMEssage'>{f('pleaseBlock')}</p>
                    )}
                  </Row>
                </AppointmentContainer>

              </Row>

              <ModalFooter>
                <div className='form-group'>
                  <button type='submit'
                    className='btn btnPrimary mr-2'
                    disabled={!!(motif === '-' || motif === '')}
                  >
                    {f('confirm')}
                  </button>
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalWrapper>
    </div >
  )
}

export default BookedPatientModal

const ModalWrapper = styled(Modal)`
  padding: 1rem;
  p {
    font-size: 0.9rem;
  }
  .modal-content {
    padding: 1rem;
  }
  .hoursLab {
    text-align: center;
  }

  .motif {
    color: var(--texte-bleu-fonce);
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  Label {
    color: var(--texte-table);
    font-weight: 700;
    font-size: 0.9rem;
  }
  input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }
  .react-datepicker__input-container input {
    padding-left: 10px;
    width: 105px;
    margin-bottom: 1rem;
  }
  .date {
    margin-bottom: 1rem;
  }
  .motifInput {
    justify-content: center;
    margin-bottom:1rem;
  }
  .disable {
    cursor: default;
    color: #ccc;
    background-color: transparent;
 
   }
 
`

const AppointmentContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 0 0 1rem 0;
  background: var(--grey-white);
  color: var(--dark-blue);
  border: 1px solid var(--alternative-blue-2);
  border-radius: 5px;
  padding: 1rem;
  font-size: 0.9rem;

  .rowCreneauDay {
    margin-bottom: 1rem;
  }
  .rowCreneauDay > div {
    display: flex;
    align-items: center;
  }
  .labelCreneauDay,
  .creneauHours {
    font-size: 0.7rem;
    color: var(--dark-blue);
  }
  #creneauDay {
    padding: 5px 20px 5px 5px;
    font-size: 0.7rem;
    font-weight: 300;
    color: var(--alternative-blue-2);
    border: 1px solid var(--alternative-blue-2);
  }
  .labelCreneauDay {
    margin: 0 10px 5px 0;
  }

  .creneauHours {
    margin-bottom: 10px;
    margin-right: 5px;
  }
  .colCreneauHours {
    .creneauButton {
      width: 103px;
      padding: 7px 6px;
      color: var(--alternative-blue-2);
      border: 1px solid var(--alternative-blue-2);
      background-color: transparent;
      font-size: 1rem;
      font-weight: 300;
    }
    .creneauSelected {
      width: 100px;
      padding: 7px 6px;
      margin: 0 5px 5px 0;
      color: var(--alternative-blue-2);
      border: 1px solid #3d75ae;
      box-shadow: 0.5px 0.5px;
      background-color: white;
      font-size: 1rem;
      font-weight: 300;
    }
  }
  .react-datepicker__input-container input {
    border: 1px solid var(--alternative-blue-2);
    padding-left: 10px;
    width: 105px;
  }

  @media (max-width: 575px) {
    padding: 0.5rem;
    .rowCreneauDay > div {
      justify-content: center;
    }
    .rowCreneau > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0;
    }
  }
  .errorMEssage {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 11.2px;
    color: #dc3545;
    text-align: center;
  }
`
