import { ErrorMessage, Field, Formik } from 'formik'
import React from 'react'
import DatePicker from 'react-datepicker'
import { Col, Input } from 'reactstrap'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'

import { FormFilter } from 'assets/styles/filter/Date'
import { FormDateProps } from 'models/filter.model'
import moment, { Moment } from 'moment'

const FormDate: React.FC<FormDateProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setEqualDate,
  setBeforeDate,
  setAfterDate,
  typeDate,
  getSelectedDate,
  setTypeDate
}) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const handleChangeDate = (typeDate: number, date: Moment): void => {
    switch (typeDate) {
      case 1:
        return setEqualDate(date)
      case 2:
        return setAfterDate(date)
      case 3:
        return setBeforeDate(date)
    }
  }

  return (
    <Formik

      initialValues={{
        startDate: '',
        endDate: ''
      }}
      validationSchema={Yup.object().shape({
        startDate: Yup.date(),
        endDate: Yup.date()
      })}
      onSubmit={(fields) => {
      }}
    >

      {({ errors, touched }) => (
        <FormFilter>
          <Input
            type='select'
            value={typeDate}
            onChange={(e) => { setTypeDate(parseInt(e.target.value)) }}
            className='form-control-select'
          >
            <option value={0} selected>{f('between')}</option>
            <option value={1}>{f('equal')}</option>
            <option value={2}>{f('after')}</option>
            <option value={3}>{f('beforeFilter')}</option>

          </Input>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {typeDate === 0
              ? <>
                <Col md='5'>
                  <Field
                    name='startDate'
                    type='date'
                    component={DatePicker}
                    locale='fr'
                    dateFormat='P'
                    allowSameDay={false}
                    placeholderText={f('placeholderFilter')}
                    selected={startDate.toDate()}
                    onSelect={(date: Date) => {
                      setStartDate(moment(date))
                    }}
                    className={
                      'form-control' +
                      ((errors.startDate != null) && (touched.startDate != null)
                        ? ' is-invalid'
                        : '')
                    }
                  />

                  <ErrorMessage
                    name='startDate'
                    component='div'
                    className='invalid-feedback'
                  />
                </Col>
                <span>{f('and')}</span>
                <Col md='5'>
                  <div className='form-group'>
                    <Field
                      name='startDate'
                      type='date'
                      component={DatePicker}
                      locale='fr'
                      dateFormat='P'
                      allowSameDay={false}
                      selected={endDate.toDate()}
                      default
                      placeholderText={f('placeholderFilter')}
                      onSelect={(date: Date) => {
                        setEndDate(moment(date))
                      }}
                      className={
                        'form-control' +
                        ((errors.startDate != null) && (touched.startDate != null)
                          ? ' is-invalid'
                          : '')
                      }
                    />
                    <ErrorMessage
                      name='startDate'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>
              </>
              : <div style={{ display: 'flex', justifyContent: 'center' }}>

                <Col md='12'>
                  <div className='form-group'>
                    <Field
                      name='equalDate'
                      type='date'
                      component={DatePicker}
                      locale='fr'
                      dateFormat='P'
                      allowSameDay={false}
                      selected={getSelectedDate(typeDate).toDate()}
                      default
                      placeholderText={f('placeholderFilter')}
                      onSelect={(date: Date) => {
                        handleChangeDate(typeDate, moment(date))
                      }}
                      className={
                        'form-control' +
                        ((errors.startDate != null) && (touched.startDate != null)
                          ? ' is-invalid'
                          : '')
                      }
                    />
                    <ErrorMessage
                      name='startDate'
                      component='div'
                      className='invalid-feedback'
                    />
                  </div>
                </Col>
              </div>}

          </div>

        </FormFilter>
      )}

    </Formik>

  )
}

export default FormDate
