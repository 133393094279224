import React from 'react'
import { Table, Button } from 'reactstrap'
import styled from 'styled-components'
import {
  Trash2 as DeleteIcon
} from 'react-feather'

import { Row, TableProps } from 'models/table.model'

import Pagination from './Pagination'

import RenderCell from 'components/RenderCell'
import HeaderTable from 'components/HeaderTable'

import { sortChangedHandler } from 'utils/sortHandler'

const Wrapper = styled.div`
  width: 100%;
`

const StyledTable = styled(Table)`
  background-color: white;
  width: 100%;
  thead th {
    border-left: 1px solid #e6e6f2;
    border-right: 1px solid #e6e6f2;
    padding: 0.75rem !important;
  }

  thead th:first-child {
    padding-left:  0.75rem !important;
  }

  thead {
    font-size: 0.9rem;
    color: var(--texte-bleu-fonce);
    th div {
      display: flex;
      align-items: center;
    }
  }

  tbody,
  tbody input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }

  tr {
    width: 100%;
    div {
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      svg {
        width: 1rem;
        margin-left: 0.5rem;
      }
    }
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(230, 230, 242, 0.5);
  }
  td {
    padding: 0.75rem !important;
    vertical-align: middle;
    padding: 0 0.5rem;
    &:focus-visible {
      background: white;
    }
    border: 1px solid #e6e6f2;
  }
  tbody {
    max-height: 40rem;
    button {
      margin: 0.5rem 0;
    }
    svg {
      width: 1rem;
    }
  }
`

const BlockTable: React.FC<TableProps> = ({
  columns,
  data,
  setSortCriteria,
  sortCriteria,
  pageSize,
  offset,
  setOffset,
  onDeleteItem
}) => {
  // Use the state and functions returned from useTable to build your UI
  const dataToShow = data.slice((offset as number) * (pageSize as number), (pageSize as number) * ((offset as number) + 1))

  const pageCount = data.length === 0 ? 1 : Math.ceil(data.length / (pageSize as number))

  // Render the UI for your table
  return (
    <Wrapper>
      <StyledTable responsive striped>
        <thead>
          <tr>
            <HeaderTable
              columns={columns}
              sortCriteria={sortCriteria}
              sortChangedHandler={sortChangedHandler}
              setSortCriteria={setSortCriteria}
              thEqual={false}
              thAccessor={null}
              thClassname={null}
              sortDescOrAsc='DESC'
            />
          </tr>
        </thead>
        <tbody>
          {dataToShow.map((row: Row, i: number) => (
            <tr key={`body_tr_${i}`}>
              {columns.map((column) => {
                return <RenderCell key={i} row={row} rowIndex={i} column={column} page='blockTable' />
              })}

              <td key={`delete_${i}`}>
                <Button
                  color='danger'
                  onClick={() => {
                    if (onDeleteItem != null) {
                      onDeleteItem(row)
                    }
                  }}
                >
                  <DeleteIcon />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <Pagination
        onGoToPage={(newPage) => setOffset?.(newPage)}
        onNavigate={(step) => setOffset?.((prevValue) => prevValue + step)}
        offset={offset != null ? offset : 0}
        pageCount={pageCount}
      />
    </Wrapper>
  )
}

export default BlockTable
