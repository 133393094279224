import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useIntl } from 'react-intl'

const NotificationConfirmModal = (props: any) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>{f('Confirmation')}</ModalHeader>
        <ModalBody>
          {props.isNotifiable
            ? <>
              {f('sendNotif')} <strong> {props.productName}</strong> {f('toYourPatients')}<br />
              {f('reduction')} <strong>{props.reduction}</strong>%.
            </>

            : <>{f('notificationAlreadySent')}</>}

        </ModalBody>
        <ModalFooter style={{ borderTop: 'unset' }}>
          <Button hidden={!props.isNotifiable} color='primary' onClick={() => props.sendPromotionNotificationTest(props.idPromoNotifications) && props.toggle()}>{f('yes')}</Button>
          <Button color='secondary' onClick={props.toggle}>{f('cancel')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default NotificationConfirmModal
