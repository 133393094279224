import React, { useEffect, useState } from 'react'
import { Table, Button, DropdownItem, PopoverBody } from 'reactstrap'
import styled from 'styled-components'
import {
  Bell
} from 'react-feather'

import Pagination from './Pagination'
import { Row, TableProps } from 'models/table.model'
import RenderCell from 'components/RenderCell'
import { sortChangedHandler } from 'utils/sortHandler'
import HeaderTable from 'components/HeaderTable'
import { PopoverWrapperActionsStocks } from 'assets/styles/filter'
import { useIntl } from 'react-intl'

const Wrapper = styled.div`
  width: 100%;
`

const StyledTable = styled(Table)`
  background-color: white;
  width: 100%;

  thead {
    font-size: 0.9rem;
    color: var(--texte-bleu-fonce);
    th div {
      display: flex;
      align-items: center;
    }
  }

  tbody,
  tbody input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }

  tr {
    width: 100%;
    div {
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      svg {
        width: 1rem;
        margin-left: 0.5rem;
      }
    }
  }
  td {
    vertical-align: middle;
    padding: 0 0.5rem;
    &:focus-visible {
      background: white;
    }
  }
  tbody {
    max-height: 40rem;
    svg {
      width: 1rem;
    }
  }
  th  {
    border-top: none !important;
    border-bottom: none !important;
    padding-bottom: 0.5rem;
  }
  .space{
   padding-left: 1.9rem ;
  }
  .loading{
    text-align: center;
    justify-content: center;
    align-items: center
  }

`

const ProductsTable: React.FC<TableProps> = ({
  columns,
  data,
  dataCount,
  onCellChange,
  setSortCriteria,
  sortCriteria,
  onDeleteItem,
  canSendNotification,
  sendPromotionNotification,
  pageSize,
  offset,
  setOffset,
  setIsLoading
}) => {
  // Use the state and functions returned from useTable to build your UI
  const pageCount = data.length === 0 ? 1 : Math.ceil((dataCount as number) / (pageSize as number))
  const dataToShow = data
  const [isOpenPopover, setIsOpenPopover] = useState(true)
  const toggle = (): void => setIsOpenPopover(!isOpenPopover)
  const [rowSelected, setRowSelected] = useState(-1)

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  useEffect(() => {
    setIsOpenPopover(true)
  }, [rowSelected])

  // Render the UI for your table
  return (
    <Wrapper>
      <StyledTable responsive size='sm'>
        <thead>
          <tr>
            <HeaderTable
              columns={columns}
              sortCriteria={sortCriteria}
              sortChangedHandler={sortChangedHandler}
              setSortCriteria={setSortCriteria}
              thEqual={false}
              thAccessor='name'
              thClassname='space'
              sortDescOrAsc='DESC'
            />
            {canSendNotification != null && canSendNotification ? <th> </th> : null}
            <th>{f('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {dataToShow.map((row: Row, i: number) => (
            <tr key={`body_tr_${i}`}>
              {columns.map((column) => {
                return <RenderCell key={i} row={row} rowIndex={i} column={column} page='stock' onCellChange={onCellChange} />
              })}

              {canSendNotification != null && row.category1 !== 'médicaments'
                ? (
                  <td key={`notif_promo_${i}`} className='space'>
                    <Button
                      color='secondary'
                      onClick={() => sendPromotionNotification?.(row.product_id, row.name, row.reduction)}
                    >
                      <Bell />
                    </Button>
                  </td>
                ) : <td />}
              <td key={`actions_${i}`} className='space'>
                <Button
                  color='link'
                  id={`popover_${i}`}
                  style={{ textDecoration: 'unset', color: 'unset', fontWeight: 800 }}
                  onClick={() => setRowSelected(row.id)}
                >
                  ...
                </Button>
                {
                  rowSelected === row.id && (
                    <PopoverWrapperActionsStocks property='stock' trigger='legacy' placement='bottom-end' isOpen={isOpenPopover} target={`popover_${i}`} toggle={() => toggle()}>
                      <PopoverBody>
                        <DropdownItem
                          onClick={() => onDeleteItem?.(row)}
                        >{f('delete')}
                        </DropdownItem>
                      </PopoverBody>
                    </PopoverWrapperActionsStocks>
                  )
                }

              </td>
            </tr>
          ))}
        </tbody>
        <Pagination
          onGoToPage={(newPage) => {
            setOffset?.(newPage)
            setIsLoading?.(true)
          }}
          onNavigate={(step) => {
            setOffset?.((prevValue) => prevValue + step)
            setIsLoading?.(true)
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }}
          offset={offset != null ? offset : 0}
          pageCount={pageCount}

        />
      </StyledTable>

    </Wrapper>
  )
}

export default ProductsTable
