import { UpdatableUser } from './user.model'

export interface AppointmentState {
  id?: number
  pharmacy_id?: number
  appointment_start?: Date
  appointment_end?: Date
  date?: Date
  motif?: string
  createdAt?: Date
  updatedAt?: Date
  user?: UpdatableUser
}

export interface Event {
  id?: number
  title?: string
  allDay?: boolean
  start?: Date
  end?: Date
  user?: UpdatableUser
  motif?: string
}

export interface Messages {
  allDay?: string
  previous?: string
  next?: string
  today?: string
  month?: string
  week?: string
  day?: string
  agenda?: string
  date?: string
  time?: string
  event?: string
}

export interface AvailableDate {
  start_date: Date
  end_date: Date
}

export interface BlockSlot {
  id: number
  start_date: Date
  end_date: Date
  motif: String
}

export const motifs = [
  "Vaccin anti-COVID-19", "Test antigénique COVID-19", "Autre (Bilan, Vaccination, ect.)"
]
