import { Moment } from 'moment'
import React from 'react'
import { Col, Row, CustomInput, Dropdown, DropdownToggle } from 'reactstrap'
import FormDate from './FormDate'
import { history } from 'store'
import { FilterHeader, GroupFilter, PopoverWrapper } from 'assets/styles/filter'
import { DropDownMenuDate } from 'assets/styles/filter/Date'
import { FilterModalProps } from 'models/filter.model'
import { useIntl } from 'react-intl'

const FilterModal: React.FC<FilterModalProps> = ({
  isOpenFilter,
  openDate,
  typeDate,
  startDate,
  endDate,
  equalDate,
  beforeDate,
  afterDate,
  setStartDate,
  setEndDate,
  setEqualDate,
  setBeforeDate,
  setAfterDate,
  setTypeDate,
  toggleFilter,
  setIsOpenFilter,
  setOpenDate

}) => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const getSelectedDate = (type: number): Moment | undefined => {
    switch (type) {
      case 1:
        return equalDate
      case 2:
        return afterDate
      case 3:
        return beforeDate
    }
  }
  return (
    <PopoverWrapper placement='bottom' trigger='legacy' isOpen={isOpenFilter} target='popoverFilter' toggle={toggleFilter}>
      <FilterHeader>
        <Col>
          <button
            type='reset' className='btn btnRemoveFilter mr-2'
            onClick={() => {
              setOpenDate(false)
            }}
          >
            {f('deleteFilter')}
          </button>
        </Col>
        <Col>
          <span style={{ fontSize: '15px' }}>{f('filters')}</span>
        </Col>
        <Col>
          <button
            type='submit' className='btn btnConfirmFilter' onClick={() => {
              // Make a component
              const start = startDate?.startOf('date').unix()
              const end = endDate?.endOf('date').unix()
              const selectedDateStart = getSelectedDate(typeDate)?.startOf('date').unix()
              const selectedDateEnd = getSelectedDate(typeDate)?.endOf('date').unix()

              const location = { pathname: '/orders', search: '' }

              if (openDate) {
                if (typeDate === 0) {
                  location.search = `?start=${start}&end=${end}`
                } else if (typeDate === 1) {
                  location.search = `?start=${selectedDateStart}&end=${selectedDateEnd}`
                } else if (typeDate === 2) {
                  location.search = `?start=${selectedDateStart}`
                } else if (typeDate === 3) {
                  location.search = `?end=${selectedDateEnd}`
                }
              }
              history.push(location)
              // getData(pharmaId)
              setOpenDate(false)
              setIsOpenFilter(false)
            }}
          >
            {f('completed')}
          </button>
        </Col>
      </FilterHeader>
      <Row>
        <Col>
          <Dropdown isOpen={openDate} disabled={false} toggle={() => toggleFilter}>
            <DropdownToggle hidden />
            <GroupFilter check>
              <CustomInput
                type='checkbox' className='titleCheckbox' id='checkbox_data' label={f('dateBetween')} checked={openDate}
                onChange={() => { setOpenDate(!openDate) }}
              />
            </GroupFilter>
            <DropDownMenuDate>
              <FormDate
                setStartDate={setStartDate} setEndDate={setEndDate}
                setEqualDate={setEqualDate} setBeforeDate={setBeforeDate} setAfterDate={setAfterDate}
                startDate={startDate} endDate={endDate}
                typeDate={typeDate}
                getSelectedDate={getSelectedDate}
                setTypeDate={setTypeDate}
              />
            </DropDownMenuDate>
          </Dropdown>

        </Col>
      </Row>

    </PopoverWrapper>
  )
}

export default FilterModal
