import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardWrapper } from 'assets/styles/CardWrapper'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'
import { LoaderWrapper } from 'assets/styles/LoaderWrapper'
import { OrdersWrapper } from 'assets/styles/OrdersWrapper'
import { FiltersAboveTable } from 'assets/styles/StockStyledComponents'
import SelectPharma from 'components/SelectPharma'
import { RootState } from 'models/redux.model'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  CardBody,
  Container,
  Row,
  Col
} from 'reactstrap'
import styled from 'styled-components'
import SideMenu from '../components/SideMenu'

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import Filters from 'components/DashboardKPIsTable/Filters'
import { Column } from 'models/table.model'
import DashboardKPIsTable from 'components/DashboardKPIsTable'
import API from 'utils/API'
import { useLocation } from 'react-router'
import { Kpis } from 'models/kpis.model'
const loadingIcon = 'images/loading-gif-phacil.svg'

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-top: 8px;
  margin-left: 10px;
  cursor: pointer;
`

const DashboardKPIs = () => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })
  const search = useLocation().search

  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)

  const motifsByLanguage = [f('allVoiceMail')]
  const motifs = ['Tous']

  const [isLoading, SetIsLoading] = useState(true)

  const [filteredRows, setFilteredRows] = useState<Kpis[]>([])
  const [referenceRows, setReferenceRows] = useState<Kpis[]>([])

  const [count, setCount] = useState(0)
  const pageSize = 10
  const [offset, setOffset] = useState(0)
  const [activeTab, setActiveTab] = useState(0)

  const [sortCriteria, setSortCriteria] = useState<{
    direction: 'DESC' | 'ASC'
    accessor: string
  } | null>(null)

  const columns: Column[] = [
    {
      Header: f('date'),
      accessor: 'formattedMonth'
    },
    {
      Header: f('numberOrders'),
      accessor: 'numberOrders'
    }

  ]
  /** CRUD FUNCTIONS */
  const getData = async (pharmaId: number): Promise<any> => {
    try {
      SetIsLoading(true)

      const start = new URLSearchParams(search).get('start')
      const end = new URLSearchParams(search).get('end')
      if (selectedPharma == null || selectedPharma.pharmacy_id === -1) return

      const response = await API.get(`/kpis/get/orders/${pharmaId}?start=${start}&end=${end}`)
      setReferenceRows(response.data.data)
      setFilteredRows(response.data.data)
      setCount(response.data.data.length)
      SetIsLoading(false)
    } catch (e: any) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (selectedPharma.pharmacy_id !== -1) {
      getData(selectedPharma.pharmacy_id).catch(err => console.log('err: ', err))
    }
  }, [selectedPharma])
  return (
    <OrdersWrapper property='dashboardKPIs' className='dataContainer'>
      <SideMenu parent='KPIs' />
      <ContainerWrapper>
        <Container fluid>
          <Row style={{ justifyContent: 'space-between' }}>

            <SelectPharma pharmacies={pharmacies} />

          </Row>
          <Row className='headerTitle'>
            <h2>{f('kpisDashboard')}</h2>
            <StyledFontAwesomeIcon
              icon={faSyncAlt}
              onClick={() => {
                getData(selectedPharma.pharmacy_id).catch(err => console.log('err: ', err))
              }}
            />

          </Row>

          <Row>
            <Col xs='12'>
              <CardWrapper dashboardKPIs>
                <CardBody>
                  <FiltersAboveTable>
                    <Filters
                      motifsByLanguage={motifsByLanguage}
                      motifs={motifs}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      referenceRows={referenceRows}
                      setFilteredRows={setFilteredRows}
                      setOffset={setOffset}
                    />
                  </FiltersAboveTable>
                  {isLoading ? (
                    <LoaderWrapper title='dashboardKPIs'>
                      <h5>{f('loadingKpis')}</h5>
                      <img src={loadingIcon} alt='loading' width='35' />
                    </LoaderWrapper>
                  )
                    : (
                      <DashboardKPIsTable
                        sortCriteria={sortCriteria}
                        setSortCriteria={setSortCriteria}
                        columns={columns}
                        data={filteredRows}
                        offset={offset}
                        setOffset={setOffset}
                        pageCount={Math.ceil(count / pageSize)}
                        getData={getData}
                        pageSize={pageSize}
                        activeTab={activeTab}
                      />
                    )}
                </CardBody>
              </CardWrapper>
            </Col>
          </Row>
        </Container>
      </ContainerWrapper>
    </OrdersWrapper>
  )
}

export default DashboardKPIs
