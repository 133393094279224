import React, { useEffect, useState } from 'react'
import SideMenu from '../components/SideMenu'
import { OrdersWrapper } from 'assets/styles/OrdersWrapper'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'
import { CardBody, Col, Container, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { RootState } from 'models/redux.model'
import SelectPharma from 'components/SelectPharma'
import { CardWrapper } from 'assets/styles/CardWrapper'
import { FiltersAboveTable } from 'assets/styles/StockStyledComponents'
import { useIntl } from 'react-intl'
import Filters from 'components/Notifications/Filters'
import ToggleSwitch from 'components/Notifications/ToggleSwitch'
import API from 'utils/API'
import { alertError, alertSuccess } from 'utils/notifications'

const Notifications = () => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)
  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma)

  const motifsByLanguage = [f('orders')]
  const motifs = ['Commandes']
  const [activeTab, setActiveTab] = useState(0)

  const [isMail, setIsMail] = useState(true)
  const [isSms, setIsSms] = useState(false)
  const [isChrome, setIsChrome] = useState(true)

  useEffect(() => {
    const orderMail = selectedPharma.pharmacy?.pharmacy_notification?.order_mail ?? true
    const orderSms = selectedPharma.pharmacy?.pharmacy_notification?.order_sms ?? false
    const orderChrome = selectedPharma.pharmacy?.pharmacy_notification?.order_chrome ?? true

    setIsMail(orderMail)
    setIsSms(orderSms)
    setIsChrome(orderChrome)
  }, [selectedPharma])

  const handleUpdateValidation = async (title: string): Promise<void> => {
    try {
      const data = {
        pharmacy_id: selectedPharma.pharmacy_id,
        order_mail: isMail,
        order_sms: isSms,
        order_chrome: isChrome
      }
      switch (title) {
        case f('mailNotif'):
          data.order_mail = !isMail
          break
        case 'SMS':
          data.order_sms = !isSms
          break
        case 'Chrome':
          data.order_chrome = !isChrome
          break
      }
      const resullt = await API.post('/pharmacy/notifications/insert', data)

      if (resullt.data.success === true) {
        alertSuccess(f('successNotification'))
      } else {
        alertError(`${f('errorNotification')} ${title.toLocaleLowerCase()}`)
      }
    } catch (e: any) {
      console.log(e)
    }
  }
  return (
    <OrdersWrapper property='notifications' className='dataContainer'>
      <SideMenu parent='notifications' />
      <ContainerWrapper>
        <Container fluid>
          <Row style={{ justifyContent: 'space-between' }}>

            <SelectPharma pharmacies={pharmacies} />

          </Row>
          <Row className='headerTitle'>
            <h2>{f('notifications')}</h2>
          </Row>

          <Row>
            <Col xs='12'>
              <CardWrapper notifications>
                <CardBody>
                  <FiltersAboveTable>
                    <Filters
                      motifsByLanguage={motifsByLanguage}
                      motifs={motifs}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  </FiltersAboveTable>

                  <div className='notifications'>
                    <ToggleSwitch
                      title={f('mailNotif')} isChecked={isMail} toggle={async () => {
                        setIsMail(!isMail)
                        await handleUpdateValidation(f('mailNotif'))
                      }}
                    />
                    <ToggleSwitch
                      title='SMS' isChecked={isSms} toggle={async () => {
                        setIsSms(!isSms)
                        await handleUpdateValidation('SMS')
                      }}
                    />
                    <ToggleSwitch
                      title='Chrome' isChecked={isChrome} toggle={async () => {
                        setIsChrome(!isChrome)
                        await handleUpdateValidation('Chrome')
                      }}
                    />
                  </div>
                </CardBody>
              </CardWrapper>
            </Col>
          </Row>
        </Container>
      </ContainerWrapper>
    </OrdersWrapper>
  )
}

export default Notifications
