import React from 'react'
import { stt } from '../../../helpers/table'
// import Loading from "./LoadingTable";
import ScrollContainer from 'react-indiana-drag-scroll'

import { StyledContainer } from './TableMainStyledComponents'

const TableMain = ({
  tableHeaders,
  tableData,
  tablePaginate,
  isLoading,
  fixed
}) => {
  const initTableRow = (data, index) => {
    const idx = index

    return (
      <tr key={index}>
        {tableHeaders.map(function (header, index) {
          let value = data
          if (header.index) {
            value = data[header.index]
          }

          if (header.callback) {
            value = header.callback(value)
          }

          if (tablePaginate && header.index && header.index === 'stt') {
            value = stt(
              idx,
              tablePaginate.current_page,
              tablePaginate.per_page
            )
          }
          if (header.show === false) {
            return
          }
          return (
            <td
              key={index}
              {...header.option}
              className={`td-body ${header?.option?.className}`}
            >
              {value}
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <StyledContainer>
      <ScrollContainer
        className={`scrollable  ${fixed && 'table-fixed'}`}
        vertical={false}
        hideScrollbars={false}
      >
        <table className='table table-main auto mb-0'>
          <thead className='table-thead'>
            <tr>
              {tableHeaders.map(function (header, index) {
                if (header.show === false) {
                  return
                }
                return (
                  <th
                    key={index}
                    className={`th-header ${header?.option?.className || ''}`}
                  >
                    {header.label}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className='table-tbody'>
            {isLoading && (
              <tr>
                <td className='no-result' colSpan={tableHeaders.length}>
                  {/* <Loading count="10" /> */}
                  Loading...
                </td>
              </tr>
            )}

            {tableData.length === 0 && !isLoading && (
              <tr>
                <td className='no-result' colSpan={tableHeaders.length}>
                  Không có bản ghi nào
                </td>
              </tr>
            )}

            {tableData.length > 0 && !isLoading && tableData.map(initTableRow)}
          </tbody>
        </table>
      </ScrollContainer>
    </StyledContainer>
  )
}

export default TableMain
