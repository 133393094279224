import React from 'react'

import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Col, Row } from 'reactstrap'
import { useIntl } from 'react-intl'

const UploadForm: React.FC<{
    onSubmit: (fields: { file: File | null }) => any
}> = ({ onSubmit }) => {
    const { formatMessage } = useIntl()
    const f = (id: any) => formatMessage({ id })

    const SUPPORTED_FORMATS = [
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'image/png'
    ];

    return (
        <Formik
            initialValues={{
                file: null,
            }}
            validationSchema={Yup.object().shape({
                file: Yup.mixed().required(f('requiredImage'))
                    .test(
                        'fileFormat',
                        f('incorrectFormat'),
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    ),
            })}
            onSubmit={(fields) => onSubmit(fields)}
        >
            {({ errors, touched, setFieldValue }) => (
                <Form encType='multipart/form-data'>
                    <Row className='form-group'>
                        <Col>
                            <label htmlFor='text'>{f('newImage')}</label>

                            <input style={{ height: 'auto' }} id='file' name='file' type='file' onChange={(event) => {
                                event.currentTarget.files && (setFieldValue('file', event.currentTarget.files[0]))
                            }} className={
                                'form-control' +
                                (errors.file && touched.file ? ' is-invalid' : '')
                            } />
                            <ErrorMessage
                                name='file'
                                component='div'
                                className='invalid-feedback'
                            />
                        </Col>
                    </Row>
                    <div className='form-group'>
                        <button type='submit' className='btn btnPrimary mr-2'>
                            {f('valid')}
                        </button>
                    </div>
                </Form>
            )}

        </Formik>
    )
}

export default UploadForm
