import React from 'react'
import ReactDOM from 'react-dom'
import App from './App/App'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import './index.css'
import './assets/bootstrap.min.css'
import { IntlProvider } from 'react-intl'
import messages_fr from './translations/fr.json'
import messages_es from './translations/es.json'
import messages_en from './translations/en.json'
import { createGlobalStyle } from 'styled-components'

import API from './utils/API'

const messages: any = {}
messages.fr = messages_fr
messages.es = messages_es
messages.en = messages_en

const languageList = ['fr', 'es', 'en']
const language: string = navigator.language.split(/[-_]/)[0]
API.defaults.headers.language = language

const GlobalStyle = createGlobalStyle`
  .dropdown-menu {
    top: auto!important;
    transform: unset!important;
    max-height: 50vh;
    overflow: auto;
  }
`

ReactDOM.render(
  <IntlProvider locale={language} messages={languageList.includes(language) ? messages[language] : messages.en}>
    <GlobalStyle />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
)
