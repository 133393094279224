import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { useIntl } from 'react-intl'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { history } from 'store'
import { StyledContainer } from '../assets/styles/AddUserFormStyledComponents'

import MaskedInput from 'react-text-mask'

const AddUser: React.FC<{
  onCreateUser: (fields: {
    firstname: string
    lastname: string
    email: string
    phone: string
    birthday: string
    notes: string
    code: string
    address: string
  }) => any
}> = ({ onCreateUser }) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const onGenerateCode = () => {
    setCode(Date.now())
  }

  const [code, setCode] = useState<any>('')

  return (
    <StyledContainer>
      <Formik
        enableReinitialize
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          birthday: '',
          notes: '',
          code: code,
          address: ''
        }}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().required(f('must be valid')),
          lastname: Yup.string().required(f('must be valid')),
          email: Yup.string().required(f('must be valid')),
          phone: Yup.string().required(f('must be valid')),
          birthday: Yup.string().required(f('must be valid')),
          code: Yup.string().required(f('must be valid')),
          address: Yup.string().required(f('must be valid'))
        })}
        onSubmit={(fields) => {
          const [day, month, year] = fields.birthday.split('-')
          const date = `${year}-${month}-${day}`
          fields.birthday = date

          onCreateUser(fields)
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className='CreateUserWrapper '>
              <div className='row'>
                <div className='form-group col-md-6'>
                  <label htmlFor='firstname'>{f('First Name')}</label>

                  <Field
                    name='firstname'
                    type='text'
                    placeholder={f('First Name')}
                    className={
                      'form-control' + (errors.firstname && touched.firstname ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage name='firstname' component='div' className='invalid-feedback' />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='lastname'>{f('Last Name')}</label>

                  <Field
                    name='lastname'
                    type='text'
                    placeholder={f('Last Name')}
                    className={
                      'form-control' + (errors.lastname && touched.lastname ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage name='lastname' component='div' className='invalid-feedback' />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='email'>{f('Email')}</label>

                  <Field
                    name='email'
                    type='text'
                    placeholder={f('Email')}
                    className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name='email' component='div' className='invalid-feedback' />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='phone'>{f('Phone Number')}</label>

                  <Field
                    name='phone'
                    type='text'
                    placeholder={f('Phone Number')}
                    className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name='phone' component='div' className='invalid-feedback' />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='birthday'>{f('Birthday')}</label>

                  <Field
                    name='birthday'
                    render={({ field }: { field: any }) => (
                      <MaskedInput
                        {...field}
                        mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        id='phone'
                        placeholder='JJ-MM-AAAA'
                        type='text'
                        className={
                          'form-control' + (errors.birthday && touched.birthday ? ' is-invalid' : '')
                        }
                      />
                    )}
                  />
                  <ErrorMessage name='birthday' component='div' className='invalid-feedback' />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='code'>{f('Code')}</label>

                  <Field
                    name='code'
                    type='text'
                    placeholder={f('scanBarcode')}
                    className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name='code' component='div' className='invalid-feedback' />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='address'>{f('Address')}</label>

                  <Field
                    name='address'
                    type='text'
                    placeholder={f('addressExample')}
                    className={
                      'form-control' + (errors.address && touched.address ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage name='address' component='div' className='invalid-feedback' />
                </div>
                <div className='form-group col-md-12'>
                  <label htmlFor='notes'>{f('Notes')}</label>

                  <Field
                    name='notes'
                    type='text'
                    as='textarea'
                    placeholder={f('Notes')}
                    className={'form-control' + (errors.notes && touched.notes ? ' is-invalid' : '')}
                  />
                  <ErrorMessage name='notes' component='div' className='invalid-feedback' />
                </div>
                <div className='col-md-12'>
                  <div className='d-flex AddUserButton'>
                    <div className='form-group'>
                      <button type='submit' className='btn btn-primary mr-2'>
                        {f('SAVE')}
                      </button>
                    </div>
                    <div className='form-group'>
                      <button
                        type='button'
                        className='btn btn-secondary mr-2'
                        onClick={() => history.push('/manage')}
                      >
                        {f('CANCEL')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </StyledContainer>
  )
}

export default AddUser
