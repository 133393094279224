import * as actionTypes from './actionTypes'

export const addProduct = (data) => {
  return {
    type: actionTypes.ADD_PRODUCT_ITEM,
    data
  }
}

export const deleteProduct = (data) => {
  return {
    type: actionTypes.DELETE_PRODUCT_ITEM,
    data
  }
}

export const updateProduct = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_ITEM,
    data
  }
}

export const clearProduct = () => {
  return {
    type: actionTypes.CLEAR_PRODUCTS
  }
}
