import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { RootState } from 'models/redux.model'

import SideMenu from 'components/SideMenu'
import AutoIncome from 'components/Income/AutoIncome'
import NotAutoIncome from 'components/Income/NotAutoIncome'
import SelectPharma from 'components/SelectPharma'
import Breadcrum from 'components/ui/Breadcrum'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useReactToPrint } from 'react-to-print'
import PdfIncome from 'components/Income/PdfIncome'

import API from 'utils/API'
import {
  alertError,
  alertSuccessModifCompte
} from 'utils/notifications'

import { fee } from 'models/income.model'

import { setSelectedPharma } from '../actions'
import { Helmet } from 'react-helmet'
import { Column } from 'models/table.model'

const Income = (): JSX.Element => {
  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma)
  const pharmacy = selectedPharma.pharmacy
  const [isAutoPayment, setIsAutoPayment] = useState<boolean>(pharmacy.auto_payment)
  const [isLoading, setIsloading] = useState(false)
  const [data, setData] = useState<fee[]>([])

  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const dispatch = useDispatch()

  const componentRef = useRef(null)
  const columns: Column[] = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: f('month').replace('m', 'M'),
      accessor: 'formattedMonth'
    },
    {
      Header: f('amount'),
      accessor: 'formattedCost'
    },
    {
      Header: f('Validation'),
      accessor: 'validation'
    },
    {
      Header: f('Paiement'),
      accessor: 'payment_confirmed'
    }
  ]

  useEffect(() => {
    setIsAutoPayment(pharmacy.auto_payment)
  }, [pharmacy.auto_payment])

  const handleUpdatePharmacy = async (autoPayment: boolean): Promise<void> => {
    setIsAutoPayment(autoPayment)
    pharmacy.auto_payment = autoPayment
    try {
      const res = await API.put(
        `/pharmacies/${pharmacy.pharmacy_id}`,
        { auto_payment: autoPayment }
      )

      if (res.data.success === true) {
        if (autoPayment) {
          alertSuccessModifCompte(f('activePayment'))
        } else {
          alertSuccessModifCompte(f('disablePayment'))
        }

        dispatch(setSelectedPharma(selectedPharma))
      } else alertError(f('errorUpdatePharma'))
    } catch (err: any) {
      console.log(err)
      alertError(f('errorUpdatePharma'))
    }
  }

  const getData = useCallback(async (): Promise<void> => {
    try {
      const newData = await API.post(`/pharmacy/fees/get/${pharmacy.pharmacy_id}`, { startDate: new Date('2020/01/01'), endDate: new Date() })
      if (newData.data.fees.length > 0) {
        setIsloading(true)
        setData(newData.data.fees)
      }
    } catch (err) {
      console.log('err: ', err)
      alertError(f('ErrorIncome'))
    }
  }, [pharmacy.pharmacy_id])

  useEffect(() => {
    setIsloading(false)
    getData().catch(err => console.log(err))
  }, [pharmacy.pharmacy_id])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <Wrapper className='dataContainer' style={{ height: '100%', background: '#FFF' }}>
      <Helmet>
        <title>{f('incomesMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('incomesMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <SideMenu parent='revenus' />
      <ContainerWrapper>
        <Container fluid>
          <Row>
            <Col xs='8' className='bodyHeader'>
              <SelectPharma pharmacies={pharmacies} />
            </Col>
            <Col xs='4' className='bodyHeader'>
              <div style={{ display: 'none' }}>
                <PdfIncome
                  ref={componentRef}
                  data={data}
                  columns={columns}
                />
              </div>
              <button
                type='submit' className='btn btnDownload mr-2' onClick={() => {
                  handlePrint()
                }}
              >
                {f('downloadReceipt')}
              </button>
            </Col>
          </Row>

          <Row className='headerTitle'>

            <h2>{f('Billing')}</h2>
            <StyledFontAwesomeIcon
              icon={faSyncAlt}
              onClick={async () => {
                setIsloading(false)
                await getData()
              }}
            />
            <Breadcrum />

          </Row>
          <Row>
            {
              isAutoPayment
                ? <AutoIncome
                  handleUpdatePharmacy={handleUpdatePharmacy} isAutoPayment={isAutoPayment}
                  pharmacyId={pharmacy.pharmacy_id} data={data} isLoading={isLoading} setData={setData}
                  getData={getData} setIsloading={setIsloading}
                />
                : <NotAutoIncome handleUpdatePharmacy={handleUpdatePharmacy} isAutoPayment={isAutoPayment} />
            }
          </Row>
        </Container>
      </ContainerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0;
  width: 100%;
  display: block;
  .bodyWrapper {
    background: var(--gris-bleu);
    width: 100%;
    padding: 1rem;
    @media (min-width: 60rem) {
      min-width: 70%;
      max-width: calc(100% -20rem);
    }
    .bodyHeader {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      button {
        display: flex;
        align-items: center;
        svg {
          height: 1.5rem;
          margin-right: 0.2rem;
        }
      }
    }
  }

  //Raph :
  .AddButtonWrapper {
    display: flex;
    align-items: flex-start;
    button {
      margin-left: auto;
    }
  }
  .container-fluid {
    padding: 30px 20px 60px 20px;
    background-color: white;
  }

  @media (max-width: 769px) {
    flex-direction: column;
  }
  .headerTitle {
    h2 {
      color: var(--texte-bleu-fonce);
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  label {
    display: flex;
    align-items: center;
  }
  label select {
    width: unset;
  }
  h6 {
    text-align: center;
    margin-top: 33vh;
  }
`

const ContainerWrapper = styled.div`
  @media (min-width: 769px) {
    margin-left: 230px;
  }
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-top: 8px;
  margin-left: 10px;
  cursor: pointer;
`

export default Income
