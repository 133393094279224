import { Reducer, AnyAction } from 'redux'

import * as actionTypes from '../actions/actionTypes'
import { UserState as User, newEmptyPharmacy } from 'models/user.model'

type UserActionHandler = (state: User, action: AnyAction) => User
// type UserActionHandlerNoData = (state: User) => User;

const initState: User = {
  userRole: null,
  userId: null,
  firstname: null,
  lastname: null,
  email: null,
  phone_number: null,
  city: null,
  code_postal: null,
  pharmacies: [],
  selectedPharma: newEmptyPharmacy,
  isMultipharmaAccount: null,
  isAuthenticated: null
}

const setSelectedPharma: UserActionHandler = (state, action) => {
  const newState = { ...state }
  newState.selectedPharma = action.payload
  return newState
}

const setUser: UserActionHandler = (state, action) => {
  return {
    ...state,
    ...action.payload
  }
}

const setAuthStatus: UserActionHandler = (state, action) => {
  return {
    ...state,
    isAuthenticated: action.payload
  }
}

type UpdatableUserProp = 'email' | 'firstname' | 'lastname' | 'phone_number' | 'city' | 'code_postal'

const updateUser: UserActionHandler = (state, action) => {
  const newUser = { ...state }
  const tmpUser = { ...action.payload } as { [index: string]: string }
  for (const key in tmpUser) {
    newUser[key as UpdatableUserProp] = tmpUser[key]
  }
  return (newUser)
}

const userReducer: Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return setUser(state, action)
    case actionTypes.SET_AUTH_STATUS:
      return setAuthStatus(state, action)
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUser(state, action)
    case actionTypes.FORGET_USER:
      return {
        ...initState,
        isAuthenticated: false
      }
    case actionTypes.SET_SELECTED_PHARMA:
      return setSelectedPharma(state, action)
    default:
      return state
  }
}

export default userReducer
