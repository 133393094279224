import React, { useEffect, useState } from 'react'

import { Article } from 'models/LandingPage/articles.model'
import { ImageContainer } from 'assets/styles/landingPage/Articles'

const Articles: React.FC<{ data: Article[] }> = ({ data }) => {
  const articlesToShow = [
    '4144a207-c963-4c43-945e-ed71581b35cb',
    '6c8a197a-42e7-4b96-a7f4-7bc35d1e8cd6',
    'c02659a5-7c6c-4b7b-b67a-ecacb9e088a1',
    'd07e419e-7c0c-40e5-bf9b-d431775e61a5',
    '8cb56933-91ec-4f37-92c8-98612470c73f',
    '04415fdd-88db-4a7f-ad5a-767920d42278'
  ]

  return (
    <div className='container center text-center'>
      <div className='row align-items-center'>
        {data?.map((article) => {
          return (
            articlesToShow.includes(article.id) && (
              <div className='col-lg-2 col-md-2 col-6 text-center col '>
                <ImageContainer src={article.img} alt={article.title} key={article.id} />
              </div>
            )
          )
        })}
      </div>
    </div>
  )
}
export default Articles
