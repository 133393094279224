import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const InfosWrapper = styled(Modal)`
    .modal-content{
        top: 30vh
    }
`

const InfosViewDisabledModal = (props: any) => {
    const { formatMessage } = useIntl()
    const f = (id: any) => formatMessage({ id })

    return (
        <InfosWrapper isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>{f('disabledTitle')}</ModalHeader>
            <ModalBody>
                {f('disabledBody')}
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={() => props.toggle()}>{f('ok')}</Button>
            </ModalFooter>
        </InfosWrapper>
    )
}

export default InfosViewDisabledModal
