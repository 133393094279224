import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col, Card } from 'reactstrap'
import styled from 'styled-components'

import SideMenu from '../components/SideMenu'
import Breadcrum from '../components/ui/Breadcrum'
import ScheduleManager from '../components/ScheduleManager'

import { RootState } from 'models/redux.model'

import SelectPharma from '../components/SelectPharma'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'

const ScheduleWrapper = styled.div`
  margin: 0;
  width: 100%;
  display: block;
  .bodyWrapper {
    background: var(--gris-bleu);
    width: 100%;
    padding: 1rem;
    @media (min-width: 60rem) {
      min-width: 70%;
      max-width: calc(100% -20rem);
    }
    .bodyHeader {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      button {
        display: flex;
        align-items: center;
        svg {
          height: 1.5rem;
          margin-right: 0.2rem;
        }
      }
    }
  }

  //Raph :
  .AddButtonWrapper {
    display: flex;
    align-items: flex-start;
    button {
      margin-left: auto;
    }
  }
  background-color: var(--gris-bleu);
  .container-fluid {
    padding: 30px 20px 60px 20px;
  }

  @media (max-width: 769px) {
    flex-direction: column;
  }
  .headerTitle {
    margin-bottom: 2rem;
    h2 {
      color: var(--texte-bleu-fonce);
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  label {
    display: flex;
    align-items: center;
  }
  label select {
    width: unset;
  }
`

const ScheduleManagement = () => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma)
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)

  return (
    <ScheduleWrapper className='dataContainer'>
      <Helmet>
        <title>{f('scheduleMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('scheduleMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <SideMenu parent='schedule' />
      <ContainerWrapper property='scheduleManagement' style={{ height: '100vh' }}>
        <Container fluid>
          <Row className='headerTitle'>
            <Col>
              <h2>{f('schedule')}</h2>
              <Breadcrum />
            </Col>
          </Row>
          {selectedPharma && (
            <Row>
              <Col xs='12' className='bodyHeader'>
                <SelectPharma pharmacies={pharmacies} />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs='12'>
              <ScheduleManager />
            </Col>
          </Row>
        </Container>
      </ContainerWrapper>
    </ScheduleWrapper>
  )
}

export default ScheduleManagement
