import React from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import styled from 'styled-components'

import SideMenu from '../components/SideMenu'
import SelectPharma from '../components/SelectPharma'
import Breadcrum from '../components/ui/Breadcrum'
import { RootState } from 'models/redux.model'
import QRCodeDisplay from 'components/QRCodeDisplay'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { OrdersWrapper } from 'assets/styles/OrdersWrapper'
import { CardWrapper } from 'assets/styles/CardWrapper'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'

const QRCodes = () => {
  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma)
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  return (
    <OrdersWrapper property='qrcode' className='dataContainer' style={{ height: '100%' }}>
      <Helmet>
        <title>{f('qrcodesMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('qrcodesMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <SideMenu parent='QRCodes' />
      <ContainerWrapper>
        <Container fluid>
          <Row className='headerTitle'>
            <Col>
              <h2>QR Codes</h2>
              <Breadcrum />
            </Col>
          </Row>
          {selectedPharma && (
            <Row className='bodyHeader'>
              <Col xs='12'>
                <SelectPharma pharmacies={pharmacies} />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs='12'>
              <CardWrapper qrcode>
                <QRCodeDisplay />
              </CardWrapper>
            </Col>
          </Row>
        </Container>
      </ContainerWrapper>
    </OrdersWrapper>
  )
}

export default QRCodes
