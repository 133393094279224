import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  margin: 0;
  display: block;
  .containerWrapper {
    margin-left: 230px;
    .container-fluid {
      padding: 30px 20px 60px 20px;
      .headerTitle {
        margin-bottom: 2rem;
        .divider {
          border-top: 1px solid var(--border-separation);
        }
      }
      .bodyHeader {
        input {
          min-height: 40px;
        }
        .searchBar {
          border: none;
        }
        .dropdown {
          button {
            min-height: 40px;
            border: none;
            background-color: #fff;
            color: #007bff;
            font-weight: 600;
            transition: all 0.3s;
            min-width: 130px;
            &:hover {
              background: linear-gradient(to right, #00b2ff, #0066ff);
              color: #fff;
            }
            &:focus {
              background: linear-gradient(to right, #00b2ff, #0066ff);
              background-color: unset !important;
              color: #fff;
              border-color: unset !important ;
              box-shadow: unset !important;
            }
          }
        }
        .AddUser {
          text-align: end;
          button {
            min-width: 150px;
            min-height: 40px;
          }
        }
      }
    }
  }

  UpdateUserWrapper {
    input,
    select {
      height: 40px;
    }
    textarea {
      height: 100px !important;
      background: transparent;
    }
    input::placeholder,
    textarea::placeholder {
      opacity: 0.5;
    }
    button {
      width: 152px;
      height: 40px;
    }
    .form-group {
      position: relative;
      label {
        position: absolute;
        top: -8px;
        left: 25px;
        padding: 0px 6px;
        background-color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #4f4f4f;
      }
    }
    .row {
      margin: 0 auto;
      background: #fff;
    }
  }
  .UpdatePharmacyUserWrapper {
    .row {
      padding: 2rem;
      max-width: 50%;
      margin-left: 15px;
      // margin: auto;
      background-color: #fff;
      border: 1px solid rgba(63, 63, 68, 0.005);
      box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
        0px 1px 3px rgba(63, 63, 68, 0.15);
      border-radius: 4px;
      .form-group {
        position: relative;
        label {
          position: absolute;
          top: -8px;
          left: 25px;
          padding: 0px 6px;
          background-color: #fff;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #4f4f4f;
        }
      }
      input,
      select {
        height: 40px;
        background: transparent;
      }
      textarea {
        height: 100px;
        background: transparent;
      }
      input::placeholder,
      textarea::placeholder {
        opacity: 0.5;
      }
      button {
        width: 152px;
        height: 40px;
      }
    }
  }
`
