import API from './API'
import webKeys from '../config/keys'

// const keys = webKeys as {
//   pushVapidKey: string;
// };

const urlBase64ToUint8Array = (base64String: String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}

interface ExtendableEvent extends Event {
  waitUntil: (fn: Promise<any>) => void
}

export const askPermission = async () => {
  return await Notification.requestPermission()
}

export const registerServiceWorker = async () => {
  return await navigator.serviceWorker.register('./sw.js')
}

export const createNotificationSubscription = async () => {
  // Wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready

  const sw = await serviceWorker.pushManager.getSubscription()
  if (sw != null) return null

  // Subscribe and return subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array('BMCD7p8uq1yCsrlEzyXa6b17xFi7CAdT2i3mkW7owrTaS807oC55K2pAcL6buqVN2iR5z2f_2qHDN8u-y4tU0vI')
  })
}

export const postSubscription = async (subscription: PushSubscription, pharmacist_id: number) => {
  return await API.post('/notification/subscribe', {
    ...subscription.toJSON(),
    pharmacist_id: pharmacist_id
  })
}

export const receivePushNotification = (event: ExtendableEvent) => {}

const initNotifications = async (pharmacist_id: number) => {
  const allowed = await askPermission()
  if (allowed !== 'granted') return

  registerServiceWorker()

  const subscription = await createNotificationSubscription()

  if (subscription != null) await postSubscription(subscription, pharmacist_id)
}

export default initNotifications
