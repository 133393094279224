import React from 'react'

import styled from 'styled-components'
import { Col, NavItem, NavLink } from 'reactstrap'
import { FilterProps } from 'models/filter.model'

const BorderBottomCol = styled(Col)`
  border-bottom: 1px solid #dee2e6;
  padding: 0;
  max-width: 97%;
  a:hover,
  span:hover {
    filter: brightness(80%);
`

const Filters: React.FC<FilterProps> = ({ motifsByLanguage, activeTab, setActiveTab }) => {
  return (
    <BorderBottomCol className='nav' lg='12'>
      {motifsByLanguage.map((motifByLanguage: string, index: number) => (
        // <NavWrapper>
        <NavItem key={index}>
          <NavLink
            className={activeTab === index ? 'active' : ''}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setActiveTab(index)
            }}
          >
            {motifByLanguage}
          </NavLink>
        </NavItem>
      ))}
    </BorderBottomCol>
  )
}

export default Filters
