
import styled from 'styled-components'

export const LoaderWrapper = styled.div`
display: flex !important;
flex-direction: column !important;
justfify-content: center !important;
align-items: center !important;
padding: 1rem 0 !important;
height: ${props => props.title === 'stock' && '100vh !important'};
margin-top: ${props => props.title === 'voicemail' && '20px !important'};

h5 {
  color: #3d8eff !important;
  margin-bottom: 1rem !important;
}

@media (max-width: 769px) {
    height:  ${props => props.title === 'stock' && '50vh !important'};
  }
`
