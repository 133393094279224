import React, { useState } from 'react'
import { Row } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { alertError, alertSuccess } from 'utils/notifications'
import API from 'utils/API'

interface AskTntFileProps {
  data: any

}

const AskTntFile = (props: AskTntFileProps) => {
  const [clicked, setClicked] = useState(false)
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const handleClick = async (): Promise<void> => {
    setClicked(true)

    const content = `La pharmacie ${props.data.pharmacyName} a demandé la génération du bon TNT de la commande ${props.data.orderId}.`
    const response = await API.post('/mail/notification', { content })
    if (response.data.success === true) {
      alertSuccess('Demande de bon TNT envoyée')
    } else {
      alertError('Erreur lors de la demande du bon TNT')
    }
  }

  const StyledButton =
    !clicked
      ? styled.button`
          border: solid 1px #6c757d;
          color: #fff;
          background-color: #6c757d;
          border-color: #6c757d;
          box-shadow: none;
          border-radius: 3px;
          padding: 5px;
          width: 15rem;
          min-height: 3rem;
          font-size: 12px;
        `
      : styled.button`
          background-image: linear-gradient(to bottom right, #ff625b, #ffb064);
          border: solid 1px #ffb064;
          color: white;
          box-shadow: none;
          border-radius: 3px;
          padding: 5px;
          width: 15rem;
          min-height: 3rem;
          font-size: 12px;

        `

  return (
    <StyledRow>
      <StyledButton
        onClick={async () => {
          await handleClick()
        }}
      >
        Demander fichier TNT
      </StyledButton>
    </StyledRow>
  )
}

const StyledRow = styled(Row)`
  position: relative;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 9px;

`

export default AskTntFile
