import React, { useEffect, useState } from 'react'
import {
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  Modal
} from 'reactstrap'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import 'react-datepicker/dist/react-datepicker.css'

import styled from 'styled-components'
import moment, { Moment } from 'moment'
import API from '../../utils/API'
import { alertSuccess } from '../../utils/notifications'

import { useIntl } from 'react-intl'

import socket, { CONNECTION_PORT } from 'utils/socket'
import { motifs } from 'models/appointment.model'

registerLocale('fr', fr)
setDefaultLocale('fr')

interface BookedProps {
  roomBookedModal: string
  isOpen: boolean
  closeModal: () => void
  backdrop: boolean
  pharmaId: number
  getAvailableDateAppointment: () => void
}

interface Time {
  time: string
  value: string
}

const BookedModal: React.FC<BookedProps> = ({
  roomBookedModal,
  isOpen,
  closeModal,
  backdrop,
  pharmaId,
  getAvailableDateAppointment
}) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const [hourStart, setHourStart] = useState<string>('')
  const [hourEnd, setHourEnd] = useState<string>('')
  const [motif, setMotif] = useState<string[]>([])
  const [display, setDisplay] = useState<string>('none')

  useEffect(() => {
    // If socket was needed to avoid undefined error
    // because socket's not initialized in useEffect
    if (socket) {
      socket.emit('join_room', roomBookedModal)
    }
  }, [CONNECTION_PORT])

  useEffect(() => {
    socket.on('reload_booked', () => {
      resetData()
    })
  }, [isOpen, motif])

  const endWithMinutes: string = '19.30'

  const [endTime, setEndTime] = useState<Time[]>([])
  const [startTime, setStartTime] = useState<Time[]>([])

  const hourMax: number = 15

  const deliveryDisabledDays: number[] = [
    129,
    142,
    153,
    185,
    195,
    196,
    228,
    249,
    316,
    360,
    1
  ]

  useEffect(() => {
    resetData()
  }, [isOpen, motif])

  const isSunday = (date: any) => {
    const day = moment(date).day()
    return day === 0
  }

  //  Calculates the minimum date for the scheduled delivery
  const calculateMinDate = (): Moment => {
    const today: Moment = moment()

    if (today.hour() >= hourMax) today.add(1, 'day')

    if (isSunday(today)) today.add(1, 'day')

    while (deliveryDisabledDays.includes(today.dayOfYear())) {
      today.add(1, 'day')
    }

    return today
  }

  const [selectedStartDay, setSelectedStartDay] = useState<Moment>(
    calculateMinDate()
  )

  const [selectedEndDay, setSelectedEndDay] = useState<Moment>(
    moment(calculateMinDate()).add(8, 'days')
  )

  /**
   * Compares if two dates are on the same day
   * @param {Date} first - the first date compared
   * @param {Date} second - the second date compared
   */
  const datesAreOnSameDay = (first: any, second: any) =>
    first.isSame(second, 'day')

  // end Time greater than the start time in the end time field
  const updateEndTime = (time: string, startDate: Moment, endDate: Moment) => {
    let selectedElement: Time[] = []
    let tabInitialEndTime: Time[] = []

    if (startTime.length !== 0 || endTime.length !== 0) {
      if (datesAreOnSameDay(startDate, endDate)) {
        selectedElement = startTime.filter(el => el.time === time)

        if (endTime.find(el => el.time === selectedElement[0].time) != null) {
          tabInitialEndTime = endTime.filter(
            el => el.value > selectedElement[0].value
          )
        } else {
          tabInitialEndTime = startTime.filter(
            el => el.value > selectedElement[0].value
          )
        }

        setEndTime(tabInitialEndTime)
        if (tabInitialEndTime.length > 0) {
          setHourEnd(tabInitialEndTime[0].time)
        } else {
          setHourEnd('')
        }
      }
    } else {
      setHourStart('')
      setHourEnd('')
    }
  }

  // complete time filed compared to now
  const timeOnSameDay = (day: Moment, tabTime: Time[], pos: string) => {
    const today = moment()

    if (datesAreOnSameDay(today, day)) {
      const tmp: Time[] = tabTime.filter(
        el => parseInt(el.value) > today.hour()
      )

      if (pos === 'start') {
        setStartTime(tmp)
        if (tmp.length > 0) {
          setHourStart(tmp[0].time)
        } else {
          setHourStart('')
        }
      } else {
        setEndTime(tmp)
        if (tmp.length > 0) {
          setHourEnd(tmp[0].time)
        } else {
          setHourEnd('')
        }
      }

      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    getDisableAppointment(selectedStartDay, 'start')
    getDisableAppointment(selectedEndDay, 'end')
    // eslint-disable-next-line
  }, [isOpen, motif])

  // Parse the date to send
  const parserAppointment = (hour: string, selectDay: Moment) => {
    const splitHour = hour.split(':')
    const date = moment(selectDay)
      .hours(parseInt(splitHour[0]))
      .minutes(parseInt(splitHour[1]))
      .seconds(0)
    const appointment = new Date(moment(date).format())

    return appointment
  }

  const createAppointment = async () => {
    let res = null
    const body: { [k: string]: any } = {
      pharmacy_id: pharmaId,
      start_date: parserAppointment(hourStart, selectedStartDay),
      end_date: parserAppointment(hourEnd, selectedEndDay)
    }

    try {
      if (motif.length === 1) {
        body.motif = motif[0]
        res = await API.post('/unlockedAppointment/create', body)
      } else {
        body.motifs = motif
        res = await API.post('/unlockedAppointment/createMultiple', body)
      }
    } catch (err: any) {
      console.log(err)
    }

    if (res?.data?.success) {
      getAvailableDateAppointment()
      alertSuccess(f('unBlockSuccess'))
      // resetData();
    }
  }

  const getDisableAppointment = (date: Moment, pos: string) => {
    API.post('unlockedAppointment/getDisableAppointment', {
      pharmacy_id: pharmaId,
      date: moment(date).format('YYYY-MM-DD'),
      motif
    })
      .then(res => {
        if (res.data.success) {
          if (pos === 'start') {
            setStartTime(res.data.data)
          } else {
            setEndTime(res.data.data)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const resetData = async () => {
    getDisableAppointment(selectedStartDay, 'start')
    getDisableAppointment(selectedEndDay, 'end')
    getAvailableDateAppointment()
  }

  const handleMotifChange = (event: any) => {
    if (motif.includes(event.target.name)) {
      setMotif(prev => prev.filter(elt => elt !== event.target.name))
    } else {
      setMotif(prev => [...prev, event.target.name])
    }
  }

  console.log(motif.length)

  return (
    <div>
      <ModalWrapper
        isOpen={isOpen}
        toggle={closeModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={backdrop}
      >
        <ModalHeader className='motif'>{f('blockSomething')}</ModalHeader>
        <ModalBody>
          <Row className='motifInput'>
            <Col md='7'>
              <FormControl>
                <FormLabel>{f('motif')}</FormLabel>
                <FormGroup>
                  {motifs.map((elt, index) => (
                    <FormControlLabel
                      key={`motif-checkbox-${index}`}
                      control={
                        <Checkbox
                          checked={motif.includes(elt)}
                          onChange={handleMotifChange}
                          name={elt}
                        />
                      }
                      label={elt}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Col>
          </Row>
          {motif.length > 0 && (
            <>
              <Row>
                <Col className='date' md='4'>
                  <Label>{f('startDate')}</Label>
                  <br />
                  <DatePicker
                    selected={selectedStartDay.toDate()}
                    locale='fr'
                    dateFormat='P'
                    onChange={() => {}}
                    filterDate={date => {
                      const day = moment(date).day()

                      if (
                        deliveryDisabledDays.includes(moment(date).dayOfYear())
                      ) {
                        return false
                      }

                      return day !== 0
                    }}
                    allowSameDay={false}
                    minDate={calculateMinDate().toDate()}
                    maxDate={selectedEndDay.toDate()}
                    onSelect={date => {
                      setSelectedStartDay(moment(date))
                      getDisableAppointment(moment(date), 'start')
                      timeOnSameDay(moment(date), startTime, 'start')
                    }}
                  />
                </Col>

                <Col md='4' />

                <Col className='date' md='4'>
                  <Label>{f('endDate')}</Label>
                  <br />
                  <DatePicker
                    selected={selectedEndDay.toDate()}
                    locale='fr'
                    dateFormat='P'
                    onChange={() => {}}
                    filterDate={date => {
                      const day = moment(date).day()

                      if (
                        deliveryDisabledDays.includes(moment(date).dayOfYear())
                      ) {
                        return false
                      }

                      return day !== 0
                    }}
                    minDate={selectedStartDay.toDate()}
                    allowSameDay={false}
                    onSelect={date => {
                      setSelectedEndDay(moment(date))
                      getDisableAppointment(moment(date), 'end')
                      timeOnSameDay(moment(date), endTime, 'end')
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <Label>{f('startHour')}</Label>
                  <Input
                    type='select'
                    name='selectStartTime'
                    id='startTime'
                    onChange={e => {
                      setHourStart(e.target.value)
                      updateEndTime(
                        e.target.value,
                        selectedStartDay,
                        selectedEndDay
                      )
                    }}
                  >
                    <option selected> - </option>
                    {startTime.map((el, i) => {
                      return el.value !== endWithMinutes ? (
                        el.time === hourStart ? (
                          <option selected key={i}>
                            {el.time}
                          </option>
                        ) : (
                          <option key={i}>{el.time}</option>
                        )
                      ) : null
                    })}
                  </Input>
                </Col>
                <Col md='4' />
                <Col md='4'>
                  <Label>{f('endHour')}</Label>
                  <Input
                    type='select'
                    name='selectEndTime'
                    id='endTime'
                    onChange={e => {
                      setHourEnd(e.target.value)
                    }}
                  >
                    <option selected> - </option>
                    {endTime.map((el, i) => {
                      return el.time === hourEnd ? (
                        <option selected key={i}>
                          {el.time}
                        </option>
                      ) : (
                        <option key={i}>{el.time}</option>
                      )
                    })}
                  </Input>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <UnlockButton
            type='submit'
            className='btn btnPrimary mr-2'
            id='block'
            disabled={
              !!(hourStart === '' || hourEnd === '' || motif.length === 0)
            }
            onClick={async () => {
              closeModal()
              createAppointment()
              await socket.emit('reload_booked', roomBookedModal)
            }}
          >
            {f('unBlock')}
          </UnlockButton>
        </ModalFooter>
      </ModalWrapper>
    </div>
  )
}

export default BookedModal

const UnlockButton = styled.button`
  &:disabled {
    cursor: not-allowed;
  }
`

const ModalWrapper = styled(Modal)`
  padding: 1rem;
  p {
    font-size: 0.9rem;
  }
  .modal-content {
    padding: 1rem;
  }

  .motif {
    color: var(--texte-bleu-fonce);
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .motifInput {
    justify-content: center;
    margin-bottom: 1rem;
  }

  label {
    color: var(--texte-table);
    font-weight: 700;
    font-size: 0.9rem;
    margin-right: 1rem;
  }

  input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }

  .react-datepicker__input-container input {
    padding-left: 10px;
    width: 105px;
    margin-bottom: 1rem;
  }

  .date {
    margin-bottom: 1rem;
  }
`
