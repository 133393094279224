import React, { useEffect } from 'react'
import {
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Modal
} from 'reactstrap'
import styled from 'styled-components'
import { Event } from 'models/appointment.model'
import moment from 'moment'
import API from '../../utils/API'
import { alertSuccess, alertError } from '../../utils/notifications'
import { useIntl } from 'react-intl'

import socket, { CONNECTION_PORT } from 'utils/socket'

interface CalendarProps {
  roomBookedModal: string
  roomPatientModal: string
  isOpen: boolean
  closeModal: () => void
  data: Event
  backdrop: boolean
  pharmaId: number | null
  getData: (id: number) => void
}

const CalendarModal: React.FC<CalendarProps> = ({
  roomBookedModal,
  roomPatientModal,
  isOpen,
  closeModal,
  data,
  backdrop,
  pharmaId,
  getData
}) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  useEffect(() => {
    // If socket was needed to avoid undefined error
    // because socket's not initialized in useEffect
    if (socket) {
      socket.emit('join_room', roomPatientModal)
    }
  }, [CONNECTION_PORT])

  useEffect(() => {
    socket.on('reload_booked', () => {
      console.log('reload')
      pharmaId && getData(pharmaId)
    })
  }, [isOpen])

  const deleteAppointment = async (id: number): Promise<any> => {
    try {
      await API.delete(`/appointment/${id}`)
      pharmaId && getData(pharmaId)
      alertSuccess(f('removeSlot'))
    } catch (e: any) {
      console.error(e)
      alertError(f('removeSlotError'))
    }
  }

  return (
    <div>
      <ModalWrapper
        isOpen={isOpen}
        toggle={closeModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={backdrop}
      >
        <ModalHeader className='motif'>{data.motif}</ModalHeader>
        <ModalBody>
          {(data.user != null) && (
            <Row>
              <Col md='4'>
                <Label>{f('lastName')}</Label>
                <p>{data.user?.lastname}</p>
              </Col>
              <Col md='4'>
                <Label>{f('firstName')}</Label>
                <p>{data.user?.firstname}</p>
              </Col>
              <Col md='4'>
                <Label>{f('phone')}</Label>
                <p>{data.user?.phone_number}</p>
              </Col>
            </Row>
          )}

          <Row>
            <Col md='6'>
              <Label>{f('startDate')}</Label>
              <p>{moment(data.start).format('DD MMMM YYYY, H:mm')}</p>
            </Col>
            <Col md='6'>
              <Label>{f('endDate')}</Label>
              <p>{moment(data.end).format('DD MMMM YYYY, H:mm')}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              data.id && deleteAppointment(data.id)
              await socket.emit('reload_booked', roomPatientModal)
              await socket.emit('reload_booked', roomBookedModal)
              closeModal()
            }}
            color='btn btnPrimary mr-2'
          >
            {f('delete')}
          </Button>
          <Button onClick={closeModal} color='btn btnSecondary'>
            {f('close')}
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </div>
  )
}

export default CalendarModal

const ModalWrapper = styled(Modal)`
  padding: 1rem;
  p {
    font-size: 0.9rem;
  }
  .modal-content {
    padding: 1rem;
  }

  .motif {
    color: var(--texte-bleu-fonce);
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  Label {
    color: var(--texte-table);
    font-weight: 700;
    font-size: 0.9rem;
  }
  input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }
`
