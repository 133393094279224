import React from 'react'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { history } from '../../../store'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from 'react-intl'
import { Col, Row } from 'reactstrap'
import LoginImg from 'assets/images/landingPage/login.svg'

const ForgotPasswordFormMobile: React.FC<{
  onSubmit: (fields: { email: string }) => any
}> = ({ onSubmit }) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  return (
    <div className='container'>
      <Row style={{ alignItems: 'center' }}>
        <Col md='6'>
          <div>

            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(f('wrongEmail'))
                  .required(f('pleaseEmail2'))
              })}
              onSubmit={(fields) => onSubmit(fields)}
            >
              {({ errors, touched }) => (
                <div className='splash-container'>
                  <div>
                    <FontAwesomeIcon
                      icon={faArrowLeft} onClick={() => {
                        history.push('/')
                      }}
                    />
                    <div className=' text-center'>
                      <h2>{f('phacilAdmin')}</h2>
                      <h5 className='splash-description'>{f('forgotPasswd')}</h5>
                    </div>
                    <div>
                      <Form>
                        <p>
                          {f('sendNewPasswd')}
                        </p>
                        <div className='form-group'>
                          <Field
                            name='email'
                            type='text'
                            className={
                              'form-control form-control-lg' +
                                                            (errors.email && touched.email ? ' is-invalid' : '')
                            }
                            placeholder={f('mail')}
                          />
                          <ErrorMessage
                            name='email'
                            component='div'
                            className='invalid-feedback'
                          />
                        </div>
                        <button
                          type='submit'
                          className='btn btn-primary btn-lg btn-block'
                        >
                          {f('reinitPasswd')}
                        </button>
                      </Form>
                    </div>
                    <div className='card-footer text-center'>
                      <a href='https://phacil.delivery/pharmacies'>
                        {f('noAcc')}<br />
                        {f('contactUs')}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </Col>
        <Col md='6'>
          <img style={{ width: '100%' }} src={LoginImg} alt='Login' />
        </Col>

      </Row>

    </div>

  )
}

export default ForgotPasswordFormMobile
