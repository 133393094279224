import { io } from 'socket.io-client'
import webKeys from '../config/keys'

const keys = (webKeys) as {
  baseUrl: string
}

export const CONNECTION_PORT = keys.baseUrl
// let CONNECTION_PORT = "https://preprod-shielded-dawn-73021.herokuapp.com";

const socket = io(CONNECTION_PORT)

export const sendReloadInfo = (orderId: any, pharmacyId: any): void => {
  const content = {
    content: {
      orderId: orderId,
      pharmacyId: pharmacyId
    },
    room: 'superadmin'
  }
  socket.emit('send_reload_info', content)
}

export const sendReloadSuperAdmin = (): void => {
  socket.emit('reload_orders', 'superadmin')
}

export const reloadTntFile = (cb: any, orderId: number): void => {
  socket.on('reload_tnt_file', (data) => {
    if (data.content.orderId === orderId) {
      cb().catch((err: any) => console.log(err))
    }
  })
}

export default socket
