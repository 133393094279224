import React from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import { useIntl } from 'react-intl'
import { openPopup } from 'utils/popup'

const SidebarOrdonnance = () => {
  const { formatMessage, locale } = useIntl()
  const f = (id: any) => formatMessage({ id })
  locale === 'es' && moment.locale('es')


  return (

    <ModalWrapper>
      {/* <StyledModalBody> */}
      <Row className='title'>
        <Col>
          <StyledImg src='/assets/icons/Icon_secure_orange@1,5x.svg' alt='Secure icon' />
          <span className='textModal'>{f('prescriptionSegura')}</span>
        </Col>
      </Row>

      <Row className='blockSecureOrdo'>
        <Col>
          <h4>{f('prescriptionWithQRCode')} </h4>
          <ImgWrapper>
            <StyledImgPrescription src='/assets/icons/qare.png' alt='qare icon' />
            <a
              rel='noreferrer'
              onClick={() => { openPopup('https://livi.fr/ordonnance/') }}
            >
              <StyledImgLivi src='/assets/icons/livi2.png' alt='livi2 icon' />
            </a>
            <a
              rel='noreferrer'
              onClick={() => { openPopup('https://app.ordoclic.fr/direct/scan?session=professional') }}
            >
              <StyledImgZavamed src='/assets/icons/zavamed.jpg' alt='zavamed icon' />
            </a>
          </ImgWrapper>
          <p className='textModal'>{f('validPrescription')} </p>
        </Col>
      </Row>

      <Row className='blockSecureOrdo'>
        <Col>
          <h4>{f('prescriptionWithoutQRcode')} </h4>
          <ImgWrapper>
            <StyledImgDoctolib src='/assets/icons/doctolib.jfif' alt='doctolib icon' />
            <StyledImgPrescriptionLast src='/assets/icons/medadom.png' alt='medadom icon' />
          </ImgWrapper>
          <p className='textModal'>{f('automaticSendRequest')}</p>
        </Col>
      </Row>

      {/* </StyledModalBody> */}
    </ModalWrapper>
  )
}

const StyledImg = styled.img`
  width: 18px;
  height: 30px;
`

const StyledImgDoctolib = styled.img`
  width: 80px;
  height: auto;
`

const StyledImgPrescription = styled.img`
  width: 45px;
  height: auto;
`
const StyledImgPrescriptionLast = styled.img`
  width: 60px;
  height: auto;
`
const StyledImgZavamed = styled.img`
  width: 60px;
  height: auto;
  cursor: pointer;
`

const StyledImgLivi = styled.img`
  width: 30px;
  height: auto;
  cursor: pointer;
`
const ImgWrapper = styled(Col)`
display: flex; 
flex-direction: row; 
align-items: center; 
justify-content: space-around;
`
const ModalWrapper = styled.div`
border: 2px dotted; opacity: 0.8;
display: flex; 
flex-direction: column; 
height: 100%;

  h3 {
    font-weight: bold;
    font-size: 1.2rem;
    color: #202855;
    text-align: center;
  }
  h4 {
    font-weight: bold;
    font-size: 1rem;
    color: #202855;
    text-align: center;
  }
  .textModal {
    margin: 0;
    font-weight: bold;
    font-size: 0.9rem;
    color: #6c6f7d;
    text-align: justify;
  }
  .blockSecureOrdo {
    text-align: center;
    padding : 1.5rem;
  }
  .title {
    text-align: center;
    margin-top: 0.5rem;
  }

  .
`
export default SidebarOrdonnance
