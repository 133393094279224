import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  CardBody,
  Button
} from 'reactstrap'
import { PlusCircle as AddIcon } from 'react-feather'

import { Column, Row as RowType } from 'models/table.model'
import API from '../utils/API'
import { alertSuccess, alertError } from '../utils/notifications'
import AddModal from '../components/AddModal'
import StockTable from '../components/StockTable'
import NotificationConfirmModal from '../components/StockTable/NotificationConfirmModal'
import SideMenu from '../components/SideMenu'
import SearchForm from '../components/SearchForm'
import { RootState } from 'models/redux.model'

import SelectPharma from '../components/SelectPharma'
import { useIntl } from 'react-intl'
import InfosViewDisabledModal from 'components/InfosViewDisabledModal'
import { StockWrapper, FiltersAboveTable, SearchbarWrapper } from '../assets/styles/StockStyledComponents'
import { StockItem } from 'models/stocks.models'

import Helmet from 'react-helmet'
import { LoaderWrapper } from 'assets/styles/LoaderWrapper'

import { CardWrapper } from 'assets/styles/CardWrapper'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'
import { applySort } from 'utils/sortHandler'

const loadingIcon = 'images/loading-gif-phacil.svg'
const blurStock = 'images/blurStock.png'

interface StockRow extends RowType { }

const Stock: React.FC = () => {
  // Pharmacy list from Redux
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)
  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )

  const [data, setData] = useState<StockRow[]>([]) // Array of examples
  const [filteredRows, setFilteredRows] = useState<StockRow[]>([]) // Array of examples
  const [currentSearchValue, setCurSearchValue] = useState<string>('')
  const [sortCriteria, setSortCriteria] = useState<{
    direction: 'ASC' | 'DESC'
    accessor: string
  } | null>(null)

  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [exampleCount, setExampleCount] = useState(0)

  const pageSize = 50
  const [offset, setOffset] = useState(0)

  const [modalConfirmationNotifications, setModalConfirmationNotifications] = useState(false)
  const toggle = (): void => setModalConfirmationNotifications(!modalConfirmationNotifications)
  const [productIdNotif, setProductIdNotif] = useState(0)

  const [openModalDisabled, setOpenModalDisabled] = useState(true)
  const toggleDisabled = (): void => setOpenModalDisabled(!openModalDisabled)

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const isMultipharmaAccount = useSelector((state: RootState) => state.user.isMultipharmaAccount)

  const [clearTimeouts, setClearTimeouts] = useState<number[]>([])
  const [isSearchable, setIsSearchable] = useState(false)
  const [productName, setProductName] = useState('')
  const [reduction, setReduction] = useState(-1)

  const [isNotifiable, setIsNotifiable] = useState(false)
  const [endDateNotification, setEndDateNotification] = useState('')
  const [idPromoNotifications, setIdPromoNotifications] = useState(null)

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggleTooltip = (): void => setTooltipOpen(!tooltipOpen)
  const textTooltip = f('textTooltipStock')

  const columns: Column[] = [
    {
      Header: f('name2'),
      accessor: 'name',
      filtrable: true
    },
    {
      Header: f('ean'),
      accessor: 'EAN_code',
      filtrable: true
    },
    {
      Header: f('prix'),
      accessor: 'priceRaw',
      editable: true
    },
    {
      Header: f('quantity3'),
      accessor: 'quantity',
      editable: true
    },
    {
      Header: f('reduc'),
      accessor: 'reduction',
      editable: true
    }
  ]

  /** DATA FORMATTING */
  const formatDataRows = (rawData: StockItem[]): StockRow[] => {
    const rows: StockRow[] = []
    rawData.forEach((productData: StockItem) => {
      if (
        Array.isArray(productData.examples) &&
        productData.examples.length > 0
      ) {
        productData.examples.forEach((example) => {
          const newRow: StockItem = {
            name: productData.name,
            EAN_code: productData.EAN_code,
            category1: productData.category1,
            ...example
          }
          if (newRow.examples != null) delete newRow.examples
          const formattedNewRow = newRow as unknown
          rows.push(formattedNewRow as StockRow)
        })
      }
    })
    return rows
  }

  /**
   * Filters the rows according to the current search value (filter on Name or EAN code)
   */
  const applyFilters = async (): Promise<any> => {
    if (
      currentSearchValue == null ||
      currentSearchValue === ''
    ) {
      setFilteredRows([...data])
      setIsLoading(false)
    } else {
      const data = {
        research: currentSearchValue,
        offset: offset,
        limit: pageSize,
        pharmacy_id: selectedPharma.pharmacy_id
      }

      const newFilteredRows = await API.post('/pharmacist/research/name', data)
      setFilteredRows(formatDataRows([...newFilteredRows.data.result]))
      setExampleCount(newFilteredRows.data.count)
      setIsLoading(false)
    }
  }

  /** CRUD FUNCTIONS */
  const getData = async (): Promise<any> => {
    try {
      const data = {
        offset: offset,
        limit: pageSize,
        pharmacy_id: selectedPharma.pharmacy_id
      }

      const examplesResponse = await API.post('/pharmacist/research', data)
      setData(formatDataRows([...examplesResponse.data.result]))
      setExampleCount(examplesResponse.data.count)
      setIsLoading(false)
    } catch (e: any) {
      console.log(e)
    }
  }

  const modifyItemHandler: (data: {
    rowIndex: number
    accessor: string
    newValue: string | number
  }) => any = async ({ rowIndex, accessor, newValue }) => {
    const updatedExample: { [index: string]: any } = {
      example_id: filteredRows[rowIndex].id,
      priceRaw: filteredRows[rowIndex].priceRaw,
      reduction: filteredRows[rowIndex].reduction,
      quantity: filteredRows[rowIndex].quantity
    }

    if (!Object.keys(updatedExample).includes(accessor)) return

    updatedExample[accessor] = +newValue
    try {
      await API.put('/pharmacist/modification-stock', updatedExample)
      alertSuccess(f('updatedProduct'))
    } catch (e: any) {
      alertError(f('updateProductError'))
      console.error(e)
    }

    getData().catch(err => console.log(err))
  }

  const deleteItemHandler: (data: RowType) => Promise<any> = async ({ id }) => {
    try {
      await API.delete('/pharmacist/deleting-stock', {
        data: { example_id: id }
      })
      alertSuccess(f('deletedProduct'))
    } catch (e: any) {
      console.error(e)
      alertError(f('deleteProductError'))
    }

    selectedPharma != null && getData()
  }

  const sendPromotionNotificationTest = async (idPromoNotifications?: number): Promise<any> => {
    try {
      await API.post('/mobile/admin/product/promotion/pushNotification', {
        product_id: productIdNotif,
        pharmacy_id: selectedPharma.pharmacy_id,
        idPromoNotifications: idPromoNotifications
      })
      alertSuccess(f('notifSend'))
    } catch (err: any) {
      alertError(f('errorSendNotif'))
    }
  }

  const preparePromotionNotification = async (): Promise<any> => {
    try {
      const res = await API.post('/mobile/admin/product/prepare-promos-notifs', {
        pharmacy_id: selectedPharma.pharmacy_id
      })
      setIdPromoNotifications(null)
      if (res.data.success) {
        setIsNotifiable(res.data.isNotifiable)
        setEndDateNotification(res.data.endDate)
        if (res.data.idPromoNotifications) {
          setIdPromoNotifications(res.data.idPromoNotifications)
        }
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  const sendPromotionNotification = async (productId: number, productName: string, reduction: number): Promise<any> => {
    setProductName(productName)
    setReduction(reduction)
    setProductIdNotif(productId)
    toggle()
  }

  const addItemHandler = async (itemData: {
    EAN_code: string
    quantity: string
    price: string
    reduction: string
  }): Promise<any> => {
    const body = {
      ...itemData,
      pharmacy_id: selectedPharma.pharmacy_id
    }
    try {
      await API.post('/pharmacist/adding-stock', body)
      setIsAddModalOpen(false)
      alertSuccess(f('addedProduct'))
      getData().catch(err => console.log(err))
    } catch (e: any) {
      console.error(e)
      alertError(f('addProductError'))
    }
  }

  /**
   * OTHER EVENTS HANDLERS
   */
  useEffect(() => {
    getData().catch(err => console.log(err))
    preparePromotionNotification().catch(err => console.log(err))
  }, [selectedPharma])

  useEffect(() => {
    preparePromotionNotification().catch(err => console.log(err))
  }, [modalConfirmationNotifications])

  useEffect(() => {
    if (currentSearchValue == null || currentSearchValue === '') getData().catch(err => console.log(err))
  }, [currentSearchValue])

  useEffect(() => {
    applyFilters().catch(err => console.log(err))
  }, [data])

  useEffect(() => {
    if (isSearchable) {
      setOffset(0)
      applyFilters().catch(err => console.log(err))
    }
  }, [isSearchable])

  useEffect(() => {
    if (currentSearchValue.length >= 1) applyFilters().catch(err => console.log(err))
    else getData().catch(err => console.log(err))
  }, [offset])

  useEffect(() => {
    applySort(data, setData, sortCriteria)
  }, [sortCriteria])

  useEffect(() => {
    for (const index of clearTimeouts) {
      if (index !== clearTimeouts.length - 1) {
        clearTimeout(index)
      }
    }
  }, [clearTimeouts])

  return (
    <>
      <Helmet>
        <title>{f('stockMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('stockMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>

      <AddModal
        isOpen={isAddModalOpen}
        toggleModal={() => setIsAddModalOpen((prevValue) => !prevValue)}
        onAddExample={addItemHandler}
      />
      <NotificationConfirmModal
        isOpen={modalConfirmationNotifications}
        toggle={toggle}
        sendPromotionNotificationTest={sendPromotionNotificationTest}
        productName={productName}
        reduction={reduction}
        isNotifiable={isNotifiable}
        endDateNotification={endDateNotification}
        idPromoNotifications={idPromoNotifications}
      />
      <StockWrapper property='stock' className='dataContainer'>
        <SideMenu parent='Mon stock' />
        <ContainerWrapper>
          <Container fluid className={(selectedPharma.pharmacy.pharmacy_menu?.orders === 1 && !isMultipharmaAccount) ? 'blur' : ''}>
            {(selectedPharma.pharmacy.pharmacy_menu?.stock === 1 && !isMultipharmaAccount)
              ? <img src={blurStock} alt='' />
              : (
                <Container>
                  <Row className='headerTitle'>
                    <h2>{f('stocks')}</h2>
                    {
                      selectedPharma && (
                        <div className='bodyHeader'>
                          <SelectPharma pharmacies={pharmacies} />
                        </div>
                      )
                    }
                  </Row>
                  <Row>
                    <Col xs='12'>
                      <CardWrapper stock>
                        {
                          isLoading
                            ? (

                              <LoaderWrapper title='stock'>
                                <h5>{f('loadingStock')}</h5>
                                <img src={loadingIcon} alt='loading' width='35' />
                              </LoaderWrapper>
                            )
                            : (
                              <CardBody>
                                <FiltersAboveTable property='stock'>

                                  <Col xs='12' lg=''>

                                    <SearchbarWrapper property='stock'>
                                      <SearchForm
                                        setIsSearchable={setIsSearchable}
                                        setClearTimeouts={setClearTimeouts}
                                        onSearch={applyFilters}
                                        setInputValue={(newValue) => {
                                          setCurSearchValue(newValue)
                                        }}
                                        inputValue={currentSearchValue}
                                        toogle={toggleTooltip}
                                        tooltipOpen={tooltipOpen}
                                        textTooltip={textTooltip}
                                      />
                                    </SearchbarWrapper>
                                    <Button
                                      style={{ float: 'right', marginRight: '2rem' }}
                                      color='success'
                                      onClick={() => setIsAddModalOpen(true)}
                                    >
                                      <AddIcon />
                                      <span>{f('add')}</span>
                                    </Button>
                                  </Col>
                                </FiltersAboveTable>
                                <StockTable
                                  sortCriteria={sortCriteria}
                                  setSortCriteria={setSortCriteria}
                                  columns={columns}
                                  data={filteredRows}
                                  dataCount={exampleCount}
                                  onCellChange={modifyItemHandler}
                                  onDeleteItem={deleteItemHandler}
                                  canSendNotification={selectedPharma.pharmacy.fidelity}
                                  sendPromotionNotification={sendPromotionNotification}
                                  pageSize={pageSize}
                                  offset={offset}
                                  setOffset={setOffset}
                                  setIsLoading={setIsLoading}
                                />
                              </CardBody>
                            )
                        }
                      </CardWrapper>
                    </Col>
                  </Row>
                </Container>
              )}
          </Container>
        </ContainerWrapper>
        {
          selectedPharma.pharmacy.pharmacy_menu?.stock === 1 && !isMultipharmaAccount &&
          <InfosViewDisabledModal
            isOpen={openModalDisabled}
            toggle={toggleDisabled}
          />
        }
      </StockWrapper>
    </>
  )
}

export default Stock
