import React from 'react'
import styled from 'styled-components'
import { Input, Label, FormGroup } from 'reactstrap'
import { useIntl } from 'react-intl'

import { CouponState } from 'models/product.model'

//   if (item.price != null && item.pourcent != null) return null
//   else if (item.price != null) return null
//   else if (item.pourcent != null) return null

interface CouponProps {
  points: number
  total: number
  item: CouponState
  checkCoupon: number
  setCheckCoupon: (id: number) => any
}

const Coupon = ({ points, total, item, checkCoupon, setCheckCoupon }: CouponProps): JSX.Element | null => {
  const { formatMessage } = useIntl()
  const f = (id: string): string => formatMessage({ id })

  const error = (item: any): JSX.Element | null => {
    if (item.point > points) {
      return <CouponErr>{f('NotEnoughPoints')}</CouponErr>
    } else if (total != null && total - item.price < 0) {
      return <CouponErr>{f('PriceTooLow')}</CouponErr>
    } else return null
  }

  if (item.point == null) return null

  if (item.price != null && item.pourcent != null) {
    return (
      <div className='checkbox-main'>
        <FormGroup>
          <Label check>
            <Input
              type='radio'
              onClick={() => { item.id !== checkCoupon ? setCheckCoupon(item.id) : setCheckCoupon(0) }}
              checked={checkCoupon === item.id}
              name='coupon-radio'
              disabled={
                (total - item.price < 0) || item.point > points
              }
            />
            {`${f('couponReduction')} ${item.price}€ + ${item.pourcent}% [${item.point} ${f('couponPoint')}]`}
          </Label>
          {error(item)}
        </FormGroup>
      </div>
    )
  }

  if (item.price != null) {
    return (
      <div className='checkbox-main'>
        <FormGroup>
          <Label check>
            <Input
              type='radio'
              onClick={() => { item.id !== checkCoupon ? setCheckCoupon(item.id) : setCheckCoupon(0) }}
              checked={checkCoupon === item.id}
              name='coupon-radio'
              disabled={
                (total - item.price < 0) || item.point > points
              }
            />
            {`${f('couponReduction')} ${item.price}€ [${item.point} ${f('couponPoint')}]`}
          </Label>
          {error(item)}
        </FormGroup>
      </div>
    )
  }

  if (item.pourcent != null) {
    return (
      <div className='checkbox-main'>
        <FormGroup>
          <Label check>
            <Input
              type='radio'
              onClick={() => { item.id !== checkCoupon ? setCheckCoupon(item.id) : setCheckCoupon(0) }}
              checked={checkCoupon === item.id}
              name='coupon-radio'
              disabled={item.point > points}
            />
            {`${f('couponReduction')} ${item.pourcent}% [${item.point} ${f('couponPoint')}]`}
          </Label>
          {error(item)}
        </FormGroup>
      </div>
    )
  }

  return null
}

const CouponErr = styled.p`
  font-size: 12px;
  font-style: italic;
  margin-left: 1rem;
  color: lightgray;
`

export default Coupon
