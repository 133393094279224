import styled from 'styled-components'
import { stylesLandingPage } from '../../../constants/styles.constants'

export const QuestionsContainer = styled.div`

img{
    height: 20px;
    min-width: 20px;
    margin-right: 0.5rem!important;
}

h5{
    color: #161c2d;
    font-weight: 600
}
p{
    line-height: 1.6;
    margin-top: 0;
    font-weight: 450;
}
span{
    color: ${stylesLandingPage.color_phacil} !important;
}
`

export const SecondContent = styled.div`
text-align: center;
@media (max-width: 768px){
    h4{
        font-size: 24px !important;
    }
}

h4{
    color: #161c2d;
    letter-spacing: 0.5px;
    font-size: 30px;
    
}

.btn{
    padding: 8px 20px !important;
    outline: 0 !important;
    text-decoration: none !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    color: white;
}    

.btn:hover{
    color: white !important;
    background-color: #4631e2
}
span{
    font-weight: 700;
    color: ${stylesLandingPage.color_phacil} !important;
}
.para-desc{
    max-width: 600px;
}
p{
    line-height: 1.6;
    margin-top: 0;
    font-weight: 450;
}
`
