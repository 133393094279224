const crisp = () => {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = '9a24fe78-15e2-4f4e-8f31-e09bd18d1188';

  (function () {
    var d = document
    var s = d.createElement('script')

    s.src = 'https://client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}

export default crisp
