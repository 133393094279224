
import { Card } from 'reactstrap'
import styled from 'styled-components'

export const CardWrapper = styled(Card)`

${props => (props.stock || props.order || props.voicemail || props.dashboardKPIs || props.notifications) && `{
    border: none;
}`};

margin-bottom: ${props => props.user && '20px'};

  h5 {
    color: var(--texte-bleu-fonce);
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  .card-header {
    background-color: white;
    border: ${props => props.order && 'none'};
  }

${props => props.user && `{
    .imagePharmacy {
        text-align: center;
        margin-top: 2rem;
      }

      .columnImage {
        text-align: center;
      }
}`};

${props => (props.order || props.voicemail || props.dashboardKPIs || props.notifications) && `{
    .active {
        color: #007bff;
        border-bottom: 2px solid #007bff;
      } 
}`};

${props => (props.voicemail || props.dashboardKPIs) && `{
    .nav {
        display: flex;
        padding-left: 0;
        list-style: none;
        margin-left: 1rem;
      }
    .defaultLink {
        border-bottom: 1px solid #dee2e6;
    }
}`};
`
