import * as actionTypes from './actionTypes'
// import API from '../../utils/API';
import API from '../utils/API'
import { AppThunk } from 'models/redux.model'
import { alertError, alertLoading, alertSuccessModifCompte, alertSuccess } from '../utils/notifications'
import { UserState, UpdatableUser } from 'models/user.model'
import { Pharmacy } from 'models/pharmacy.model'

export const verifyStripeStatus = (): AppThunk => {
  return async (dispatch) => {
    try {
      const statusResponse = await API.get('/users/stripe/connect-onboarding')

      const responseData = statusResponse.data
      if (responseData.state === 1 && responseData.result.url != null) {
        window.open(responseData.result.url, '_blank')
      } else {
        alertSuccess('Votre compte est à jour !')
      }
    } catch (e: any) {
      console.error('Error checking Stripe status, continuing to Dashboard')
    }
  }
}

/**
 * Save user data in the store
 * @function
 * @param {Object} userData data to save in the store
 * @returns {Object} Type of the action that fired witb the userData
 */
export const setUser = (userData: UserState) => {
  return {
    type: actionTypes.SET_USER,
    payload: userData
  }
}

/**
 * Sets auth status
 * @function
 * @param {boolean} boolean data to save in the store
 * @returns {Object} Type of the action that fired witb the userData
 */
export const setAuthStatus = (status: boolean) => {
  return {
    type: actionTypes.SET_AUTH_STATUS,
    payload: status
  }
}

/**
 * Save selected pharmacy
 * @function
 * @param {Object} pharmacy data to save in the store
 * @returns {Object} Type of the action that fired witb the userData
 */
export const setSelectedPharma = (pharmacy: Pharmacy) => {
  return {
    type: actionTypes.SET_SELECTED_PHARMA,
    payload: pharmacy
  }
}

const getPharmacies = async () => {
  try {
    const pharmaResponse = await API.get('/pharmacist/pharmacies')
    return [...pharmaResponse.data.result]
  } catch (e: any) {
    console.log(e)
    return []
  }
}

export const getUser = (): AppThunk => {
  return async (dispatch) => {
    try {
      const userResponse = await API.get('/pharmacist/profile')
      const pharmacies: Pharmacy[] = await getPharmacies()

      let isMultipharmaAccount = false
      dispatch(setSelectedPharma(pharmacies[0]))

      // 821 = id en dev/preprod
      // 422 = id en prod
      if (pharmacies[0]?.pharmacist_id === 821 || pharmacies[0]?.pharmacist_id === 422) {
        isMultipharmaAccount = true
      }

      dispatch(
        setUser({
          ...userResponse.data.data.user,
          pharmacies: pharmacies,
          isMultipharmaAccount,
          isAuthenticated: true
        })
      )
    } catch (e: any) {
      console.log(e)
      return []
    }
  }
}

export const forgetUser = () => {
  return {
    type: actionTypes.FORGET_USER
  }
}

const updateStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START
  }
}

const updateSuccess = (result: UserState) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload: result
  }
}

const updateFail = (error: Error) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    error: error
  }
}

export const updateUser = (userData: UpdatableUser, formatMessage: any): AppThunk => {
  return async (dispatch) => {
    dispatch(updateStart())
    alertLoading(formatMessage('editingProfile'))
    try {
      const updateUserRes = await API.put('/pharmacist/profile', userData)
      alertSuccessModifCompte(formatMessage('updatedProfile'))
      dispatch(updateSuccess(updateUserRes.data.data))
    } catch (e: any) {
      dispatch(updateFail(e))
      alertError(e.message)
      console.log(e)
    }
  }
}
