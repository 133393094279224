import styled from 'styled-components'
import { stylesLandingPage } from '../../../constants/styles.constants'

export const ContentPartner = styled.div`
h6{
    color: #161c2d;
}

h4{
    color: #161c2d;
    letter-spacing: 0.5px;
    font-size: 30px!important;
}
p{
    max-width: 600px;
}
span{
    font-weight: 700;
    color: ${stylesLandingPage.color_phacil}!important;
}
text-align: center;
`

export const CardPartner = styled.div`
text-align: center;
height: 100%;

p{
    text-align: justify;
}
h6{
    color: ${stylesLandingPage.color_phacil}!important;
}
span{
    font-weight: 800;
}
`
