if (process.env.NODE_ENV === 'production') {
  module.exports = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    apiKey: process.env.REACT_APP_API_KEY,
    timeSlot: process.env.REACT_APP_RDV_TIMESLOT,
    pushVapidKey: process.env.REACT_APP_PUSH_VAPID_KEY,
    tagManagerId: process.env.REACT_APP_TAG_MANAGER_ID
  }
} else {
  module.exports = require('./dev')
}
