import styled from 'styled-components'

export const NoResultFoundWrapper = styled.div`
min-height: 500px;
justify-content: center;
align-items: center;
display: flex;
.subtitle{
  font-size: 14px;
  color: #6A7383;
}
`

export const ImageWrapper = styled.div `
background-color: #f6f8fa;
display: inline-block;
padding: 10px;
`

export const TitleWrapper = styled.div `
padding-top: 24px;
.title{
  color: #1A1B25;
  font-size: 20px;
  font-weight: bold;
}
`
