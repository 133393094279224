import styled from 'styled-components'

export const OrdersWrapper = styled.div`
  margin: 0;
  width: 100%;
  display: block;
  .bodyWrapper {
    background: var(--gris-bleu);
    width: 100%;
    padding: 1rem;
    @media (min-width: 60rem) {
      min-width: 70%;
      max-width: calc(100% -20rem);
    }
    .bodyHeader {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      button {
        display: flex;
        align-items: center;
        svg {
          height: 1.5rem;
          margin-right: 0.2rem;
        }
      }
    }
  }

  //Raph :
  .AddButtonWrapper {
    display: flex;
    align-items: flex-start;
    button {
      margin-left: auto;
    }
  }
  background-color: ${props => props.property === 'qrcode' ? 'var(--gris-bleu)' : 'white'};
  .container-fluid {
    padding: 20px 20px 60px 20px;
  }

  @media (max-width: 769px) {
    flex-direction: column;
  }
  .headerTitle {
    margin-left: ${props => props.property !== 'qrcode' && '1rem'};
    margin-bottom: ${props => props.property === 'qrcode' && '2rem'};
    h2 {
      color: var(--texte-bleu-fonce);
      font-size: 1.5rem;
      margin-bottom: ${props => props.property === 'qrcode' && '1rem'};
    }
  }
  label {
    display: flex;
    align-items: center;
  }
  label select {
    width: unset;
  }
  .blur{
    filter: ${props => props.property === 'order' && 'blur(8px)'};
    pointer-events: ${props => props.property === 'order' && 'none'};
  }

  ${props => (props.property === 'voicemail' || props.property === 'dashboardKPIs') && `{

    table {
      background-color: #fff;
      border: 1px solid rgba(63, 63, 68, 0.005);
      box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
        0px 1px 3px rgba(63, 63, 68, 0.15);
      border-radius: 4px;
      thead,
      th,
      td {
        height: 40px;
      }
    }

    .table td {
      padding-right: 0.1rem !important;
      padding-top: 0.2rem !important;
      padding-bottom: 0.2rem !important;
    }
    
    .table th {
      padding-right: 0.1rem !important;
    }
    .table th:first-child {
      padding-left: unset !important;
    }
    
    .table td:first-child {
      padding-left: 0.5rem !important;
    }
  }`}
`
