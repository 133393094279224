export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'

export const SET_USER = 'SET_USER'
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS'
export const FORGET_USER = 'FORGET_USER'
export const SET_SELECTED_PHARMA = 'SET_SELECTED_PHARMA'

export const UPDATE_USER_START = 'UPDATE_USER_START'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const LOADING_START = 'LOADING_START'
export const LOADING_DONE = 'LOADING_DONE'
export const SET_ERROR_MSG = 'SET_ERROR_MSG'

export const ADD_PRODUCT_ITEM = 'ADD_PRODUCT_ITEM'
export const DELETE_PRODUCT_ITEM = 'DELETE_PRODUCT_ITEM'
export const UPDATE_PRODUCT_ITEM = 'UPDATE_PRODUCT_ITEM'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
