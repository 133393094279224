import React, { useEffect, useState } from 'react'
import {
  ModalHeader,
  ModalBody,
  Modal
} from 'reactstrap'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import API from '../../utils/API'
import BlockTable from '../BlockTable/index'
import { Column, Row as RowType } from 'models/table.model'
import { BlockSlot } from 'models/appointment.model'
import { alertError, alertSuccess } from 'utils/notifications'
import socket, { CONNECTION_PORT } from 'utils/socket'
import { applySort } from 'utils/sortHandler'

registerLocale('fr', fr)
setDefaultLocale('fr')

interface BlockProps {
  isOpen: boolean
  closeModal: () => void
  backdrop: boolean
  pharmaId: number
  getAvailableDateAppointment: () => void

}

const BlockModal: React.FC<BlockProps> = ({
  isOpen,
  closeModal,
  backdrop,
  pharmaId,
  getAvailableDateAppointment

}) => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })
  const [data, setData] = useState<BlockSlot[]>([]) // Array of examples
  const [offset, setOffset] = useState(0)

  const columns: Column[] = [
    {
      Header: f('startDate'),
      accessor: 'start_date',
      filtrable: true
    },
    {
      Header: f('endDate'),
      accessor: 'end_date',
      filtrable: true
    },
    {
      Header: f('motif'),
      accessor: 'motif',
      filtrable: true
    }

  ]

  const [sortCriteria, setSortCriteria] = useState<{
    direction: 'ASC' | 'DESC'
    accessor: string
  } | null>(null)

  const getDisableAppointmentToDelete = (): void => {
    API.post('unlockedAppointment/getDisableAppointmentToDelete', {
      pharmacy_id: pharmaId
    })
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.unlockedAppointments)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const refresh = (): void => {
    getDisableAppointmentToDelete()
    getAvailableDateAppointment()
  }

  useEffect(() => {
    // If socket was needed to avoid undefined error
    // because socket's not initialized in useEffect
    if (socket != null) {
      socket.emit('join_room', 'blockSlots')
    }
  }, [CONNECTION_PORT])

  useEffect(() => {
    socket.on('reload_booked', () => {
      refresh()
    })
  }, [isOpen])

  // const deleteItemHandler: (data: RowType) => Promise<any> = async ({ id }: { id: number }) => {
  const deleteItemHandler: (data: RowType) => Promise<any> = async ({ id }) => {
    try {
      await API.delete(`/unlockedAppointment/deleteDisableAppointment/${id}`, {
      })
      refresh()
      await socket.emit('reload_booked', 'blockSlots')
      closeModal()
      alertSuccess(f('blockSuccess'))
    } catch (e: any) {
      console.error(e)
      alertError(f('unBlockError'))
    }
  }

  useEffect(() => {
    getDisableAppointmentToDelete()
  }, [isOpen])

  useEffect(() => {
    applySort(data, setData, sortCriteria)
  }, [sortCriteria])

  return (
    <div>
      <ModalWrapper
        isOpen={isOpen}
        toggle={closeModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={backdrop}
      >
        <ModalHeader className='motif'>{f('blockSlots')}</ModalHeader>
        <ModalBody>
          <BlockTable
            sortCriteria={sortCriteria}
            setSortCriteria={setSortCriteria}
            columns={columns}
            data={data}
            onDeleteItem={deleteItemHandler}
            pageSize={5}
            offset={offset}
            setOffset={setOffset}
            isOpen={isOpen}
          />
        </ModalBody>
      </ModalWrapper>
    </div>
  )
}

export default BlockModal

const ModalWrapper = styled(Modal)`
`
