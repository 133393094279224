import { DropdownMenu, Form } from 'reactstrap'
import styled from 'styled-components'

export const DropDownMenuDate = styled(DropdownMenu)`
overflow: unset !important;
background-color: #f6f8fa;
border: none !important;
color: #404452;
width: 100%;
margin-top: -2px;
box-shadow: 
0 0 0 1px rgb(136 152 170 / 10%),
0 15px 35px 0 rgb(49 49 93 / 10%),
0 5px 15px 0 rgb(0 0 0 / 8%);
border-radius: 0rem 0rem 0.25rem 0.25rem !important;
`

export const FormFilter = styled(Form)`

.form-control{
    padding-top: 0.15rem !important;
    padding-bottom: 0.15rem !important;
    font-size: 13px;
    text-align: center;
    border-radius: 0.6rem !important;
    width: 100%;
}
.form-control-select{
    padding-top: 0.15rem !important;
    padding-bottom: 0.15rem !important;
    margin-bottom: 0.75rem;
    font-size: 13px;
    border-radius: 0.6rem !important;
    height: calc(1.5em + .3rem + 2px);
    width: 77%;
    margin-left: auto;
    margin-right: auto;
} 
.form-group{
    margin-bottom: unset !important;
}

div{
    .form-control{
        padding: unset !important;
    }
}

`