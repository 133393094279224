import styled from 'styled-components'

export const StyledContainer = styled.div`
  .OrderManagementWrapper {
    width: 100%;
    margin: 0;
    display: block;
    // background-color: #efeff6;
    .containerWrapper {
      margin-left: 230px;
      .container-fluid {
        padding: 30px 20px 60px 20px;
        .headerTitle {
          margin-bottom: 2rem;
          .divider {
            border-top: 1px solid var(--border-separation);
          }
        }
        .bodyHeader {
          input {
            min-height: 40px;
          }
          .searchBar {
            border: none;
          }
          .dropdown {
            button {
              min-height: 40px;
              border: none;
              background-color: #fff;
              color: #007bff;
              font-weight: 600;
              transition: all 0.3s;
              min-width: 130px;
              &:hover {
                background: linear-gradient(to right, #00b2ff, #0066ff);
                color: #fff;
              }
              &:focus {
                background: linear-gradient(to right, #00b2ff, #0066ff);
                background-color: unset !important;
                color: #fff;
                border-color: unset !important ;
                box-shadow: unset !important;
              }
            }
          }
          .AddUser {
            text-align: end;
            button {
              min-width: 150px;
              min-height: 40px;
            }
          }
        }
      }
    }
  }
  .orderManager {
    background-color: #f9fafc;
    min-height: 100vh;
    .title-main {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 26px;
      letter-spacing: 0.01em;
      color: #0d1c2e;
      margin-bottom: 12px;
    }
    .description-main {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: #707c97;
    }
  }
  .block-text-main {
    width: 80%;
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #2a8bf2;
      margin-bottom: 12px;
    }
    &__description {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 151.69%;
      color: #1f1f1f;
      margin-bottom: 0px;
    }
  }
  .block-form-add {
    margin-bottom: 16px;
    margin-top: 32px;
    display: flex;
    .text-or {
      margin-top: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1f1f1f;
      margin-left: 36px;
      margin-right: 36px;
    }
  }
  .block-user-info {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    padding: 25px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: #002643;
      margin-bottom: 10px;
    }
    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 20px;
      letter-spacing: -0.05px;
      color: #3a3b3f;
      margin-bottom: 12px;
    }
    .description {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.05px;
      color: #3a3b3f;
    }
    .bold {
      font-weight: 500;
    }
    .content-info {
      margin-top: 20px;
    }
  }
  .block-bar-code {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    padding: 10px 30px;
    width: fit-content;
    margin-top: 20px;
    img {
      width: 328px;
      height: 147px;
    }
  }
  .btn-text-main {
    button {
      color: #fff;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.002em;
    }
  }
  .block-btn-main {
    margin-top: 32px;
    display: flex;
    &--justify {
      justify-content: space-between;
    }
    &--end {
      justify-content: flex-end;
    }
    .btn-next {
      display: inline-block;
      button {
        width: 184px;
        height: 40px;
        color: #fff;
        background-color: #2a8bf2;
        border: none;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover,
        &:active,
        &:focus {
          border: none !important;
          box-shadow: 0 0 0 !important;
          background-color: #2174cc !important;
        }
      }
    }
    .btn-preview {
      display: inline-block;
      button {
        border: none;
        width: 184px;
        height: 40px;
        background-color: #f2f2f2;
        color: #000;

        &:hover,
        &:active,
        &:focus {
          border: none !important;
          box-shadow: 0 0 0 !important;
          background-color: #dbdbdb !important;
        }
      }
    }
  }
  .btn-scan-card {
    button {
      width: 236px;
      height: 40px;
      background-color: #27ae60;
    }
  }
  .block-form-add {
    .input-main {
      position: relative;
      margin-bottom: 0px;
      label {
        position: absolute;
        top: -8px;
        left: 12px;
        padding: 0px 6px;
        background-color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #4f4f4f;
        margin-bottom: 0;
      }
      .form-control {
        border: 1px solid #828282;
        background-color: #f9fafc;
        width: 360px;
        height: 40px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
      }
      &.error {
        label {
          color: #eb5757;
        }
        .form-control {
          border: 1px solid #eb5757;
        }
        .msg-error {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #eb5757;
          margin-left: 16px;
        }
      }
    }
  }
  .block-total-price-list {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    gap: 12px;
  }
  .block-total-price {
    width: 380px;
    padding: 18px 23px;
    background-color: #e8663d;
    border-radius: 4px;
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.996px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 0px;
    }
    &__line {
      background-color: #fff;
    }
    &__total-price {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.06px;
      color: #ffffff;
      margin-bottom: 0;
      span {
        font-weight: bold;
      }
    }
    .checkbox-main {
      margin-left: 16px;
      .form-check-label {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0428571px;
        color: #ffffff;
      }
      .form-check-input {
        position: relative;
        width: 18px;
        height: 18px;
        margin-right: 12px;
      }
    }
  }
  .block-form-add-input-btn {
    display: flex;
    .btn-add {
      button {
        width: 98px;
        height: 40px;
        background-color: #27ae60;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        &:active,
        &:focus,
        &:hover {
          background-color: #239654 !important;
          box-shadow: 0 0 0 #27ae60 !important;
          border-color: #27ae60 !important;
        }
      }
    }
  }

  .block-form-add-input-custom-btn {
    margin-top: 3rem;
    display: inline-block;
    text-align: center;
    h5{
      margin-bottom: 2rem;
    }
    .btn-add {
      button {
        margin-top: 2rem;
        width: 98px;
        height: 40px;
        background-color: #27ae60;
  
        &:active,
        &:focus,
        &:hover {
          background-color: #239654 !important;
          box-shadow: 0 0 0 #27ae60 !important;
          border-color: #27ae60 !important;
        }
      }
    }
  }


  .process-bar-step {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 32px;
    margin-bottom: 0px;
    margin-left: 50px;
    &__item {
      width: 200px;
      height: 24px;
      text-align: center;
      position: relative;
      .text {
        position: absolute;
        top: -45px;
        left: 50%;
        transform: translateX(-50%);

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #828282;
        min-width: 9rem;
      }
      .step {
        background: #ffffff;
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #828282;
        position: relative;
        &::before {
          content: '';
          width: 176px;
          height: 4px;
          background: #e8e8e8;
          display: inline-block;
          position: absolute;
          top: 9px;
          left: -177px;
        }
      }
      &:first-child {
        .step {
          &::before {
            width: 50px;
            left: -51px;
          }
        }
      }
      &:last-child {
        .step {
          &::after {
            content: '';
            height: 4px;
            background: #e8e8e8;
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 23px;
            width: 50px;
          }
        }
      }
      &.active {
        .text {
          font-weight: bold;
          color: #2a8bf2;
        }
        .step {
          background-color: #2a8bf2;
          border: 1px solid #2a8bf2;
          &::before {
            background-color: #2a8bf2;
          }
        }
        &:last-child {
          .step {
            &::after {
              background-color: #2a8bf2;
            }
          }
        }
      }
    }
  }
  .input-main {
    position: relative;
    margin-bottom: 0px;
    label {
      position: absolute;
      top: -8px;
      left: 12px;
      padding: 0px 6px;
      background-color: #fff;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #4f4f4f;
      margin-bottom: 0;
    }
    .form-control {
      border: 1px solid #828282;
      background-color: #f9fafc;
      width: 360px;
      height: 40px;
    }
    &.error {
      label {
        color: #eb5757;
      }
      .form-control {
        border: 1px solid #eb5757;
      }
      .msg-error {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #eb5757;
        margin-left: 16px;
      }
    }
    &--custom {
      input {
        height: 26px;
        width: 50px;
        background-color: #f2f2f2;
        border: none;
        outline: none;
        text-align: center;
        &:focus {
          background-color: #f2f2f2;
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  tr {
    input {
      padding-right: 0px !important;
    }

    .form-group {
      margin: 0 !important;
    }
  }

  .delete-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    &:active,
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }

  .block-table-main {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    &__top {
      padding: 12px;
      padding-left: 24px;
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.05px;
      color: #3a3b3f;
    }
    thead,
    th,
    tr,
    td {
      height: 12px;
    }
  }
  .block-bg-item {
    padding: 2rem;
    background-color: #fff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
  }
  .block-bg-profile {
    background-color: #fff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    width: 20%;
  }
  .profile{
    display: inline-block;
    width: 25%;
    text-align: center;

  }
  .informations{
    display: inline-block;
    width: 60%;
    color: #828282;
    text-align: center;
    p{
      margin: 0.3em;
      font-size: 12px
    }
    
  }
  .btn-cancel {
    button {
      margin-top: 2rem;
      width: 98px;
      height: 40px;
      background-color: #6c757d;

      &:active,
      &:focus,
      &:hover {
        background-color: #5a6268 !important;
        box-shadow: 0 0 0 #6c757d !important;
        border-color: #6c757d !important;
      }
    }
  }
  
`
