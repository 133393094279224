import React from 'react'
import styled from 'styled-components'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const BreadcrumbWrapper = styled.div`
    border-top: 1px solid var(--border-separation);
    ol {
        display: flex;
        padding: 0.5rem 0 0 0;
        margin: 0!important;
    }
    ol li {
        list-style-type: none;
        display: flex; 
        align-items: center;
    }
    ol li+li{
        padding-left: 0.5rem;
    }
    ol li a {
        color: var(--breadcrum-text);
        text-decoration: none;
        font-size: 0.7rem;
        font-weight:700;
    }
    .actif {
        color: var(--breadcrum-actif);
        font-size: 0.7rem;
        font-weight:700;
    }
    ol li a:hover {
        color: var(--texte-navbar-hover);
    }
    
    svg {        
        font-size: 0.7rem;
        margin-right:0.5rem;
        color: var(--breadcrum-text);
    }
`

const Breadcrum = () => {
  return (
    <BreadcrumbWrapper>
      {/* <ol>
        <li>Dashboard</li>
        <li><FontAwesomeIcon icon={faAngleRight} />Tables</li>
        <li className="actif"><FontAwesomeIcon icon={faAngleRight} />Data Tables</li>
    </ol> */}
    </BreadcrumbWrapper>

  )
}

export default Breadcrum
