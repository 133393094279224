import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { refreshSession } from '../actions'
import { RootState } from 'models/redux.model'

import LandingPage from '../containers/LandingPage'
import Password from 'containers/Password'
import User from '../containers/User'
import Stock from '../containers/Stock'
import Orders from '../containers/Orders'
import ImportCSV from '../containers/ImportCSV'
import Appointment from '../containers/Appointment'
import UserManagement from '../containers/UserManagement/UserManagement'
import CreateUser from '../containers/UserManagement/CreateUser'
import UserDetail from '../containers/UserManagement/UserDetail'
import OrderManagement from '../containers/OrderManagement/OrderManagement'
import ScheduleManagement from '../containers/ScheduleManagement'
import QRCodes from '../containers/QRCodes'
import Income from '../containers/Income'
import VoiceMessages from '../containers/VoiceMessages'

import Layout from '../components/Layout'
import crisp from 'plugins/crisp'
// import chatIndex from '../components/chatIndex.old'

import initNotifications from '../utils/pushNotifications'
import { useIntl } from 'react-intl'
import WelcomeMessageVoice from 'containers/WelcomeMessagevoice'
import Notifications from 'containers/Notifications'

import DashboardKPIs from '../containers/DashboardKPIs'
import config from '../config/keys'
const keys = config as {
  tagManagerId: string
}


const App: React.FC = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated)
  const pharmacistId = useSelector((state: RootState) => state.user.selectedPharma.pharmacist_id)
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })
  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma)
  const isMultipharmaAccount = useSelector((state: RootState) => state.user.isMultipharmaAccount)

  useEffect(() => {
    crisp()

    if (process.env.REACT_APP_CUSTOM_ENVIRONMENT === 'production' && keys.tagManagerId != null) {
      TagManager.initialize({ gtmId: keys.tagManagerId })
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated === false || pharmacistId === -1) return
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      initNotifications(pharmacistId).catch(err => console.log(err))
    }
  }, [isAuthenticated, pharmacistId])

  /**
   * Persistent authentication
   */
  useEffect(() => {
    dispatch(refreshSession(f))
  }, [])

  // Routes to display if the user is not authenticated
  const authRoutes = (
    <Switch>
      <Route exact path='/' component={LandingPage} />
      <Route exact path='/password' component={Password} />
      <Redirect to='/' />
    </Switch>
  )

  // Routes to display if user is authenticated
  const defaultRoutes = (
    <Switch>
      <Route exact path='/stock' component={Stock} />
      <Route exact path='/orders' component={Orders} />
      <Route exact path='/user' component={User} />
      <Route exact path='/' render={() => (<Redirect to='/orders' />)} />
      {/* <Route exact path='/' component={Orders} /> */}
      <Route exact path='/import-stock' component={ImportCSV} />
      <Route exact path='/calendrier' component={Appointment} />
      {/* <Route exact path='/messages' component={chatIndex} /> */}
      {isMultipharmaAccount === true
        ? <Route exact path='/kpis' component={DashboardKPIs} />
        : <Route exact path='/kpis' render={() => (<Redirect to='/orders' />)} />}
      {selectedPharma.pharmacy.fidelity
        ? <Route exact path='/manage' component={UserManagement} />
        : <Route exact path='/manage' render={() => (<Redirect to='/orders' />)} />}
      {selectedPharma.pharmacy.fidelity
        ? <Route exact path='/manage/new-user' component={CreateUser} />
        : <Route exact path='/manage/new-user' render={() => (<Redirect to='/orders' />)} />}
      {selectedPharma.pharmacy.fidelity
        ? <Route exact path='/manage/detail-user/:id' component={UserDetail} />
        : <Route exact path='/manage/detail-user/:id' render={() => (<Redirect to='/orders' />)} />}
      {selectedPharma.pharmacy.fidelity
        ? <Route exact path='/order-management' component={OrderManagement} />
        : <Route exact path='/order-management' render={() => (<Redirect to='/orders' />)} />}
      <Route exact path='/schedule' component={ScheduleManagement} />
      <Route exact path='/qrcodes' component={QRCodes} />
      <Route exact path='/revenus' component={Income} />
      {selectedPharma.pharmacy.standard
        ? <Route exact path='/voicemail' component={VoiceMessages} />
        : <Route exact path='/voicemail' render={() => (<Redirect to='/orders' />)} />}
      <Route exact path='/voicemail/welcome' component={WelcomeMessageVoice} />
      {isMultipharmaAccount === true
        ? <Route exact path='/notifications' component={Notifications} />
        : <Route exact path='/notifications' render={() => (<Redirect to='/orders' />)} />}
      <Redirect to='/' />
    </Switch>
  )

  return (
    <Layout isAuthenticated={isAuthenticated}>
      {isAuthenticated == null ? null : (!isAuthenticated ? authRoutes : ((selectedPharma.pharmacy.pharmacy_id !== -1 && isMultipharmaAccount !== null) ? defaultRoutes : null))}
    </Layout>
  )
}

export default withRouter(App)
