import React from 'react'
import styled from 'styled-components'

import { StyledTable } from 'styles'
import { Column, Row as RowModel } from 'models/table.model'
import { useIntl } from 'react-intl'
import { Col, Row } from 'reactstrap'
import moment from 'moment'
import { fee } from 'models/income.model'
import RenderCell from 'components/RenderCell'

const PdfIncome = React.forwardRef((props: { data: fee, columns: Column[], parent?: string }, ref: any) => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  return (
    <Wrapper ref={ref}>
      <HeaderIncome>
        <Row>
          <Col>
            {moment(new Date()).format('DD/MM/YY, HH:MM')}
          </Col>
          <Col>
            {f('phacilPharmacists')}
          </Col>
        </Row>
        <Row>
          <Col style={{ marginLeft: '2rem', marginTop: '1rem' }}>
            <a href='/orders'>
              <img
                className='logoPhacil'
                src='https://phacil.delivery/static/PNG/Assets-3/logo-Phacil-115.png'
                alt='Logo Phacil'
              />
            </a>
          </Col>

        </Row>
      </HeaderIncome>
      <StyledIncome>
        {props.parent === 'autoIncome'
          ? <h2>
            Récapitulatif des commandes
          </h2>
          : <h2>
            {f('recapFee')}
          </h2>}

        <StyledTable responsive>
          <thead>
            <tr>
              {props.columns.map((column) => {
                return (
                  <th key={'head_' + column.accessor}>
                    <div>
                      <span>{column.Header}</span>
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {props.data.map((row: any, i: number) => (
              <tr key={`body_tr_${i}`} style={{ backgroundColor: 'white' }}>
                {props.columns.map((column) => {
                  return <RenderCell key={i} row={row} rowIndex={i} column={column} page='pdfIncome' />
                })}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </StyledIncome>
      <FooterIncome>
        <Row>
          <Col>

            <a href='https://www.phacil.app/revenus'>
              https://www.phacil.app/revenus
            </a>
          </Col>
          <Col>
            <span style={{ float: 'right' }}>1/1</span>
          </Col>
        </Row>

      </FooterIncome>

    </Wrapper>

  )
})

const Wrapper = styled.div`
padding: 2rem;
 `

const StyledIncome = styled.div`
width: 90%;
margin: auto;
border: none;
margin-top: 3rem;
h2 {
color: var(--texte-bleu-fonce);
font-size: 1.5rem;
margin-bottom: 1rem;
  }   
   `

const HeaderIncome = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-navbar);
  }   
    `

const FooterIncome = styled.div`
position: absolute;
width: 90%;
bottom: 0;
margin-bottom: 2rem;
  a{
      text-decoration: none;
      color: black;
  }


`
export default PdfIncome
