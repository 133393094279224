import React, { FormEvent, useEffect, useState } from 'react'
import { Input, Tooltip } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import IconMagnifyingGlass from '../assets/images/magnifying-glass-solid.svg'
import IconMark from '../assets/images/xmark-solid.svg'

const SearchFormWrapper = styled.form`
    display: flex;
    align-items: center;
    margin: 0;
    input {
        margin-right: 0.5rem;
    }
    position: relative;
`
const IconWrapper = styled.div`
padding: 4px;
position: absolute;
top:50%;
right:6%;
transform: translateY(-50%);
`

interface SearchFormProps {
  onSearch: () => any
  inputValue: string
  setInputValue: (newValue: string) => any
  setIsSearchable?: (value: boolean) => any
  setClearTimeouts?: (value: any) => any
  tooltipOpen?: boolean
  toogle?: () => any
  textTooltip?: string
};

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, inputValue, tooltipOpen, textTooltip, setInputValue, setIsSearchable, setClearTimeouts, toogle }) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSearch()
  }
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const [displayMark, setDisplayMark] = useState(false)

  useEffect(() => {
    if (inputValue !== '') {
      setDisplayMark(true)
    } else {
      setDisplayMark(false)
    }
  }, [inputValue])
  return (
    <SearchFormWrapper onSubmit={handleSubmit} id='Tooltip-magnifyingGlass'>
      {
        tooltipOpen != null && (
          <IconWrapper>
            {displayMark
              ? <img
                src={IconMark} alt='mark' width={15} height={15} style={{ cursor: 'pointer' }} onClick={() => {
                  setDisplayMark(false)
                  setInputValue('')
                }}
              />
              : <img src={IconMagnifyingGlass} alt='magnifyingGlass' width={15} height={15} />}
          </IconWrapper>
        )
      }

      <Input
        type='text'
        value={inputValue}
        style={{ paddingRight: tooltipOpen != null ? '3rem' : '' }}
        onChange={e => {
          (setIsSearchable != null) && setIsSearchable(false)
          setInputValue(e.target.value)
          const timeout = setTimeout(() => {
            (setIsSearchable != null) && setIsSearchable(true)
              ; (setClearTimeouts != null) && setClearTimeouts((old: number[]) => [...old, timeout])
          }, 1000)
        }}
        placeholder={tooltipOpen != null ? f('nameCodeEan2') : f('nameCodeEan')}

      />
      {tooltipOpen != null && !displayMark && (
        <Tooltip placement='right' isOpen={tooltipOpen} target='Tooltip-magnifyingGlass' toggle={toogle}>
          {textTooltip}
        </Tooltip>
      )}

      {/* <Button color="primary" type="submit">Rechercher</Button> */}

    </SearchFormWrapper>
  )
}

export default SearchForm
