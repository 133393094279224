import React from 'react'
import styled from 'styled-components'

import Pagination from './Pagination'

import HeaderTable from 'components/HeaderTable'

import { sortChangedHandler } from 'utils/sortHandler'
import RenderCell from 'components/RenderCell'
import { StyledTable } from 'styles'
import { TableProps } from 'models/table.model'

export type InputType = | 'text' | 'hidden'

const DashboardKPIsTable: React.FC<TableProps> = ({
  columns,
  data,
  setSortCriteria,
  sortCriteria,
  pageCount,
  offset,
  setOffset,
  activeTab,
  pageSize

}) => {
  const dataToShow = data.slice((offset as number) * (pageSize as number), (pageSize as number) * ((offset as number) + 1))

  // Render the UI for your table
  return (
    <Wrapper>
      <StyledTable responsive>
        <thead>
          <tr>
            <HeaderTable
              columns={columns}
              sortCriteria={sortCriteria}
              sortChangedHandler={sortChangedHandler}
              setSortCriteria={setSortCriteria}
              thEqual
              thAccessor='createdAt'
              thClassname='spaceDate'
              sortDescOrAsc='ASC'
              parent='dashboardKPIs'
            />
          </tr>
        </thead>
        <tbody>
          {dataToShow.map((row: any, i: number) => (
            <tr key={`body_tr_${i}`}>
              {columns.map((column) => {
                return (
                  <RenderCell
                    key={i}
                    row={row}
                    rowIndex={i}
                    column={column}
                    page='dashboardKPIs'
                    activeTab={activeTab}
                  />
                )
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <Pagination
        onGoToPage={(newPage) => {
          setOffset?.(newPage)
        }}
        onNavigate={(step) => {
          setOffset?.((prevValue) => prevValue + step)
        }}
        offset={offset != null ? offset : 0}
        pageCount={pageCount != null && pageCount > 0 ? pageCount : 1}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

export default DashboardKPIsTable
