import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage, FormikErrors, FormikTouched } from 'formik'
import { Row } from 'reactstrap'
import * as Yup from 'yup'

import styled from 'styled-components'

import CustomButton from '../../ui/Button'
import { useIntl } from 'react-intl'

interface data {
  price: number
  prescriptionId: number
  getData: () => void
  setPrice: Dispatch<SetStateAction<number>>
}

const ModalOrdonnancePrice = (props: data) => {
  const [showPrice, setShowPrice] = useState(false)
  const [price, setPrice] = useState<number>(props.price)
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  useEffect(() => {
    props.setPrice(price)
  }, [props, price])

  const priceDisplay = (
    errors: FormikErrors<{
      price: number
    }>,
    touched: FormikTouched<{
      price: number
    }>
  ) => {
    if (props.price > 0) setShowPrice(true)

    if (!showPrice) {
      return (
        <CustomButton
          onClick={() => {
            setShowPrice(true)
          }}
        >
          {f('defineRest')}
          <StyledImg src='/assets/icons/Icon_secure_orange@1,5x.svg' alt='Secure icon' />
        </CustomButton>
      )
    }

    const type = showPrice ? 'text' : 'hidden'
    return (
      <Field
        value={price}
        name='price'
        type={type}
        className={'form-control' + (errors.price && touched.price ? ' is-invalid' : '')}
        onChange={(e: any) => setPrice(e.target.value)}
      />
    )
  }

  return (
    <Row>
      <Formik
        initialValues={{
          price: props.price
        }}
        validationSchema={Yup.object().shape({
          price: Yup.number().required(f('pleasePrice'))
        })}
        onSubmit={(fields) => { }}
      >
        {({ errors, touched }) => (
          <StyledForm>
            <PriceWrapper>
              {priceDisplay(errors, touched)}
              <ErrorMessage name='price' component='div' className='invalid-feedback' />
            </PriceWrapper>
          </StyledForm>
        )}
      </Formik>
    </Row>
  )
}

const StyledImg = styled.img`
  width: 18px;
  height: 30px;
  margin-left: 5px;
`

const PriceWrapper = styled.div`
  position: relative;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-bottom: 1rem;
  padding-left: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  flex: 1 1 auto;
`

const StyledForm = styled(Form)`
  width: -moz-available;
`

export default ModalOrdonnancePrice
