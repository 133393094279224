import React, { useCallback, useEffect, useState } from 'react'
import { createTntFile } from 'utils/generateTnt'
import { Row } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import API from 'utils/API'
import { alertError, alertInfo } from 'utils/notifications'
import { useSelector } from 'react-redux'
import { RootState } from 'models/redux.model'
import { reloadTntFile } from 'utils/socket'

const DownloadTntFile = (props: any) => {
  const [clicked, setClicked] = useState(false)
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const [dataFileTnt, setDataFileTnt] = useState<any[]>([])
  const pharmacyState = useSelector((state: RootState) => state.user.selectedPharma)
  const [buttonProps, setButtonProps] = useState({})

  useEffect(() => {
    reloadTntFile(getTntFile, props.data.orderId)
  }, [])

  const getTntFile = useCallback(async (): Promise<void> => {
    try {
      const res = await API.post('/file/getOrderInfo', { orderId: props.data.orderId, pharmacyOrderId: props.data.pharmacyOrderId })
      if (res.data.success === true) {
        setDataFileTnt(res.data.data)
        if (pharmacyState.pharmacy.tnt_delivery_form === 'manual' && res.data.data.length === 0) {
          const props = {
            'data-title': 'Bon de commande en cours de génération'
          }
          setButtonProps(props)
        }
      }
    } catch (err) {
      console.log('err: ', err)
      alertError('Erreur lors de la récupération du bon de commande')
    }
  }, [props.data.orderId])

  useEffect(() => {
    getTntFile().catch(err => console.log(err))
  }, [props.data.orderId])

  const downloadTntFileFromAdmin = async (): Promise<void> => {
    if (dataFileTnt.length > 0) {
      const link = document.createElement('a')
      link.href = dataFileTnt[0].fileLocation
      link.download = dataFileTnt[0].name
      link.click()
    } else {
      alertInfo('Bon de commande non généré')
    }
  }

  const sendEmailToPhacil = async (pharmacyName: string, modeTnt: string = 'manual', orderId: number): Promise<void> => {
    try {
      const content = `La pharmacie ${pharmacyName} (mode: ${modeTnt}) à téléchargé le bon TNT de la commande ${orderId}`
      const res = await API.post('/mail/notification', { content })
      if (res.data.success === true) {
        console.log('sendEmailToPhacil', res.data.success)
      }
    } catch (err) {
      console.log('err: ', err)
    }
  }

  const StyledButton =
    !clicked
      ? styled.button`
          border: ${props => props.disabled === true ? 'solid 1px #CCCCCC' : 'solid 1px #6c757d'} ;
          color: ${props => props.disabled === true ? '#6c757d' : '#ffff'};
          background-color: ${props => props.disabled === true ? '#CCCCCC' : '#6c757d'};
          border-color: #6c757d;
          box-shadow: none;
          border-radius: 3px;
          padding: 5px;
          width: 15rem;
          min-height: 3rem;
          font-size: 12px;
        `
      : styled.button`
          background-image: linear-gradient(to bottom right, #ff625b, #ffb064);
          border: solid 1px #ffb064;
          color: white;
          box-shadow: none;
          border-radius: 3px;
          padding: 5px;
          width: 15rem;
          min-height: 3rem;
          font-size: 12px;
        `

  return (
    <>
      <StyledRow>
        <StyledButton
          {...buttonProps}
          disabled={pharmacyState.pharmacy.tnt_delivery_form === 'manual'
            ? !(dataFileTnt.length > 0)
            : false}
          onClick={() => {
            setClicked(true)
            pharmacyState.pharmacy.tnt_delivery_form === 'auto'
              ? createTntFile(props.data)
              : downloadTntFileFromAdmin().catch(err => console.log(err))
            sendEmailToPhacil(pharmacyState.pharmacy.name, pharmacyState.pharmacy.tnt_delivery_form, props.data.orderId).catch(err => console.log(err))
          }}
        >
          {f('dlTnt')}
        </StyledButton>
      </StyledRow>
    </>
  )
}
const StyledRow = styled(Row)`
  position: relative;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
  display: -webkit-box;
  display: -webkit-flex;  
  display: -ms-flexbox;
  display: flex;
  margin-left: 9px;

  button[data-title]:hover:after {
    content: attr(data-title);
    padding: 4px 8px;
    color: black;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 20;
    white-space: nowrap;
    border: 1px solid black;
  }

`

export default DownloadTntFile
