import React from 'react'
import { RenderCellProps } from 'models/table.model'
import RenderBlockTable from './RenderBlockTable'
import AutoIncome from './AutoIncome'
import PdfIncome from './PdfIncome'

import { useIntl } from 'react-intl'
import StockTable from './StockTable'
import OrdersTable from './OrdersTable'
import Voicemail from './Voicemail'
import DashboardKPIs from './DashboardKPIs'

const RenderCell = ({
  row,
  rowIndex,
  column,
  page,
  isMultipharmaAccount,
  handleUpdateValidation,
  handleUpdatePaymentConfirmation,
  onCellChange,
  filesNames,
  locale,
  selectOrderId,
  iconCircle,
  setPharmacyOrderIdToShow,
  setOrderIdToShow,
  checkTextSelected,
  editShipped,
  renderInputCommentary,
  selectRowId,
  formatCommentary,
  setSelectRowId,
  activeTab,
  handleActions,
  getTimestampPerMonth,
  transferPharmacyFees,
  handlePrint,
  setTimestampStart,
  setTimestampEnd
}: RenderCellProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  if (page === 'autoIncome') {
    return (
      <AutoIncome
        row={row}
        rowIndex={rowIndex}
        column={column}
        isMultipharmaAccount={isMultipharmaAccount}
        handleUpdateValidation={handleUpdateValidation}
        handleUpdatePaymentConfirmation={handleUpdatePaymentConfirmation}
        getTimestampPerMonth={getTimestampPerMonth}
        f={f}
        transferPharmacyFees={transferPharmacyFees}
        handlePrint={handlePrint}
        setTimestampStart={setTimestampStart}
        setTimestampEnd={setTimestampEnd}
      />
    )
  }

  if (page === 'pdfIncome') return <PdfIncome row={row} rowIndex={rowIndex} column={column} f={f} />

  if (page === 'stock') return <StockTable row={row} rowIndex={rowIndex} column={column} onCellChange={onCellChange} />

  if (page === 'order') {
    return (
      <OrdersTable
        row={row}
        rowIndex={rowIndex}
        column={column}
        filesNames={filesNames}
        locale={locale}
        selectOrderId={selectOrderId}
        iconCircle={iconCircle}
        setPharmacyOrderIdToShow={setPharmacyOrderIdToShow}
        setOrderIdToShow={setOrderIdToShow}
        checkTextSelected={checkTextSelected}
        editShipped={editShipped}
      />
    )
  }

  if (page === 'voicemail') {
    return (
      <Voicemail
        row={row}
        rowIndex={rowIndex}
        column={column}
        f={f}
        renderInputCommentary={renderInputCommentary}
        selectRowId={selectRowId}
        formatCommentary={formatCommentary}
        setSelectRowId={setSelectRowId}
        activeTab={activeTab}
        handleActions={handleActions}
      />
    )
  }
  if (page === 'dashboardKPIs') {
    return (
      <DashboardKPIs
        row={row}
        rowIndex={rowIndex}
        column={column}
        f={f}
      />
    )
  }
  // if (page === 'blockTable')
  return <RenderBlockTable row={row} rowIndex={rowIndex} column={column} />
}

export default RenderCell
