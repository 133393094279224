import { stylesLandingPage } from '../../../constants/styles.constants'
import styled from 'styled-components'

export const FirstContent = styled.div`
max-width: 600px;
margin-left: auto;
margin-right: auto;

@media (min-width: 992px){
    text-align: left;

}

@media (max-width: 768px){
    h4{
        font-size: 24px !important;
    }
}

text-align: center;

h4{
    color: #161c2d;
    letter-spacing: 0.5px;
    font-size: 30px;
}
p{
    max-width: 600px;
}
span{
    font-weight: 700;
    color: ${stylesLandingPage.color_phacil}!important;
}
.badge {
    letter-spacing: 0.5px;
    padding: 4px 8px;
    font-weight: 600;
    line-height: 11px;
    color: white !important
}
.btn{
    padding: 8px 20px !important;
    outline: 0 !important;
    text-decoration: none !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    color: white;
}    

.btn:hover{
    color: white !important;
    background-color: #4631e2
}
`

export const SecondContent = styled.div`
text-align: center; 
h6{
    color: #513af2;
    font-weight: 700;
}

.btn{
    padding: 8px 20px !important;
    outline: 0 !important;
    text-decoration: none !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    color: white;
}    

.btn:hover{
    color: white !important;
    background-color: #4631e2
}
div span{
    color: ${stylesLandingPage.color_phacil}!important;
}
li span {
    margin-left: 0.5rem;
}
ul{
    text-align: left 
}
`

export const RateContainer = styled.div`


@media (min-width: 992px){
    margin-left: 3rem!important;
}
`
