import { ToggleSwitchWrapper } from 'assets/ToggleSwitch'
import { ToggleSwitchProps } from 'models/toggle.model'
import React from 'react'
import { FormCheck, FormLabel } from 'react-bootstrap'

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ title, isChecked, toggle }) => {
  return (
    <ToggleSwitchWrapper>
      <FormLabel>{title}</FormLabel>
      <FormCheck
        checked={isChecked}
        type='switch'
        id={`${title}-switch}`}
        onChange={(e) => { toggle() }}
      />
    </ToggleSwitchWrapper>
  )
}

export default ToggleSwitch
