import React, { createRef } from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import { AlertTriangle as AlertIcon } from 'react-feather'
import * as yup from 'yup' // for everything

const InputCellElement = styled(Field)`
  background: transparent;
  font-family: inherit;
  font-weight: inherit;
  border: none;
  margin: none;
  width: 100%;
  max-width: 10rem;
  .error {
    color: red;
  }
`

const numberSchema = yup.object().shape({
  value: yup.number()
})

// const InputCellElement = styled.input`
//   background: transparent;
//   font-family: inherit;
//   font-weight: inherit;
//   border: none;
//   margin: none;
//   padding: 0.5rem;
//   width: 50%;
//   max-width: 10rem;
// `;

const InvisibleBtn = styled.button`
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
`

interface InputCellProps {
  cellId: string
  startValue: string | number
  inputType: string
  onCellChange: (newValue: string | number) => any
}

const InputCell: React.FC<InputCellProps> = ({
  cellId,
  startValue,
  inputType,
  onCellChange
}) => {
  const cellRef = createRef<HTMLInputElement>()
  return (
    <Formik
      initialValues={{
        value: startValue
      }}
      onSubmit={(fields) => {
        console.log('fields: ', fields)
          ; (cellRef.current != null) && cellRef.current.blur()
        onCellChange(fields.value || '')
      }}
      validationSchema={numberSchema}
      enableReinitialize
    >
      {(props) => {
        return (
          <Form>
            <InputCellElement
              name='value'
              id={cellId}
              type={inputType || 'text'}
              defaultValue={startValue}
              ref={cellRef}
            />
            <InvisibleBtn type='submit' />
          </Form>
        )
      }}
    </Formik>
  )
}

export default InputCell
