import React from 'react'
import { Card, CardGroup, CardImg, CardBody, CardTitle, Row, Col } from 'reactstrap'
import 'moment/locale/fr'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

const ModalProduits = (props: any) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  return (
    <>

      <h4>{f('products')}</h4>
      <Row>
        <CardGroup>
          {props.products.map((product: any, i: any) => {
            return (
              <Col xs='4' key={i}>
                <CardWrapper>
                  <CardImg top src={product.img_link} alt={product.name} />
                  <CardBody>
                    <CardTitle>{product.name}</CardTitle>
                    <strong>
                      {f('quantity')}
                      {product.quantity}
                    </strong>
                    <p>
                      {f('price')}
                      {product.priceRaw}
                    </p>
                    {product.EAN_code != null && (
                      <p>
                        {f('eanCode')}
                        {product.EAN_code}
                      </p>
                    )}

                    {product.replace != null && (
                      <p>
                        {f('replace')}:
                        {product.replace === true ? ` ${f('yes').toLowerCase()}` : ` ${f('no').toLowerCase()}`}
                      </p>
                    )}
                    {product.cip13 != null && (
                      <p>
                        Cip13 :
                        {product.cip13}
                      </p>
                    )}
                    {product.cip7 != null && (
                      <p>
                        Cip7 :
                        {product.cip7}
                      </p>
                    )}

                  </CardBody>
                </CardWrapper>
              </Col>
            )
          })}
        </CardGroup>
      </Row>
    </>
  )
}

const CardWrapper = styled(Card)`
  margin: 0 0 0.5rem 0;
  min-height: 22em;
  .card-body {
    padding: 0.5rem;
  }
  img {
    max-height: 100px;
  }
  .card-title {
    color: #6c6f7d;
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    margin: 0;
    color: #71748d;
    font-size: 0.8rem;
    font-weight: 600;
  }
`

export default ModalProduits
