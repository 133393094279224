import React from 'react'

import { Col } from 'reactstrap'
import { CardPartner, ContentPartner } from 'assets/styles/landingPage/Partners'
import { useIntl } from 'react-intl'

const Partners: React.FC<{}> = () => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const partners = [
    {
      title: f('partnerTitleCard1'),
      content: f('partnerContentCard1'),
      img: require('../../assets/images/landingPage/Carlos_molero.png')
    },
    {
      title: f('partnerTitleCard2'),
      content: f('partnerContentCard2'),
      img: require('../../assets/images/landingPage/Godefroy.png')
    },
    {
      title: f('partnerTitleCard3'),
      content: f('partnerContentCard3'),
      img: require('../../assets/images/landingPage/Augustin.png')
    }
  ]

  return (
    <div className='container'>
      <div className='row justify-content-center text-center'>
        <Col md='12'>
          <ContentPartner>
            <h6>{f('partnerTitle')}</h6>
            <h4 className='mb-4'>{f('partnerSubtilte')}</h4>
            <p className='text-muted para-desc mx-auto mb-0'>{f('teamQuestion')}  <span className='text-primary'>Phacil, </span>
              {f('partnerText1')}
            </p>
          </ContentPartner>
        </Col>
      </div>

      <div className='row'>
        {partners.map((partner, index) => {
          return (

            <Col md='4' className='mt-4' key={index}>

              <CardPartner className=' rounded shadow p-4 center'>
                <img src={partner.img.default} className='img-fluid' alt={`${partner.title}_${index}`} style={{ maxHeight: '70px' }} />
                <p className='mt-4'>{partner.content}</p>
                <h6>{partner.title}</h6>
              </CardPartner>
            </Col>
          )
        })}

      </div>
    </div>

  )
}
export default Partners
