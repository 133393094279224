import { InputProps } from 'models'
import React from 'react'
import { Input, Label, FormGroup } from 'reactstrap'

const InputMain: React.FC<InputProps & Record<string, any>> = ({
  className,
  placeholder,
  label,
  error,
  msg,
  autofocus,
  type,
  required,
  value,
  ...input
}) => {
  return (
    <FormGroup className={`${className != null ? className : ''} ${error != null ? 'error' : ''} `}>
      {label != null ? <Label>{label}</Label> : ''}
      <Input
        required
        value={value}
        placeholder={placeholder}
        autoFocus={autofocus}
        type={type}
        {...input}
      />
      {error != null && <span className='msg-error'>{msg}</span>}
    </FormGroup>
  )
}

export default InputMain
