import { Label, Row } from 'reactstrap'
import styled from 'styled-components'

// Styling for the Stock view
export const StockWrapper = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  display: block;
  .bodyWrapper {
    background: var(--gris-bleu);
    width: 100%;
    padding: 1rem;
    @media (min-width: 60rem) {
      min-width: 70%;
      max-width: calc(100% -20rem);
    }

    .bodyHeader {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      ${props => props.property === 'stock' && `{
          button {
            display: flex;
            align-items: center;
            svg {
              height: 1.5rem;
              margin-right: 0.2rem;
            }
          }
        }
      `};
    }
  }

  .bookedButton {
    margin-bottom: ${props => props.property === 'appointment' && '1rem'};
  }

  //Raph :
  .AddButtonWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 992px) {
      margin-bottom: 0.5rem;
    }
  }
  background-color: ${props => props.property === 'stock' ? 'white' : 'var(--gris-bleu)'};

  
  ${props => props.property === 'stock' ? `{
    .container-fluid {
      padding: 20px 20px 60px 20px;
      background-color: white;
    }
  }` : `{
    .container-fluid {
      padding: 30px 20px 60px 20px;
    }
  }`};


  @media (max-width: 769px) {
    flex-direction: column;
  }
  .headerTitle {
    ${props => props.property === 'stock' ? `{
      margin-left: 1rem
    };` : `{
      margin-bottom: 2rem
    }`};
   
    h2 {
      color: var(--texte-bleu-fonce);
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  label {
    display: flex;
    align-items: center;
  }
  label select {
    width: unset;
  }
  .blur{
    filter: blur(8px);
    pointer-events: none
  }
`

export const FiltersAboveTable = styled(Row)`
  color: var(--texte-table);
  font-weight: 700;
  font-size: 0.9rem;

  .ItemsToShow {
    display: flex;
  }
  label select {
    margin: 0 0.5rem;
  } 
  label form {
    margin-left: ${props => props.property !== 'stock' && '0.5rem'}
  }
  input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }
`
export const SearchbarWrapper = styled(Label)`
  display: flex;
  justify-content: center;
  float: ${props => props.property !== 'stock' ? 'right' : 'left'};
  input {
    margin-right: 0;
  }
`
