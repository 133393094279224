import { FormGroup, Popover } from 'reactstrap'
import styled from 'styled-components'

export const PopoverWrapper = styled(Popover)`
  background-color: #fff;
  font-weight: 500;
  color: #404452;
  .popover{
    border: none;
    box-shadow: 
    0 0 0 1px rgb(136 152 170 / 10%),
    0 15px 35px 0 rgb(49 49 93 / 10%),
    0 5px 15px 0 rgb(0 0 0 / 8%);
    max-width: unset;
  };
`

export const PopoverWrapperActionsStocks = styled(Popover)`
  background-color: #fff;
  font-weight: 500;
  color: #404452;
  border: none;
  box-shadow: 
  0 0 0 1px rgb(136 152 170 / 10%),
  0 15px 35px 0 rgb(49 49 93 / 10%),
  0 5px 15px 0 rgb(0 0 0 / 8%);
  max-width: unset;
  
  .dropdown-item {
    color: var(--texte-table);
    font-size: 13px;
    font-weight: 600;
  }
`

export const FilterHeader = styled.div`
display: flex;
background-color: #f6f8fa;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
border-radius: 0.25rem 0.25rem 0rem 0rem !important;
`

export const GroupFilter = styled(FormGroup)`
padding-top: 0.5rem;
padding-bottom: 0.5rem;
font-size: 14px;
border-top: solid 1px #eae8e8; 
label {
cursor: grab;
}
.titleCheckbox:hover{
    color: black
}
`
