import styled from 'styled-components'

export const StyledContainer = styled.div`
  .UserMangagementWrapper {
    width: 100%;
    margin: 0;
    display: block;
    .containerWrapper {
      margin-left: 230px;
      .container-fluid {
        padding: 30px 20px 60px 20px;
        .headerTitle {
          margin-bottom: 2rem;
          .divider {
            border-top: 1px solid var(--border-separation);
          }
        }
        .bodyHeader {
          input {
            min-height: 40px;
          }
          .searchBar {
            border: none;
          }
          .dropdown {
            button {
              min-height: 40px;
              border: none;
              background-color: #fff;
              color: #007bff;
              font-weight: 600;
              transition: all 0.3s;
              min-width: 130px;
              &:hover {
                background: linear-gradient(to right, #00b2ff, #0066ff);
                color: #fff;
              }
              &:focus {
                background: linear-gradient(to right, #00b2ff, #0066ff);
                background-color: unset !important;
                color: #fff;
                border-color: unset !important ;
                box-shadow: unset !important;
              }
            }
          }
          .AddUser {
            text-align: end;
            button {
              min-width: 150px;
              min-height: 40px;
            }
          }
        }
      }
    }
  }
  .formWrapper {
    background-color: #fff;
    .form-row {
      max-width: 80%;
      padding: 2rem;
      .form-group {
        margin-bottom: 2rem;
        input {
          background-color: transparent;
          width: 100%;
        }
      }
    }
    .form-footer {
      padding-top: 20px;
      button {
        width: 152px;
        height: 40px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  //User Detail
  .tab-wrapper {
    background-color: #fff;
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px 4px 0px 0px;
    padding-bottom: 20px;
  }
  .nav-tabs {
    .nav-item {
      width: 50%;
      min-height: 20px;
      .nav-link {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        :hover {
          border-color: unset;
        }
        &.active {
          background-color: #e8663d;
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    max-width: 80%;
    padding-top: 2rem;
    margin: auto;
    .input-main .form-control {
      width: 100%;
    }
    .input-main {
      textarea {
        height: 100px;
      }
    }
    .form-footer {
      display: flex;
      justify-content: center;
      button {
        width: 152px;
        height: 40px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  .info {
    &-bottom {
      padding-top: 20px;
    }
  }
  .block-info {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    height: 100%;
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      gap: 15px;
    }
  }

  // Custom css
  .user-list {
    .search {
      padding-bottom: 20px;
      input {
        border: 1px solid #ccc;
      }
      button {
        border: 1px solid #ccc !important;
      }
      .action {
        display: flex;
        list-style: none;
      }
      .select {
        height: 40px;
      }
    }
  }
  .main-table {
    .action {
      display: flex;
      list-style: none;
      li {
        padding: 10px;
      }
    }
  }
  .action {
    img {
      cursor: pointer;
    }
  }
  table {
    background-color: #fff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    thead,
    th,
    td {
      height: 40px;
    }
  }

  .table td {
    padding-right: 0.1rem !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    padding-left: 1.8rem !important;
  }

  .table th {
    padding-right: 0.1rem !important;
    padding-left: 1.8rem !important;
  }
  .table th:first-child {
    padding-left: unset !important;
  }

  .table td:first-child {
    padding-left: 0.5rem !important;
  }



  .dropdown-menu {
    top: auto !important;
    transform: unset !important;
    max-height: 50vh;
    overflow: auto;
  }
  .react-confirm-alert {
    background: #fff;
    border: 1px solid #ccc;
    padding: 35px;
    border-radius: 5px;
    h1 {
      font-size: 22px;
    }
    button {
      margin: 5px;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 3px 16px;
      &:hover {
        background: #ccc;
      }
    }
  }

  .back-button {
    margin-right: 1rem;
    cursor: pointer;
    color: #e8663d;
  }

  .delete-button {
    background: none;
    border: none;
    padding: 0;

    &:active,
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }

`
