import React, { useState } from 'react'
import { Button, Input } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { PaginationProps } from 'models/table.model'

const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
    button {
      margin-right: 0.5rem;
    }
    span, div {
      display: flex;
      align-items: center;
      margin: 0.5rem;
    }
    select {
      max-width: 6.7rem;
    }
`

const Pagination: React.FC<PaginationProps> = ({ onGoToPage, onNavigate, offset, pageCount }) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const [page, setPage] = useState<number>()
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && page != null) {
      onGoToPage(page)
    }
  }
  return (
    <PaginationWrapper className='pagination'>
      <Button onClick={() => onNavigate(-1)} disabled={offset <= 0}>
        {f('previousPagination')}
      </Button>{' '}
      <Button onClick={() => onNavigate(+1)} disabled={offset >= pageCount - 1}>
        {f('nextPagination')}
      </Button>
      <span>
        <span>{f('page')}</span>
        <strong>
          {offset + 1}{f('of')}{pageCount}
        </strong>{' '}
      </span>
      <span>
        <span>{f('goToPage')}</span>
        <Input
          type='number'
          defaultValue={offset + 1}
          onChange={e => {
            const value = e.target.value ? parseInt(e.target.value) - 1 : 0
            if (value < 0 || value > pageCount - 1) return
            setPage(value)
          }}
          onKeyDown={handleKeyDown}
          style={{ width: '100px' }}
        />
      </span>{' '}
    </PaginationWrapper>
  )
}

export default Pagination
