import styled from 'styled-components'

export const StyledContainer = styled.div`
    .table-main {
        margin-bottom: 0px;
        .th-header {
        border-top: none;
        border-bottom: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.05px;
        color: #3a3b3f;
        }
        .td-body {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #3a3b3f;
        }
        .container-checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            &:after {
            content: "";
            position: absolute;
            display: none;
            left: 8px;
            top: 3px;
            width: 9px;
            height: 15px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            }
        }
        &:hover input ~ .checkmark {
            background-color: #ccc;
        }
        input:checked ~ .checkmark {
            background-color: #2196f3;
        }
        input:checked ~ .checkmark:after {
            display: block;
        }
        }
    }  
`
