import React from 'react'
import styled from 'styled-components'
import { Modal } from 'reactstrap'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'

const ModalWrapper = styled(Modal)`
  padding: 1rem;
  .modal-content {
    padding: 1rem;
  }

  h4 {
    color: var(--texte-bleu-fonce);
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  label {
    color: var(--texte-table);
    font-weight: 700;
    font-size: 0.9rem;
  }
  input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }
`

interface AddModalProps {
  isOpen: boolean
  toggleModal: () => any
  onAddExample: (fields: {
    EAN_code: string
    quantity: string
    price: string
    reduction: string
  }) => any
}

const AddModal: React.FC<AddModalProps> = ({
  isOpen,
  toggleModal,
  onAddExample
}) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  return (
    <ModalWrapper isOpen={isOpen} toggle={toggleModal} className='AddModal'>
      <h4>{f('addProduct')}</h4>
      <Formik
        initialValues={{
          EAN_code: '',
          quantity: '',
          price: '',
          reduction: ''
        }}
        validationSchema={Yup.object().shape({
          EAN_code: Yup.string().required(f('pleaseEan')),
          quantity: Yup.number().required(f('pleaseQuantity')),
          price: Yup.number().required(f('pleasePrice2')),
          reduction: Yup.number().required(f('pleaseReduc'))
        })}
        onSubmit={(fields) => onAddExample(fields)}
      >
        {({ errors, status, touched }) => (
          <Form>
            <div className='form-group'>
              <label htmlFor='EAN_code'>{f('eanCode2')}</label>
              <Field
                name='EAN_code'
                type='text'
                className={
                  'form-control' +
                  (errors.EAN_code && touched.EAN_code ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name='EAN_code'
                component='div'
                className='invalid-feedback'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='quantity'>{f('quantity2')}</label>
              <Field
                name='quantity'
                type='number'
                className={
                  'form-control' +
                  (errors.quantity && touched.quantity ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name='quantity'
                component='div'
                className='invalid-feedback'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='price'>{f('price2')}</label>
              <Field
                name='price'
                type='number'
                className={
                  'form-control' +
                  (errors.price && touched.price ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name='price'
                component='div'
                className='invalid-feedback'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='reduction'>{f('reduc')}</label>
              <Field
                name='reduction'
                type='number'
                className={
                  'form-control' +
                  (errors.reduction && touched.reduction ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name='reduction'
                component='div'
                className='invalid-feedback'
              />
            </div>
            <div className='form-group'>
              <button type='submit' className='btn btnPrimary mr-2'>
                {f('add')}
              </button>
              <button onClick={toggleModal} className='btn btnSecondary'>
                {f('cancel')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}

export default AddModal
