import React, { useState, useEffect } from 'react'
import { ToggleRight } from 'react-feather'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

const ValidationModal = (props: any) => {
  const [modal, setModal] = useState(false)
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    setModal(props.open)
  }, [props.open])

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop={false}>
        <ModalHeader>{f('changedData')}</ModalHeader>
        <ModalBody>{f('wannaSaveChange')}</ModalBody>
        <ModalFooter>
          <StyledButton
            color='primary'
            onClick={() => {
              toggle()
              props.confirm()
            }}
          >
            {f('yes')}
          </StyledButton>
          <Button
            color='secondary'
            onClick={() => {
              toggle()
              props.cancel()
            }}
          >
            {f('no')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const StyledButton = styled(Button)`
  border: solid 1px #ffb064;
  box-shadow: none;
  border-radius: 3px;
  background-image: linear-gradient(to bottom right, #ff625b, #ffb064);
  color: white;
  transform: perspective(1px) translateZ(0);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

export default ValidationModal
