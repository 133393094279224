import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import userReducer from './user'
import productReducer from './product'

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  user: userReducer,
  product: productReducer
})

export default createRootReducer
