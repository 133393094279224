import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetPassword } from '../actions'

import ForgotPasswordForm from '../components/LandingPage/ForgotPassword/ForgotPasswordForm'
import { useIntl } from 'react-intl'
import ForgotPasswordFormMobile from 'components/LandingPage/ForgotPassword/ForgotPasswordFormMobile'
import { Helmet } from 'react-helmet'
import { AppWrapper } from 'assets/styles/AppWrapper'

const Password = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const [isMobile, setIsMobile] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
    setIsMobile(isMobile)
  })

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <AppWrapper property='password'>
      <Helmet>
        <title>{f('passwordMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('passwordMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>

      {isMobile ? <ForgotPasswordFormMobile
        onSubmit={credentials => dispatch(resetPassword(credentials, f))}
      /> : <ForgotPasswordForm
        onSubmit={credentials => dispatch(resetPassword(credentials, f))}
      />}

    </AppWrapper>
  )
}

export default Password
