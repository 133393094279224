import axios from 'axios'

import webKeys from '../config/keys'

const keys = (webKeys) as {
  baseUrl: string
  apiKey: string
}

// const cookies = new Cookies();
const API = axios.create({
  baseURL: keys.baseUrl + '/api',
  responseType: 'json'
  // withCredentials: true,
})

API.defaults.headers.common.token = keys.apiKey

export default API
