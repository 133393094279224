
import { stylesLandingPage } from '../../../constants/styles.constants'
import styled from 'styled-components'

export const ContentWork = styled.div`
max-width: 600px;
margin: auto;

@media (max-width: 769px){
    h4{
        font-size: 24px !important;
    }

}

h4{
    color: #161c2d;
    letter-spacing: 0.5px;
    font-size: 30px;
    margin-bottom: 1.5rem 
}

span{
    font-weight: 700;
    color: ${stylesLandingPage.color_phacil}!important;
}

li span{
    margin-left: 0.5rem;
}

.marginLeftContent{
    margin-left: 3rem!important;
}
.btn{
    padding: 8px 20px !important;
    outline: 0 !important;
    text-decoration: none !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    color: white;
    margin-right: 1rem;
}    

.btn-outline-primary{
  color: ${stylesLandingPage.color_phacil} !important;
  border-color: ${stylesLandingPage.color_phacil} !important;

}


.btn:hover{
    color: white !important;
    background-color: #4631e2
}

`

export const SecondContentWork = styled.div`

max-width: 600px;
margin-right: auto;
margin-bottom: 3rem;

@media (min-width: 992px){
    margin-left: 3rem!important;
}

@media (max-width: 768px){
    h4{
        font-size: 24px !important;
    }
}

h4{
    color: #161c2d;
    letter-spacing: 0.5px;
    font-size: 30px;
    margin-bottom: 1.5rem;

}

span{
    font-weight: 700;
    margin-left: 0.5rem;
}




.btn{
    padding: 8px 20px !important;
    outline: 0 !important;
    text-decoration: none !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    color: white;
    margin-right: 1rem;
}    

.btn:hover{
    color: white !important;
    background-color: #4631e2
}

`
