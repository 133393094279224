import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Tooltip
} from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import API from '../utils/API'

import VoicemailTable from '../components/VoicemailTable'
import Filters from '../components/VoicemailTable/Filters'
import SideMenu from '../components/SideMenu'
import SelectPharma from '../components/SelectPharma'

import { Column } from 'models/table.model'
import { RootState } from 'models/redux.model'

import { useIntl } from 'react-intl'
import { useIdleTimer } from 'react-idle-timer'
import socket from 'utils/socket'
import { Helmet } from 'react-helmet'

import { LoaderWrapper } from 'assets/styles/LoaderWrapper'
import { OrdersWrapper } from 'assets/styles/OrdersWrapper'
import { history } from 'store'
import { CardWrapper } from 'assets/styles/CardWrapper'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'

const loadingIcon = 'images/loading-gif-phacil.svg'

const VoiceMessages: React.FunctionComponent<any> = () => {
  // const handleOnIdle = (event: any) => {
  //   console.log('user is idle', event)
  //   console.log('last active', getLastActiveTime())
  // }

  // const handleOnActive = (event: any) => {
  const handleOnActive = (): void => {
    getData(offset, pageSize).catch(err => console.log('err: ', err))
  }

  // const handleOnAction = (event: any) => {
  //   console.log('user did something', event)
  // }

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  useIdleTimer({
    timeout: 1000 * 60 * 3,
    // onIdle: handleOnIdle,
    onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500
  })

  // Pharmacy list from Redux
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)
  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const [filteredRows, setFilteredRows] = useState<any[]>([])
  const [referenceRows, setReferenceRows] = useState<any[]>([])

  const [sortCriteria, setSortCriteria] = useState<{
    direction: 'DESC' | 'ASC'
    accessor: string
  } | null>(null)

  const [offset, setOffset] = useState(0)
  const pageSize = 10

  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState<number>(0)

  const motifsByLanguage = [f('emergency'), f('allVoiceMail'), f('noListened'), f('listened'), f('notCommunicated'), f('commercial'), f('deleted')]
  const motifs = ['Urgence', 'Tous', 'Non Ecouté', 'Ecouté', 'Non communiqué', 'Commercial', 'Deleted']

  const [tooltipOpen, setTooltipOpen] = useState(false)

  // Configuration for the Orders table
  const columns: Column[] = [
    {
      Header: f('Phone Number'),
      accessor: 'phoneCalling'
    },
    {
      Header: f('Date'),
      accessor: 'createdAt'
    },
    {
      Header: f('Motif'),
      accessor: 'motif'
    },
    {
      Header: f('listenToMessage'),
      accessor: 'voicemail'
    },
    {
      Header: f('listened'),
      accessor: 'listened'
    },
    {
      Header: f('commentaryCall'),
      accessor: 'commentary'
    },
    {
      Header: f('action'),
      accessor: 'actions'
    }
  ]

  useEffect(() => {
    socket.emit('join_room', `standard_${selectedPharma.pharmacy_id}`)
  }, [selectedPharma])

  useEffect(() => {
    socket.on('reload_standard', () => {
      setTooltipOpen(true)
      getData(offset, pageSize, false).catch(err => console.log('err: ', err))
    })
  }, [])

  /** CRUD FUNCTIONS */
  const getData = async (page: number, perPage: number, reload: boolean = true): Promise<void> => {
    try {
      setIsLoading(reload)

      if (selectedPharma == null || selectedPharma.pharmacy_id === -1) return

      const callsResponse = await API.get(
        `/aircall/calls/pharmacy/v2/${selectedPharma.pharmacy_id}?page=${page}&per_page=${perPage}&activeTab=${motifs[activeTab]}`
      )

      const calls = callsResponse.data.callsWithMeta.calls
      setReferenceRows(calls)

      setFilteredRows(calls)
      setCount(callsResponse.data.callsWithMeta.meta.total)

      reload && setIsLoading(false)
    } catch (e: any) {
      console.log(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData(offset, pageSize).catch(err => console.log('err: ', err))
  }, [offset, pageSize, selectedPharma, activeTab])

  return (
    <OrdersWrapper property='voicemail' className='dataContainer'>

      <Helmet>
        <title>{f('voicemailMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('voicemailMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <SideMenu parent='Orders' />
      <ContainerWrapper>
        <Container fluid>
          <Row style={{ justifyContent: 'space-between' }}>

            <SelectPharma pharmacies={pharmacies} />
            {/* <UpdateHomeButton className='btn btn-primary' onClick={() => { history.push('/voicemail/welcome') }}>
              {f('updateWelcomeMessage')}
              <FontAwesomeIcon icon={faPhone} style={{ marginLeft: '1rem' }} />
            </UpdateHomeButton> */}
          </Row>
          <Row className='headerTitle'>
            <h2>{f('voicemail')}</h2>
            <div id='TooltipExample' onClick={() => setTooltipOpen(false)}>
              <StyledFontAwesomeIcon
                icon={faSyncAlt}
                onClick={() => {
                  getData(offset, pageSize).catch(err => console.log('err: ', err))
                  setTooltipOpen(false)
                }}
              />
              <Tooltip
                isOpen={tooltipOpen}
                placement='right'
                target='TooltipExample'
              >
                {f('newVocal')}
              </Tooltip>
            </div>
          </Row>
          <Row>
            <Col xs='12'>
              <CardWrapper voicemail>
                <CardBody>
                  <FiltersAboveTable>
                    <Filters
                      motifsByLanguage={motifsByLanguage}
                      motifs={motifs}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      referenceRows={referenceRows}
                      setFilteredRows={setFilteredRows}
                      setOffset={setOffset}
                    />
                  </FiltersAboveTable>
                  {isLoading ? (
                    <LoaderWrapper title='voicemail'>
                      <h5>{f('loadingVoicemail')}</h5>
                      <img src={loadingIcon} alt='loading' width='35' />
                    </LoaderWrapper>
                  )
                    : (
                      <VoicemailTable
                        sortCriteria={sortCriteria}
                        setSortCriteria={setSortCriteria}
                        columns={columns}
                        data={filteredRows}
                        offset={offset}
                        setOffset={setOffset}
                        pageCount={Math.ceil(count / pageSize)}
                        getData={getData}
                        pageSize={pageSize}
                        activeTab={activeTab}
                      />
                    )}
                </CardBody>
              </CardWrapper>
            </Col>
          </Row>
        </Container>
      </ContainerWrapper>
    </OrdersWrapper>
  )
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-top: 8px;
  margin-left: 10px;
  cursor: pointer;
`

const FiltersAboveTable = styled(Row)`
  color: var(--texte-table);
  font-weight: 700;
  font-size: 0.9rem;

  label select {
    margin: 0 0.5rem;
  }
  label form {
    margin-left: 0.5rem;
  }
`

export default VoiceMessages
