import React, { useEffect } from 'react'
import { Button, Input, InputGroup, Container, Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

const SendCommentary = (props: any) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  useEffect(() => {
    const objScrollable = document.getElementById('scrollComm')
    if (objScrollable != null) {
      objScrollable.scrollTop = objScrollable.scrollHeight
    }
  })

  return (
    <div>
      {/* <div>
        <h3>Votre Conversation avec Phacil | Commande n°{props.orderId}</h3>
      </div> */}
      <ScrollableContainer id='scrollComm'>
        {props.messageList.map((value: any, index: any, map: any) => {
          return (
            <StyledRow>
              <Col xs='3'>{value.author}</Col>
              {Array.isArray(value.message) ? (
                value.message.map((value: any) => {
                  return <Col xs='6'>{value}</Col>
                })
              ) : (
                <Col xs='6'>{value.message}</Col>
              )}
              <Col xs='3'>{value.time}</Col>
              <hr />
            </StyledRow>
          )
        })}
      </ScrollableContainer>
      <InputGroup>
        <Input
          type='text'
          placeholder={f('missingProduct')}
          value={props.message}
          onChange={(e) => {
            props.setMessage(e.target.value)
          }}
        />
        <StyledButton className='btn' onClick={props.sendMessage}>
          {f('send')}
        </StyledButton>
      </InputGroup>
    </div>
  )
}

const ScrollableContainer = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 140pt;
`

const StyledRow = styled(Row)`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #d9d9d9;
  border: 1px solid #c9c1d5;
  color: #5f5f5f;
  margin: 5px;
`

const StyledButton = styled.button`
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
`

export default SendCommentary
