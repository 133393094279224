import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import SideMenu from '../../components/SideMenu'
import { useIntl } from 'react-intl'
import AddUserForm from '../../components/AddUserForm'
import API from 'utils/API'
import { useSelector } from 'react-redux'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { postData } from "../../utils/APIs";
import { alertSuccess, alertError } from '../../utils/notifications'
import { history } from '../../store'
import { RootState } from 'models/redux.model'
import { StyledContainer } from './UserManagementStyledComponents'
import { Helmet } from 'react-helmet'

const CreateUser = () => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma)

  const addUserHandle = async (itemData: {
    firstname: string
    lastname: string
    email: string
    phone: string
    birthday: string
    notes: string
    code: string
    address: string
  }) => {
    const body = {
      ...itemData,
      pharmacy_id: selectedPharma.pharmacy_id.toString()
    }

    try {
      await API.post('/mobile/admin/user/create-patient', body).catch((err) => {
        if (err.response.data.message) throw new Error(f(err.response.data.message))
        else throw new Error(f(err.message))
      })

      alertSuccess(f('User Added'))
      history.push('/manage')
    } catch (e: any) {
      console.log(e)
      alertError(e.toString())
    }
  }

  return (
    <StyledContainer>
      <Helmet>
        <title>{f('manageUserMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('manageUsertMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <div className='UserMangagementWrapper dataContainer'>
        <div className='bodyWrapper'>
          <SideMenu parent='User' />
          <div className='containerWrapper'>
            <Container fluid>
              <Row className='headerTitle'>
                <Col>
                  <h2>{f('User Management')}</h2>
                  <p>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      size='lg'
                      className='back-button'
                      onClick={() => {
                        history.push('/manage')
                      }}
                    />
                    {f('Create User ')}
                  </p>
                </Col>
              </Row>
              <Row>
                <AddUserForm onCreateUser={addUserHandle} />
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </StyledContainer>

  )
}

export default CreateUser
