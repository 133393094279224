
const DashboardKPIs = ({
  row,
  rowIndex,
  column,
  f
}: any): JSX.Element => {
  return (
    <td key={`td${rowIndex}_${column.accessor}`}>
      {row[column.accessor]}
    </td>
  )
}

export default DashboardKPIs
