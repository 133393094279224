import React from 'react'
import styled from 'styled-components'

import Navbar from './Navbar'

const LayoutWrapper = styled.div`
    height: 100vh;
    margin: 0;
    .content {
        margin: 0;
        width: 100%;
        background-color: white;
    }

    .electronOff{
        margin-top:68px;
    }
`

interface LayoutProps {
  isAuthenticated: boolean | null
}

const Layout: React.FC<LayoutProps> = ({ isAuthenticated, children }) => {
  return (
    <>
      <LayoutWrapper>
        {!isAuthenticated ? null : <Navbar />}
        <div className={isAuthenticated ? 'content electronOff content-custom' : 'content'}>
          {children}
        </div>
      </LayoutWrapper>
    </>
  )
}

export default Layout
