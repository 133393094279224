
import styled from 'styled-components'

export const ToggleSwitchWrapper = styled.div`
display: flex;
max-width: 15rem;
padding: 1rem;

label{
    margin: auto;
}

`
