import React, { ReactNode } from 'react'
import { Button } from 'reactstrap'

interface ButtonProps {
  className?: string
  color?: string
  size?: string
  children?: ReactNode
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
  floatStyle?: any
}
const Buttons: React.FC<ButtonProps> = ({
  className,
  color,
  size,
  disabled,
  children = React.createElement('p'),
  onClick,
  floatStyle,
  ...props
}) => {
  return (
    <div className={className}>
      <Button
        color={color}
        disabled={disabled}
        size={size}
        onClick={onClick}
        {...props}
        style={{ float: floatStyle || 'none' }}
      >
        {children}
      </Button>
    </div>
  )
}

export default Buttons
