import React from 'react'
import { useIntl } from 'react-intl'
import { ImageWrapper, NoResultFoundWrapper, TitleWrapper } from 'assets/styles/filter/NoResultFound'
import MagnifyingGlass from '../../assets/images/magnifying-glass-solid.svg'

const NoResultFound: React.FC<any> = () => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  return (
    <NoResultFoundWrapper>
      <div>

        <ImageWrapper>
          <img src={MagnifyingGlass} width='30' alt='MagnifyingGlass' />

        </ImageWrapper>
        <TitleWrapper>
          <span className='title'>
            {f('noResultFound')}
          </span>
        </TitleWrapper>
        <div>
          <span className='subtitle'>
            {f('subtitleNoResult')}
          </span>
        </div>
      </div>

    </NoResultFoundWrapper>

  )
}

export default NoResultFound
