import React from 'react'
import styled from 'styled-components'

const WelcomeTypeForm = (): JSX.Element => {
  return (
    <>
      <StyledFrame
        id='inlineFrameExample'
        title='Déclaration Revenus'
        src='https://ordophacil.typeform.com/to/FK1M4Ugd'
      />
    </>
  )
}

const StyledFrame = styled.iframe`
  height: 66vh;
  width: 100%;
  border: none;
`

export default WelcomeTypeForm
