
import styled from 'styled-components'

export const AppWrapper = styled.div`
  margin-left: ${props => props.property === 'landingPage' && 'auto'};
  margin-right: ${props => props.property !== 'landingPage' && 'auto'};

  height: 100vh;
  display: ${props => props.property === 'landingPage' ? 'block' : 'flex'};
  justify-content: center;
  align-items: center;
  color: #71748d;

  ${props => props.property === 'landingPage' && `{ 
      @media (max-width: 767px) {
          padding-bottom: 150px;
        }
  }`};

  .splash-container {
    width: 100%;
    max-width: 420px;
    margin: auto;
  }

  .splash-container .card-header {
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #e6e6f2;
  }

  .splash-description {
    text-align: center;
    display: block;
    line-height: 20px;
    margin-top: 5px;
    padding-bottom: 10px;
  }

  .card {
    margin-bottom: ${props => props.property === 'password' && '30px'};
    border: none;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  }

  ${props => props.property === 'password' && `{
    @media (max-width: 767px) {
        .splash-container{
          margin-top: 1rem;
        }
        height: unset;
        .card-footer {
        margin-top: 1rem;
        }   
      }
  }`};

  .card-footer {
    border-top: 1px solid #e6e6f2;
    background: #f6f6ff;
    margin-right: ${props => props.property === 'landingPage' && 'unset !important'};  
    margin-left: ${props => props.property === 'landingPage' && 'unset !important'};  
  }

  .splash-container .card-footer-item {
    padding: 12px 28px;
  }
  .card-footer-item {
    padding: 12px 35px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    display: inline-block !important;
  }

  .card-footer-item-bordered:not(:last-child) {
    border-right: 1px solid rgb(230, 230, 242);
  }

  .splash-container .card-footer-item {
    padding: 12px 28px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #513af2;
    border-color: #513af2;
  }

  ${props => props.property === 'landingPage' && `{ 
    a {
      color: #71748d;
    }
    a:hover {
      color: #ff407b;
      text-decoration: none;
    }
    a:active,
    a:hover {
      outline: 0;
      text-decoration: none;
    }
  }`};

  .btn-primary {
    background-color: #513af2;
    border-color: #513af2;
  }
`
