import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import API from 'utils/API'
import { useIntl } from 'react-intl'

import { RootState } from 'models/redux.model'
import { DataCreateOrderState, CouponState } from 'models/product.model'

import Button from 'components/common/Button'
import CouponsList from 'components/OrderManagement/Coupons/CouponsList'
import { StyledContainer } from './OrderManagementStyledComponents'

interface OrderManagementStepThreeProps {
  confirmCreateOrder: () => any
  previewStepTwo: () => any
  setCheckCoupon: (id: number) => any
  valueCoupon: string
  dataCreateOrder: DataCreateOrderState
  checkCoupon: number
  isDisabled: boolean
}

const OrderManagementStepThree = ({
  confirmCreateOrder,
  previewStepTwo,
  setCheckCoupon,
  valueCoupon,
  dataCreateOrder,
  checkCoupon,
  isDisabled
}: OrderManagementStepThreeProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const f = (id: string): string => formatMessage({ id })

  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )

  const total = useSelector((state: RootState) => state.product.total)

  const [listCoupon, setListCoupon] = useState<CouponState[]>([])
  const [points, setPoints] = useState(0)

  const [newTotalPrice, setNewTotalPrice] = useState(0)
  const [newTotalPoints, setNewTotalPoints] = useState(0)

  useEffect(() => {
    if (checkCoupon !== 0) {
      const find = listCoupon.find(elt => elt.id === checkCoupon)
      if (find != null) {
        if (find.price != null && find.pourcent != null) {
          const reduc = (total - find.price) / find.pourcent
          setNewTotalPrice(total - find.price - reduc)
        } else if (find.pourcent != null) setNewTotalPrice(total - (total / find.pourcent))
        else if (find.price != null) setNewTotalPrice(total - find.price)

        setNewTotalPoints(points - find.point)
      } else {
        setNewTotalPrice(total)
        setNewTotalPoints(points)
      }
    } else {
      setNewTotalPrice(total)
      setNewTotalPoints(points)
    }
  }, [checkCoupon, total, listCoupon, points])

  useEffect(() => {
    const getListCoupon = async (): Promise<void> => {
      try {
        const cardId = valueCoupon
        const userId = dataCreateOrder?.user_id
        const pharmacyId = selectedPharma.pharmacy_id

        const codeRes = await API.post('/mobile/admin/user/get-coupon', {
          cardId,
          userId,
          pharmacyId
        })

        const pointsRes = await API.get(
          `/mobile/admin/user/get-points/${userId}/${cardId}`
        )

        if (codeRes?.data?.success === true) {
          setListCoupon(codeRes?.data?.data)
        }

        if (pointsRes?.data?.success === true) {
          if (pointsRes?.data?.card?.point != null) setPoints(pointsRes.data.card.point)
          else setPoints(0)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getListCoupon().catch(err => console.log(err))
  }, [dataCreateOrder, valueCoupon])

  return (
    <StyledContainer>
      <div className='block-btn-main block-btn-main--justify'>
        <Button
          className='btn-preview'
          children={f('back')}
          onClick={previewStepTwo}
        />
        <Button
          className='btn-next'
          children={f('finish')}
          disabled={isDisabled}
          onClick={confirmCreateOrder}
        />
      </div>
      <div className='block-total-price-list'>
        {listCoupon.length > 0
          ? (
            <CouponsList
              total={total}
              points={points}
              checkCoupon={checkCoupon}
              newTotalPrice={newTotalPrice}
              newTotalPoints={newTotalPoints}
              listCoupon={listCoupon}
              setCheckCoupon={setCheckCoupon}
            />
          )
          : (
            <div className='block-total-price'>
              <p className='block-total-price__total-price'>
                {f('Total Price')}: {total.toFixed(2)} €
              </p>
            </div>
          )}
      </div>
    </StyledContainer>
  )
}

export default OrderManagementStepThree
