import styled from 'styled-components'

export const FooterContainer = styled.div`
@media (max-width: 768px){
    li{
     margin-left: 1rem;
    }
}

.footer-head{
    letter-spacing: 1px;
    font-weight: 500;
    color: #fff;
}
p{
    color: #adb5bd;
    font-weight: 450;
    line-height: 1.6;
    font-size: 16px
}
label{
    font-weight: 700;
    font-size: 14px;
    color: #adb5bd
}
.social-icon a{
    color: #adb5bd;
    border: 1px solid #adb5bd;
    width: 27px;
    height: 27px;
    padding: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    position: relative;
}
.social-icon a:hover{
    background-color: #2f55d4
}
a{
    color: white
}
a:hover{
    color: white !important;
}
`
export const CompanyContainer = styled.div`
.footer-head{
    letter-spacing: 1px;
    font-weight: 500;
    color: #fff;
}
li {
    margin-bottom: 10px;
}
ul{
    list-style: unset;
}
a{
    color: #adb5bd !important;
    font-weight: 450;
    font-size: 16px

}
a:hover{
    color: white !important;
}
`

export const NewsLetterContainer = styled.div`

input{
    background-color: #27314f;
    border: 1px solid #27314f;
    color: #dee2e6!important;
    width: 75%;
}

.btn{
    padding: 8px 20px;
    outline: 0;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: .5px;
    -webkit-transition: all .3s;
    transition: all .3s;
    font-weight: 600;
    border-radius: 6px;
    width: 75%;

}
.btn-soft-primary {
    background-color: rgba(47,85,212,.05)!important;
    border: 1px solid rgba(47,85,212,.05)!important;
    color: #2f55d4!important;
    box-shadow: 0 3px 5px 0 rgb(47 85 212 / 10%);
}
.btn:hover{
    background-color: #2f55d4!important;
}
.btn-soft-primary:hover {
    color: white !important;
}

.d-grid{
display: grid !important;
margin-bottom: 1rem;

}

`

export const CopyRight = styled.div`

padding: 30px 0; 

p{
    color: #adb5bd;
    font-weight: 450;
    line-height: 1.6;
    font-size: 16px
}
`
