import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Container, Row, Col, Button } from 'reactstrap'
import { CSVReader } from 'react-papaparse'

import SideMenu from '../components/SideMenu'
import Breadcrum from '../components/ui/Breadcrum'
import ImgWidthHeight100Percent from '../components/ui/ImgWidthHeight100Percent'

import { RootState } from 'models/redux.model'

import { alertError, alertSuccess } from '../utils/notifications'

import API from 'utils/API'
import { useIntl } from 'react-intl'
import InfosViewDisabledModal from 'components/InfosViewDisabledModal'

import Helmet from 'react-helmet'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'

const blurCsv = 'images/blurCsv.png'

/**
 * Import CSV container
 */
const ImportCSV = () => {
  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma) // the current pharmacy selected
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const [data, setData] = useState<Object[]>([]) // the data imported from the csv file
  const [openModalDisabled, setOpenModalDisabled] = useState(true)
  const toggle = () => setOpenModalDisabled(!openModalDisabled)
  const isMultipharmaAccount = useSelector((state: RootState) => state.user.isMultipharmaAccount)

  /**
   * Handles the actions when a file is added to the dropzone
   * @param {any} data the data read from the csv file currently in the drop zone
   */
  const handleOnDrop = (data: any) => {
    console.log('---------------------------')
    console.log(data)
    setData(data)
    console.log('---------------------------')
  }

  /**
   * Handles file upload errors
   * @param err the eroor
   * @param file the file imported
   * @param inputElem the input
   * @param reason the reason
   */
  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    console.log(err)
    alertError(f('importError'))
  }

  /**
   * Handles when a file is removed from the dropzone
   * @param data the data imported from the file
   */
  const handleOnRemoveFile = (data: any) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }

  /**
   * Handles the API call to import the stocks from the csv file
   */
  const handleOnSubmit = async () => {
    if (selectedPharma != null) {
      alertSuccess(f('importStarted'))

      API.post(`/pharmacist/importing-stocks/${selectedPharma.pharmacy_id}`, {
        data: JSON.stringify(data)
      })
    } else {
      alertError(f('noPharma'))
    }
  }

  return (
    <>
      <ImportWrapper className='dataContainer'>
        <Helmet>
          <title>{f('importCSVMetaTitle')}</title>
          <link rel='canonical' href='https://www.phacil.app/' />
          <meta name='description' content={f('importCSVMetaDescription')} />
          <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
        </Helmet>

        <SideMenu parent='Mon stock' />
        <ContainerWrapper>
          <Container fluid className={(selectedPharma && selectedPharma.pharmacy.pharmacy_menu?.csv_import === 1 && !isMultipharmaAccount) ? 'blur' : ''}>

            {(selectedPharma && selectedPharma.pharmacy.pharmacy_menu?.csv_import === 1 && !isMultipharmaAccount) ? <img src={blurCsv} alt='' />
              : <>
                <Row className='headerTitle'>
                  <Col>
                    <h2>{f('importCSV')}</h2>
                    <Breadcrum />
                  </Col>
                </Row>
                <DropzoneRow>
                  <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    addRemoveButton
                    removeButtonColor='#659cef'
                    onRemoveFile={handleOnRemoveFile}
                  >
                    <span>{f('dropCSV')}</span>
                  </CSVReader>
                </DropzoneRow>
                <Row>
                  <Button onClick={handleOnSubmit}>{f('confirm')}</Button>
                </Row>
                </>}

          </Container>
        </ContainerWrapper>
        {(selectedPharma && selectedPharma.pharmacy.pharmacy_menu?.csv_import === 1 && !isMultipharmaAccount) &&
          <InfosViewDisabledModal
            isOpen={openModalDisabled}
            toggle={toggle}
          />}
      </ImportWrapper>
    </>
  )
}

const ImportWrapper = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  display: block;
  .bodyWrapper {
    background: var(--gris-bleu);
    width: 100%;
    padding: 1rem;
    @media (min-width: 60rem) {
      min-width: 70%;
      max-width: calc(100% -20rem);
    }
    .bodyHeader {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      button {
        display: flex;
        align-items: center;
        svg {
          height: 1.5rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
  .container-fluid {
    padding: 30px 20px 60px 20px;
  }
  .headerTitle {
    margin-bottom: 2rem;
    h2 {
      color: var(--texte-bleu-fonce);
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  .blur{
    filter: blur(8px);
    pointer-events: none
  }
`

const DropzoneRow = styled(Row)`
  margin-bottom: 10px;
`

export default ImportCSV
