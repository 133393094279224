import React from 'react'

import { Col } from 'reactstrap'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

interface NotAutoIncomeProps {
  handleUpdatePharmacy: (autoPayment: boolean) => Promise<void>
  isAutoPayment: boolean
}

const NotAutoIncome = ({ handleUpdatePharmacy, isAutoPayment }: NotAutoIncomeProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  return (
    <>
      <Col xs='3'>
        <h5 style={{ marginLeft: '-1rem' }}>
          {f('noAutomaticPayment')}
        </h5>
      </Col>
      <Col xs='1'>
        <FontAwesomeIcon
          style={{
            cursor: 'pointer'
          }}
          icon={faToggleOff} size='2x'
          onClick={() => {
            handleUpdatePharmacy(!isAutoPayment).catch(err => console.log(err))
          }}
        />
      </Col>
      <Col xs='8'>
        {f('toActivePayment')}
      </Col>
      <>
        <StyledFrame
          id='inlineFrameExample'
          title='Déclaration Revenus'
          src='https://ordophacil.typeform.com/to/FK1M4Ugd'
        />
      </>
    </>
  )
}

const StyledFrame = styled.iframe`
  height: 66vh;
  width: 100%;
  border: none;
`

export default NotAutoIncome
