import React, { useState, useEffect } from 'react'
import { Table } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { orderFileInfo, TableProps } from 'models/table.model'

import OrderModal from '../OrderModal'
import Pagination from './Pagination'

import RenderCell from 'components/RenderCell'
import HeaderTable from 'components/HeaderTable'

import API from 'utils/API'
import { alertSuccess, alertError, alertWarn } from 'utils/notifications'
import { sortChangedHandler } from 'utils/sortHandler'
import { sendReloadInfo } from 'utils/socket'

const ProductsTable: React.FC<TableProps> = ({
  columns,
  data,
  setSortCriteria,
  sortCriteria,
  searchValue,
  pageSize,
  getData
}) => {
  // Use the state and functions returned from useTable to build your UI
  const [offset, setOffset] = useState(0)
  const pageCount = data.length === 0 ? 1 : Math.ceil(data.length / (pageSize as number))
  const dataToShow = data.slice(offset * (pageSize as number), (pageSize as number) * (offset + 1))
  const [filesNames] = useState<orderFileInfo[][]>([])
  const [openModalOrder, setOpenModalOrder] = useState(false)
  const [orderIdToShow, setOrderIdToShow] = useState<number>(-1)
  const [pharmacyOrderIdToShow, setPharmacyOrderIdToShow] = useState<number>(
    -1
  )
  const [selectOrderId, setSelectOrderId] = useState<number>(-1)
  const [iconCircle, setIconCircle] = useState<string>('faCircleNotch')

  const { formatMessage, locale } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  useEffect(() => {
    setOffset(0)
  }, [searchValue])

  useEffect(() => {
    setOffset(0)
  }, [searchValue])

  const displayFaCircle = (): void => {
    setTimeout(function () {
      setIconCircle('faCheckCircle')
    }, 2000)
  }
  useEffect(() => {
    /* data.forEach((elem: any, index: any) => {
      API.post("/file/getOrderInfo", {
        orderId: elem.order_id,
        pharmacyOrderId: elem.pharmacyOrderId,
      }).then((result) => {
        setFilesNames((prevState) => [...prevState, result.data.data]);
      });
    }); */
  }, [data])

  const editShipped = (
    orderId: number,
    pharmacyOrderId: number,
    type: number,
    shipped: boolean,
    pharmacyName: string,
    origin: string
  ): void => {
    API.post('pharmacist/carts/shipped', {
      orderId,
      pharmacyOrderId,
      type,
      shipped,
      pharmacyName,
      origin
    })
      .then((res) => {
        if (res.data.success) {
          sendReloadInfo(orderId, pharmacyOrderId)
          getData()
          shipped
            ? alertSuccess(f('packageConfirm'))
            : alertWarn(f('packageCanceled'))
        }
      })
      .catch((err) => {
        console.log(err)
        alertError(f('failedUpdate'))
      })
  }

  const checkTextSelected = (): void => {
    const selection = window.getSelection()

    if (selection !== null) {
      if (typeof selection.anchorNode?.nodeValue === 'string') { if (selection.toString().length <= 0) setOpenModalOrder(true) }
    }
  }

  // Render the UI for your table
  return (
    <Wrapper>
      {orderIdToShow >= 0 && pharmacyOrderIdToShow >= 0 && (
        <OrderModal
          open={openModalOrder}
          filteredRows={data}
          data={data.find((order: any) => {
            return (
              order.pharmacyOrderId === pharmacyOrderIdToShow &&
              order.orderId === orderIdToShow
            )
          })}
          onClose={() => {
            setOpenModalOrder(false)
            setPharmacyOrderIdToShow(-1)
            setOrderIdToShow(-1)
          }}
          getData={getData}
          setSelectOrderId={setSelectOrderId}
          displayFaCircle={displayFaCircle}
          setIconCircle={setIconCircle}
        />
      )}
      <StyledTable responsive size='sm'>
        <thead>
          <tr>
            <HeaderTable
              columns={columns}
              sortCriteria={sortCriteria}
              sortChangedHandler={sortChangedHandler}
              setSortCriteria={setSortCriteria}
              thEqual={false}
              thAccessor='state'
              thClassname='space'
              sortDescOrAsc='ASC'
            />
          </tr>
        </thead>
        <tbody>
          {dataToShow.map((row: any, i: number) => (
            <tr key={`orders_body_tr_${i}`}>
              {columns.map((column, j: number) => {
                return (
                  <RenderCell
                    key={`order${i}_cell${j}`}
                    row={row}
                    rowIndex={i}
                    column={column}
                    page='order'
                    filesNames={filesNames}
                    locale={locale}
                    selectOrderId={selectOrderId}
                    iconCircle={iconCircle}
                    setPharmacyOrderIdToShow={setPharmacyOrderIdToShow}
                    setOrderIdToShow={setOrderIdToShow}
                    checkTextSelected={checkTextSelected}
                    editShipped={editShipped}
                  />
                )
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <Pagination
        onGoToPage={(newPage) => setOffset(newPage)}
        onNavigate={(step) => setOffset((prevValue) => prevValue + step)}
        offset={offset}
        pageCount={pageCount}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const StyledTable = styled(Table)`
  background-color: white;
  width: 100%;
  thead {
    font-size: 0.9rem;
    color: var(--texte-bleu-fonce);
    th div {
      display: flex;
      align-items: center;
    }
  }

  tbody,
  tbody input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }

  tr {
    width: 100%;
    div {
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      svg {
        width: 1rem;
        margin-left: 0.5rem;
      }
    }
  }
 
  td {
    vertical-align: middle;
    padding: 0.75rem 0.5rem;
    &:focus-visible {
      background: white;
    }
  }
  tbody {
    overflow: scroll;
    max-height: 40rem;
    button {
      margin: 0.5rem 0;
    }
    svg {
      width: 1rem;
    }
  }
  th  {
    border-top: none;
    border-bottom: none !important;
    padding-bottom: 0.5rem;
   }
  .space{
    padding-left: 1.4rem ;
  }
  .minWidth{
    min-width: 11rem;
  }
`

export default ProductsTable
