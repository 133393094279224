import { Pharmacy } from './pharmacy.model'

export interface UpdatableUser {
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  phone_number?: string | null
  city?: string | null
  code_postal?: string | null
  pharmacies?: Pharmacy[]
  user_id?: number | null
}

export interface UserState {
  userRole: number | string | null
  userId: number | null
  email: string | null
  firstname: string | null
  lastname: string | null
  phone_number: string | null
  city: string | null
  code_postal: string | null
  pharmacies: Pharmacy[]
  selectedPharma: Pharmacy
  user_role?: number | string | null
  isMultipharmaAccount: boolean | null
  isAuthenticated: boolean | null
}

export const newEmptyPharmacy: Pharmacy = {
  pharmacy_owner_id: -1,
  pharmacy_id: -1,
  pharmacist_id: -1,
  validation: -1,
  createdAt: '',
  updatedAt: '',
  pharmacy: {
    pharmacy_id: -1,
    name: 'Pas de pharmacie sélectionnée',
    url: '',
    address: '',
    latitude: -1,
    longitude: -1,
    img_link: '',
    phone: '',
    email: '',
    schedule: {},
    createdAt: '',
    updatedAt: '',
    phacil_url: '',
    fidelity: false,
    street: '',
    city: '',
    postal_code: '',
    standard: false,
    aircall_phone: '',
    auto_payment: false,
    pharmacy_menu: {}

  }
}

export interface Patient {
  patient_id: number | null
  user: UpdatableUser | null
  user_id: number | null
  email: string | null
  createdAt: Date | null
  updatedAt: Date | null
}
