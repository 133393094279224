import React, { useState, useEffect } from 'react'
import SendMessage from './SendMessage'
import API from 'utils/API'

import socket from 'utils/socket'

interface ChatProps {
  orderId: number
  pharmacyOrderId: number
  pharmacyName: string
  user_email: string
  type: number
}

const Chat = (props: ChatProps): JSX.Element => {
  // Room initialized with the orderId to get a specific room for each order
  const room = `${props.orderId.toString()}t${props.pharmacyOrderId.toString()}`

  // Username initialized later with get response
  const [userName, setUserName] = useState('')
  const [message, setMessage] = useState('')
  const [messageList, setMessageList] = useState<Object[]>([])

  useEffect(() => {
    // If socket was needed to avoid undefined error
    // because socket's not initialized in useEffect
    if (socket != null) {
      socket.on('receive_message', (data: any) => {
        console.log(data)
        setMessageList([...messageList, data])
      })
    }
  })

  useEffect(() => connectToRoom(), [])

  const connectToRoom = (): void => {
    // Room is the orderId of the order selected
    socket.emit('join_room', room)

    // Retrieve the messageList stored in db
    API.get(`/chat/messages/${room}`)
      .then((res) => {
        if (res.data.success === true) {
          setUserName(res.data.userInformation.user_name)

          if (res.data.result != null) {
            setMessageList(
              res.data.result.messages.map((key: any) => {
                return {
                  message: key.messages,
                  author: key.author,
                  time: key.time ?? key.time
                }
              })
            )
          }
        }
      })
      .catch((e: Error) => {
        console.log(`ici l'erreur : ${e.message}`)
      })
  }

  const quitRoom = (): void => {
    // Tell server user left room and triggers event associated
    socket.emit('quit_room', room)

    // Close the socket
    socket.disconnect()

    // Clean messageList
    setMessageList([])
  }

  // Send a message to the patient
  const sendMessage = async (): Promise<void> => {
    const current = new Date().toLocaleString()
    const messageContent = {
      room: room,
      content: {
        author: userName,
        message: message,
        time: current
      }
    }

    // Send a message to the user's current room
    socket.emit('send_message', messageContent)

    // Update the messageList with the new message content
    setMessageList([...messageList, messageContent.content])
    API.post(`/chat/messages/send/${room}`, {
      messages: messageContent.content.message,
      author: messageContent.content.author,
      time: messageContent.content.time,
      orderId: props.orderId,
      pharmacyOrderId: props.pharmacyOrderId,
      type: props.type,
      origin: 'pharmacist'
    }).catch((e: Error) => {
      console.log(`This didn't work:  + ${e.message}`)
    })

    setMessage('')
  }

  return (
    <div>
      <div>
        <SendMessage
          messageList={messageList}
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          quitRoom={quitRoom}
          orderId={props.orderId}
        />
      </div>
    </div>
  )
}

export default Chat
