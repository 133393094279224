import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Pharmacy } from 'models/pharmacy.model'
import { RootState } from 'models/redux.model'

import { setSelectedPharma } from '../actions'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import styled from 'styled-components'

interface SelectPharmaProps {
  pharmacies: Pharmacy[]
}

const SelectPharma = (props: SelectPharmaProps) => {
  const dispatch = useDispatch()

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const pharmacyState = useSelector((state: RootState) => state.user.selectedPharma)

  return (
    <StyledDiv>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <StyledDropdownToggle color="btn btn-primary" caret>{pharmacyState.pharmacy.name}</StyledDropdownToggle>
        <StyledDropdownMenu>
          {props.pharmacies && props.pharmacies.map((pharma) => (
            <DropdownItem
              key={pharma.pharmacy_id}
              onClick={() => {
                dispatch(setSelectedPharma(pharma))
              }}
            >
              {pharma.pharmacy.name}
            </DropdownItem>
          ))}
        </StyledDropdownMenu>
      </Dropdown>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  margin-left: 1rem;
  margin-bottom: 1rem;
  * {
    font-size: 1.2rem;
  }
`

const StyledDropdownToggle = styled(DropdownToggle)`
  max-width: 100%;
  `

const StyledDropdownMenu = styled(DropdownMenu)``

export default SelectPharma
