import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SideMenu from '../../components/SideMenu'
import { RootState } from '../../models/redux.model'
import Button from '../../components/common/Button'
import { history } from '../../store'
import API from 'utils/API'
import IconEdit from '../../assets/images/icon-edit.svg'
import IconDelete from '../../assets/images/icon-delete.svg'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Container, Row, Col, Input, FormGroup, Table } from 'reactstrap'
import { useIntl } from 'react-intl'

import { alertError, alertSuccess } from 'utils/notifications'

import { setSelectedPharma } from '../../actions'

import Pagination from '../../components/OrdersTable/Pagination'
import styled from 'styled-components'
import { StyledContainer } from './UserManagementStyledComponents'

import {
  AlignCenter as SortIcon,
  ChevronDown as SortDescIcon,
  ChevronUp as SortAscIcon
} from 'react-feather'
import { Column } from 'models/table.model'
import { Helmet } from 'react-helmet'
import { applySort, sortChangedHandler } from 'utils/sortHandler'

const UserManagement: React.FC = () => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const [dataUser, setDataUser] = useState([])
  const [keyword, setKeyword] = useState('')

  const listPharmacy = useSelector((state: RootState) => state.user.pharmacies)
  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma) // the current pharmacy selected

  const dispatch = useDispatch()

  const [offset, setOffset] = useState(0)
  const [rowPerPage, setRowsPerPage] = useState(50)
  const [pageCount, setPageCount] = useState(0)

  const [sortCriteria, setSortCriteria] = useState<{
    direction: 'DESC' | 'ASC'
    accessor: string
  } | null>({
    accessor: 'lastname',
    direction: 'ASC'
  })

  const columns: Column[] = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: f('Full Name'),
      accessor: 'lastname',
      filtrable: true
    },
    {
      Header: f('First Name'),
      accessor: 'firstname'
    },
    {
      Header: f('Email'),
      accessor: 'email'
    },
    {
      Header: f('Phone'),
      accessor: 'phone_number'
    },
    {
      Header: f('Barcode'),
      accessor: 'barcode'
    },
    {
      Header: f('Points'),
      accessor: 'points'
    }

  ]

  const onClick = (): void => {
    history.push('/manage/new-user')
  }

  const getTableUser = async (params: any): Promise<void> => {
    try {
      const res = await API.post('/mobile/admin/user/list-patient', params)
      if (res.data.success === true) {
        const uppercaseData = res.data.data
        for (let i = 0; i < uppercaseData.length; i++) {
          uppercaseData[i].lastname = uppercaseData[i].lastname.toUpperCase()
        }

        setDataUser(uppercaseData)
        setPageCount(res.data.paginate.last_page)
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  useEffect(() => {
    getTableUser({
      keyword: keyword,
      pharmacy_id: selectedPharma.pharmacy_id,
      per_page: rowPerPage,
      page: offset + 1
    }).catch(err => console.log(err))
  }, [keyword, selectedPharma, rowPerPage, offset])

  const handleOnPharmacyChange = (value: any): void => {
    const pharmacyId = parseInt(value)
    for (let i = 0; i < listPharmacy.length; i++) {
      if (listPharmacy[i].pharmacy_id === pharmacyId) {
        dispatch(setSelectedPharma(listPharmacy[i]))
        break
      }
    }
  }

  const handleClickDelete = async (userId: number): Promise<void> => {
    try {
      await API.delete(`/mobile/admin/user/delete/${userId}`, {})
      // await API.delete('/mobile/admin/user/delete/' + userId, {})
      alertSuccess(f('successDeleteUser'))
      getTableUser({
        keyword: keyword,
        pharmacy_id: selectedPharma.pharmacy_id
      }).catch(err => console.log(err))
    } catch (err: any) {
      console.log(err)
      alertError(f(err.message))
    }
  }

  const onDelete = (userId: any): void => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className='custom-ui'>
          <h1>{f('areYouSure')}</h1>
          <p>{f('deleteUser')}</p>
          <button onClick={onClose}>{f('no')}</button>
          <button
            onClick={() => {
              handleClickDelete(userId).catch(err => console.log(err))
              onClose()
            }}
          >
            {f('confirmDelete')}
          </button>
        </div>
      )
    })
  }

  useEffect(() => {
    applySort(dataUser, setDataUser, sortCriteria)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortCriteria])

  return (
    <StyledContainer>
      <Helmet>
        <title>{f('manageMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('managetMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <div className='UserMangagementWrapper dataContainer'>
        <div className='bodyWrapper'>
          <SideMenu parent='User' />
          <div className='containerWrapper user-list'>
            <Container fluid>
              <Row className='headerTitle'>
                <Col>
                  <h2>{f('userManagement')}</h2>
                  <p>{f('User List')}</p>
                  <div className='divider' />
                </Col>
              </Row>
              <Row className='bodyHeader search'>
                <Col>
                  <Input className='' onChange={(e) => setKeyword(e.target.value)} />
                </Col>
                <Col>
                  <FormGroup>
                    <Input
                      type='select'
                      name='select'
                      className='select'
                      onChange={(e) => handleOnPharmacyChange(e.target.value)}
                    >
                      {listPharmacy.map((item, index) => {
                        return (
                          <option
                            key={`listPharmacy_${index}`}
                            value={item.pharmacy_id}
                            selected={item.pharmacy_id === selectedPharma.pharmacy.pharmacy_id}
                          >
                            {item.pharmacy.name}
                          </option>
                        )
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  {/* <Link to="/manage/new-user"> */}
                  <div>
                    <Button className='AddUser' color='primary' size='md' onClick={onClick}>
                      {'+ ' + f('addUser')}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StyledTable className='main-table'>
                    <thead>
                      <tr>

                        {columns.map((column) => {
                          let icon = <SortIcon />
                          if ((sortCriteria != null) && sortCriteria.accessor === column.accessor) {
                            icon =
                              sortCriteria.direction === 'DESC' ? (
                                <SortDescIcon />
                              ) : (
                                <SortAscIcon />
                              )
                          }
                          return (
                            <th key={'head_' + column.accessor} className='spaceTh'>
                              <div
                                onClick={() => {
                                  if (column.filtrable) sortChangedHandler(column.accessor, 'DESC', setSortCriteria, sortCriteria)
                                }}
                                className={column.filtrable ? 'filtrable' : ''}
                              >
                                <span>{column.Header}</span>
                                {column.filtrable ? icon : null}
                              </div>
                            </th>
                          )
                        })}
                        <th className='text-center'>{f('Action')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        dataUser?.map((item: any, loop) => {
                          return (
                            <tr key={`dataUser${loop}`}>
                              <th className='text-center' scope='row'>
                                {loop + 1}
                              </th>
                              <td className='text-center'>{item?.lastname}</td>
                              <td className='text-center'>{item?.firstname}</td>
                              <td className='text-center'>{item?.email}</td>
                              <td className='text-center'>{item?.phone_number}</td>
                              <td className='text-center'>{item?.user_cards[0]?.card_id}</td>
                              <td className='text-center'>{item?.user_cards[0]?.point | 0}</td>
                              <td className='d-flex justify-content-center align-items-center'>
                                <div className='d-flex action'>
                                  <div>
                                    <Link to={`/manage/detail-user/${item?.user_id}`}>
                                      <img src={IconEdit} alt='' />
                                    </Link>
                                  </div>
                                  <div className='ml-4'>
                                    <button
                                      onClick={() => onDelete(item?.user_id)}
                                      className='delete-button'
                                    >
                                      <img src={IconDelete} alt='' />
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </StyledTable>
                  {dataUser.length > 0 && (
                    <Pagination
                      onGoToPage={(newPage) => setOffset(newPage)}
                      onNavigate={(step) => setOffset((prevValue) => prevValue + step)}
                      offset={offset}
                      pageCount={pageCount}
                      isRowsPerPage
                      setRowsPerPage={(rowPerPage) => setRowsPerPage(rowPerPage)}
                      currentRowsPerPage={rowPerPage}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </StyledContainer>
  )
}

const StyledTable = styled(Table)`
 
  tr {
    div {
      svg {
        width: 1rem;
        margin-left: 0.5rem;
      }
    }
  }

  tbody {
    svg {
      width: 1rem;
    }
  }
  
  .spaceTh {
        vertical-align: unset !important;
        text-align: center;
      }

  .filtrable{
    cursor: pointer;
  }

  .text-center{
    vertical-align: unset !important;
  }
     
`

export default UserManagement
