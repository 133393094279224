import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import API from 'utils/API'
import { alertError, alertSuccess } from 'utils/notifications'
// import { postData } from "utils/APIs";
import { addProduct, deleteProduct, updateProduct } from '../../actions/product'
import Button from '../../components/common/Button'
import InputMain from '../../components/common/InputMain'
import TableMain from '../../components/common/Table/TableMain'
import iconDelete from '../../assets/images/icon-delete.svg'

import { StyledContainer } from './OrderManagementStyledComponents'
import { Col, Row } from 'reactstrap'

const hiddenStyle = {
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
  borderRight: 'none'
}

const showStyle = {
  borderTopRightRadius: '0.25rem',
  borderBottomRightRadius: '0.25rem',
  borderRight: '1px solid #828282',
  border: '1px solid rgba(63,63,68,0.005)',
  boxShadow: '0px 1px 0px rgb(63 63 68 / 5%), 0px 1px 3px rgb(63 63 68 / 15%)',
  bordeRradius: '4px'
}

const OrderManagemetStepTwo = ({
  previewStepOne,
  nextStepTwo,
  dataCreateOrder
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const inputRef = useRef(null)

  const dispatch = useDispatch()

  const [numberBarcodeProduct, setNumberBarcodeProduct] = useState('')

  const [error, setError] = useState(false)
  const [show, setShow] = useState(false)

  const listProduct = useSelector(state => state.product?.product)
  const total = useSelector(state => state.product?.total)

  const [quantity, setQuantity] = useState(
    new Map(listProduct.map(elt => [elt.eanCode, elt.quantity]))
  )

  const [isProductNotFound, setIsProductNotFound] = useState(false)
  const [nameCustomProduct, setNameCustomProduct] = useState('')
  const [priceCustomProduct, setPriceCustomProduct] = useState('')
  const [eanCodeCustomProduct, setEanCodeCustomProduct] = useState('')

  const tableHeaderOrder = [
    {
      label: f('ID'),
      index: 'stt'
    },
    {
      label: f('capitalName'),
      index: 'name'
    },
    {
      label: f('price'),
      index: null,
      option: {
        className: 'text-center'
      },
      callback: data => {
        return <div>{data?.price?.toFixed(2)} €</div>
      }
    },
    {
      label: f('quantity2'),
      index: null,
      option: {
        className: 'text-center'
      },
      callback: data => {
        if (data.eanCode) {
          return (
            <div className='btn-group'>
              <InputMain
                className='input-main--custom'
                value={quantity.get(data.eanCode)}
                type='number'
                min={1}
                onChange={event => {
                  const value = parseInt(event.target.value)

                  dispatch(updateProduct({ ...data, quantity: value }))

                  setQuantity(prev => new Map(prev).set(data.eanCode, value))
                }}
              />
            </div>
          )
        }
      }
    },
    {
      label: 'Action',
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: data => {
        if (data.eanCode) {
          return (
            <div className='btn-group'>
              {/* <span>
                <label class="container-checkbox">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                </label>
              </span>
              <span>
                <img src={iconEdit} className="btn-icon mr-2" />
              </span> */}
              <button
                className='delete-button'
                onClick={() => {
                  dispatch(deleteProduct(data))
                  setQuantity(prev => {
                    const newQuantity = new Map(prev)
                    newQuantity.delete(data.eanCode)
                    setQuantity(newQuantity)
                  })
                }}
              >
                <img
                  src={iconDelete}
                  className='btn-icon'
                  alt='deleteProductIcon'
                />
              </button>
            </div>
          )
        }
      }
    }
  ]

  const onHandleNumberBarcodeProduct = e => {
    setNumberBarcodeProduct(e.target.value)
  }

  const onSubmitBarcodeProduct = async () => {
    try {
      const res = await API.post('/mobile/admin/product/get-by-code', {
        pharmacy_id: dataCreateOrder.pharmacy_id,
        ean_code: numberBarcodeProduct
      })

      if (res?.data?.success) {
        setIsProductNotFound(false)
        setEanCodeCustomProduct('')
        const atmObject = {
          id: res?.data?.data?.id,
          eanCode: res?.data?.data?.EAN_code,
          name: res?.data?.data?.name,
          product_id: res?.data?.data?.examples?.[0]?.id,
          price: res?.data?.data?.examples?.[0]?.priceRaw,
          isCustom: false,
          quantity: 1
        }

        const value = quantity.has(atmObject.eanCode)
          ? quantity.get(atmObject.eanCode) + 1
          : 1

        setQuantity(prev => new Map(prev).set(atmObject.eanCode, value))
        dispatch(addProduct(atmObject))

        alertSuccess(f('productAdded'))

        setError(false)
      }
    } catch (err) {
      if (err.response.data.message === 'PRODUCT_NOT_FID') {
        alertError(f('productNotFid'))
      } else {
        alertError(f('productNotFound'))
        setIsProductNotFound(true)
        setEanCodeCustomProduct(numberBarcodeProduct)
      }
      if (err?.success === false) {
        setError(true)
      }
    }
  }
  const onSubmitBarcodeProductCustom = () => {
    setIsProductNotFound(false)
    const atmObject = {
      id: null,
      eanCode: eanCodeCustomProduct,
      idProductFid: null,
      name: nameCustomProduct,
      product_id: null,
      price: parseFloat(priceCustomProduct),
      isCustom: true,
      quantity: 1
    }
    const value = quantity.has(atmObject.eanCode)
      ? quantity.get(atmObject.eanCode) + 1
      : 1

    setQuantity(prev => new Map(prev).set(atmObject.eanCode, value))

    dispatch(addProduct(atmObject))
    alertSuccess(f('productAdded'))
  }

  return (
    <StyledContainer>
      <div className='block-btn-main block-btn-main--justify'>
        <Button
          onClick={() => {
            setShow(true)
            inputRef.current.focus()
          }}
          children={f('addManually')}
        />
        <Button
          disabled={listProduct?.length <= 0}
          className='btn-next'
          onClick={nextStepTwo}
          children={f('next')}
        />
      </div>
      <div className='block-form-add'>
        <form
          className='block-form-add-input-btn'
          onSubmit={async e => {
            e.preventDefault()
            await onSubmitBarcodeProduct()
            inputRef.current.focus()
            inputRef.current.value = ''
          }}
        >
          <InputMain
            onChange={onHandleNumberBarcodeProduct}
            className='input-main'
            label={f('eanCodeInput')}
            error={error}
            msg={error ? 'An error occurred' : ''}
            style={show ? hiddenStyle : showStyle}
            innerRef={inputRef}
            autofocus
          />
          <Button
            style={{ visibility: `${show ? 'visible' : 'hidden'}` }}
            className='btn-add btn-text-main'
            children={f('add')}
            type='submit'
          />
        </form>

        {/* <span className="text-or">OR</span>
        <Button
          onClick={toggle}
          className="btn-scan-card btn-text-main"
          children="Choose from databased"
        /> */}
      </div>
      {
        isProductNotFound && (
          <form
            className='block-form-add-input-custom-btn'
            onSubmit={async e => {
              e.preventDefault()
              onSubmitBarcodeProductCustom()
            }}
          >
            <h5>{f('addNewProduct')}</h5>

            <Row>
              <Col md='4'>

                <InputMain
                  onChange={(e) => { setEanCodeCustomProduct(e.target.value) }}
                  value={eanCodeCustomProduct}
                  className='input-main'
                  label={f('eanCodeInput')}
                  style={showStyle}
                  required
                />
              </Col>

              <Col md='4'>
                <InputMain
                  onChange={(e) => { setNameCustomProduct(e.target.value) }}
                  className='input-main'
                  label={f('productName')}
                  style={showStyle}
                  required
                />
              </Col>
              <Col md='4'>
                <InputMain
                  onChange={(e) => { setPriceCustomProduct(e.target.value) }}
                  className='input-main'
                  label={f('productPriceCustom')}
                  style={showStyle}
                  required
                  type='number'
                  step={0.01}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className='btn-cancel btn-text-main'
                  children={f('cancel')}
                  floatStyle='right'
                  onClick={() => {
                    setIsProductNotFound(false)
                  }}
                />
              </Col>

              <Col>
                <Button
                  className='btn-add btn-text-main'
                  children={f('add')}
                  type='submit'
                  floatStyle='left'
                />
              </Col>

            </Row>

          </form>

        )
      }

      {listProduct?.length > 0 && (
        <div className='block-table-main'>
          <div className='block-table-main__top d-flex justify-content-between'>
            <span className='block-table-main__title'>{f('productList')}</span>
            <span className='block-table-main__title'>
              {f('productPrice')}: {total.toFixed(2)} €
            </span>
          </div>

          <TableMain
            fixed
            tableHeaders={tableHeaderOrder}
            tableData={listProduct}
            tablePaginate={{}}
            isLoading={false}
          />
        </div>
      )}
    </StyledContainer>
  )
}

export default OrderManagemetStepTwo
