import React from 'react'
import { useSelector } from 'react-redux'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { useIntl } from 'react-intl'
import { history } from 'store'

import { StyledContainer } from '../assets/styles/UpdateUserFormStyledComponents'
import moment from 'moment'
import MaskedInput from 'react-text-mask'
import { RootState } from 'models/redux.model'

const DetailUserForm = (props: any) => {
  const { userInfo } = props
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const listPharmacy = useSelector((state: RootState) => state.user.pharmacies)

  const onSubmitForm = (fields: any) => {
    props.onSubmitUpdate(fields)
  }
  return (
    <StyledContainer>
      <Formik
        enableReinitialize
        initialValues={{
          email: userInfo?.email,
          firstname: userInfo?.firstname,
          lastname: userInfo?.lastname,
          phone: userInfo?.phone_number,
          address: userInfo?.address,
          code: userInfo?.user_cards[0]?.card_id,
          notes: userInfo?.notes,
          birthday: userInfo?.birthday
            ? moment(userInfo?.birthday).format('DD-MM-YYYY')
            : '',
          patient_id: userInfo?.patient?.patient_id
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(f('validEmail')).required(f('pleaseEmail')),
          firstname: Yup.string().required(f('pleaseFirstName')),
          lastname: Yup.string().required(f('pleaseLastName')),
          phone: Yup.string().required(f('pleasePhone')),
          code: Yup.string().required(f('pleaseCode'))
        })}
        onSubmit={(fields) => {
          const [day, month, year] = fields.birthday.split('-')
          const date = [year, month, day].join('-')
          fields.birthday = date

          onSubmitForm(fields)
        }}

      >
        {({ errors, touched }) => (
          <Form>
            <Field name='patient_id' type='hidden' />
            <div className='UpdateUserWrapper'>
              <div className='row'>

                <div className='form-group col-md-6'>
                  <label htmlFor='code'>{f('userCode')}</label>
                  <Field
                    name='code'
                    type='text'
                    placeholder={f('userCode')}
                    className={
                      'form-control' +
                      (errors.code && touched.code ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='code'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='firstname'>{f('firstName')}</label>
                  <Field
                    name='firstname'
                    type='text'
                    placeholder={f('firstName')}
                    className={
                      'form-control' +
                      (errors.firstname && touched.firstname ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='firstname'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='lastname'>{f('Last Name')}</label>

                  <Field
                    name='lastname'
                    type='text'
                    placeholder={f('Last Name')}
                    className={
                      'form-control' +
                      (errors.lastname && touched.lastname ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='lastname'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='email'>{f('Email')}</label>

                  <Field
                    name='email'
                    type='text'
                    placeholder={f('Email')}
                    className={
                      'form-control' +
                      (errors.email && touched.email ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='phone'>{f('Phone Number')}</label>

                  <Field
                    name='phone'
                    type='text'
                    placeholder={f('Phone Number')}
                    className={
                      'form-control' +
                      (errors.phone && touched.phone ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='phone'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label htmlFor='birthday'>{f('Birthday')}</label>

                  <Field
                    name='birthday'
                    render={({ field }: { field: any }) => (
                      <MaskedInput
                        {...field}
                        mask={[
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/
                        ]}
                        id='phone'
                        placeholder='JJ-MM-AAAA'
                        type='text'
                        className={
                          'form-control' +
                          (errors.birthday && touched.birthday
                            ? ' is-invalid'
                            : '')
                        }
                      />
                    )}
                  />
                  <ErrorMessage
                    name='birthday'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>

                <div className='form-group col-md-12'>
                  <label htmlFor='notes'>{f('Notes')}</label>

                  <Field
                    name='notes'
                    type='text'
                    as='textarea'
                    placeholder='Notes'
                    className={
                      'form-control' +
                      (errors.notes && touched.notes ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage
                    name='notes'
                    component='div'
                    className='invalid-feedback'
                  />
                </div>
                <div className='col-md-12'>
                  <div className='d-flex justify-content-center AddUserButton'>
                    <div className='form-group'>
                      <button type='submit' className='btn btn-primary mr-2'>
                        {f('UPDATE')}
                      </button>
                    </div>
                    <div className='form-group'>
                      <button
                        type='button'
                        className='btn btn-secondary mr-2'
                        onClick={() => history.push('/manage')}
                      >
                        {f('CANCEL')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </StyledContainer>
  )
}

export default DetailUserForm
