import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { history } from '../../../store'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Col, Container, Row } from 'reactstrap'
import LoginImg from '../../../src/assets/images/landingPage/login.svg'
import { ButtonContainer, StyledContainer } from '../../../assets/styles/landingPage/Creativity'

const AuthForm: React.FC<{
  onLogin: (fields: { email: string, password: string }) => any
}> = ({ onLogin }) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  return (
    <div className='container'>
      <Row style={{ alignItems: 'center', marginLeft: 'unset', marginRight: 'unset' }}>
        <Col md='6'>
          <StyledContainer>
            <h1><span className='text-primary'>{f('carePathway')} </span>{f('oneHundredPourcent')}<span className='text-primary'> {f('connected')}</span></h1>
            <h2 style={{ color: 'black' }}>{f('onlineExperience')}<span className='text-primary'> {f('simplified')}</span></h2>
            <p className='para-desc'>
              {f('prescriptionServices')}<br />
              {f('subtitleAuth')}
            </p>
            <ButtonContainer className='mt-4'>
              <a href='https://doc.phacil.app' target='_blank' className='btn btn-primary mt-2 me-2' rel='noreferrer'>{f('doctorWebsite')}</a>
              <a href='https://meds-connect.com' className='btn btn-outline-primary mt-2' target='_blank' rel='noreferrer'>{f('moreInfo')}</a>
            </ButtonContainer>
          </StyledContainer>
        </Col>

        <Col md='6'>
          <div>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(f('wrongEmail'))
                  .required(f('pleaseEmail2')),
                password: Yup.string()
                  .min(4, f('atLeastPasswd'))
                  .required(f('pleasePasswd'))
              })}
              onSubmit={(fields) => onLogin(fields)}
            >
              {({ errors, touched }) => (
                <div className='splash-container'>
                  <div className='card'>
                    <div className='card-header text-center'>
                      <h2>{f('phacilAdmin')}</h2>
                      <h5 className='splash-description'>
                        {f('connect')}
                      </h5>
                    </div>

                    <div className='card-body'>
                      <Form>
                        <div className='form-group'>
                          {/* <label htmlFor="email">Email</label> */}
                          <Field
                            name='email'
                            type='text'
                            className={
                              'form-control form-control-lg' +
                              (errors.email && touched.email ? ' is-invalid' : '')
                            }
                            placeholder={f('mail')}
                          />
                          <ErrorMessage
                            name='email'
                            component='div'
                            className='invalid-feedback'
                          />
                        </div>
                        <div className='form-group'>
                          {/* <label htmlFor="password">Password</label> */}
                          <Field
                            name='password'
                            type='password'
                            className={
                              'form-control form-control-lg' +
                              (errors.password && touched.password ? ' is-invalid' : '')
                            }
                            placeholder={f('password')}
                          />
                          <ErrorMessage
                            name='password'
                            component='div'
                            className='invalid-feedback'
                          />
                        </div>
                        <div className='form-group'>
                          <label className='custom-control custom-checkbox'>
                            <Field
                              className='custom-control-input'
                              type='checkbox'
                              name='rememberme'
                            />
                            <span className='custom-control-label'>
                              {f('rememberMe')}
                            </span>
                          </label>
                        </div>
                        <button
                          type='submit'
                          className='btn btn-primary btn-lg btn-block'
                        >
                          {f('connection')}
                        </button>
                      </Form>
                    </div>
                    <Row className='card-footer bg-white p-0'>
                      <Col className='card-footer-item card-footer-item-bordered' md='6'>
                        <a href='https://share-eu1.hsforms.com/19Gqqz6YOSgK1GUNP1jKljgf2aid' target='_blank' className='footer-link' rel='noreferrer'>
                          {f('createAcc')}
                        </a>
                      </Col>
                      <Col className='card-footer-item card-footer-item-bordered' md='6'>
                        <StyledButton className='footer-link' onClick={() => history.push('password')}>
                          {f('forgotPasswd')}
                        </StyledButton>
                        {/* <a href='/password' className='footer-link'>
                 {f('forgotPasswd')}
               </a> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Formik>
          </div>

        </Col>
      </Row>

    </div>

  )
}

const StyledButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  color: #71748d;
`

export default AuthForm
