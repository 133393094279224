import React, { useState } from 'react'
import { Table, Input } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import Pagination from './Pagination'
import { TableProps } from 'models/table.model'

import HeaderTable from 'components/HeaderTable'
import RenderCell from 'components/RenderCell'

import { alertError, alertSuccess } from 'utils/notifications'
import API from 'utils/API'

import { sortChangedHandler } from 'utils/sortHandler'

export type InputType = | 'text' | 'hidden'

const VoicemailTable: React.FC<TableProps> = ({
  columns,
  data,
  setSortCriteria,
  sortCriteria,
  pageCount,
  offset,
  setOffset,
  getData,
  pageSize,
  activeTab
}) => {
  const dataToShow = data

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })
  const [commentary, setCommentary] = useState('')
  const [selectRowId, setSelectRowId] = useState(null)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, callId: number): void => {
    if (e.key === 'Enter') {
      API.post('/aircall/calls', {
        callId,
        commentary
      }).then(res => {
        alertSuccess(f('addCommentary'))
      }).catch(err => {
        alertError(f('errorCommentary'))
        console.log('err: ', err)
      })
    }
  }

  const handleActions = (callId: number, deleted: boolean): void => {
    API.post('/aircall/calls', {
      callId,
      deleted
    }).then(res => {
      deleted ? alertSuccess(f('deletedVoice')) : alertSuccess(f('reactivation'))
      getData(offset, pageSize)
    }).catch(err => {
      deleted ? alertError(f('errorDelete'))
        : alertError(f('errorReactivation'))

      console.log('err: ', err)
    })
  }

  const renderInputCommentary = (callId: number, commentary: string): React.ReactElement => {
    return (
      <Input
        addon
        type='text'
        defaultValue={commentary}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCommentary(e.target.value)
        }}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
          handleKeyPress(e, callId)

          // if (e.key === 'Enter') (e.target as HTMLTextAreaElement).blur();
        }}
      />
    )
  }

  const formatCommentary = (commentary: string): string => {
    commentary = commentary.substring(0, 6)
    return `${commentary}...`
  }

  // Render the UI for your table
  return (
    <Wrapper>
      <StyledTable responsive>
        <thead>
          <tr>
            <HeaderTable
              columns={columns}
              sortCriteria={sortCriteria}
              sortChangedHandler={sortChangedHandler}
              setSortCriteria={setSortCriteria}
              thEqual
              thAccessor='createdAt'
              thClassname='spaceDate'
              sortDescOrAsc='ASC'
            />
          </tr>
        </thead>
        <tbody>
          {dataToShow.map((row: any, i: number) => (
            <tr key={`body_tr_${i}`}>
              {columns.map((column) => {
                return (
                  <RenderCell
                    key={i}
                    row={row}
                    rowIndex={i}
                    column={column}
                    page='voicemail'
                    renderInputCommentary={renderInputCommentary}
                    selectRowId={selectRowId}
                    formatCommentary={formatCommentary}
                    setSelectRowId={setSelectRowId}
                    activeTab={activeTab}
                    handleActions={handleActions}
                  />
                )
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <Pagination
        onGoToPage={(newPage) => {
          setOffset?.(newPage)
        }}
        onNavigate={(step) => {
          setOffset?.((prevValue) => prevValue + step)
        }}
        offset={offset != null ? offset : 0}
        pageCount={pageCount != null && pageCount > 0 ? pageCount : 1}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const StyledTable = styled(Table)`
  width: 100%;
  thead {
    font-size: 0.9rem;
    color: var(--texte-bleu-fonce);
    th div {
      display: flex;
      align-items: center;
    }
  }

  tbody,
  tbody input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }

  tr {
    width: 100%;
    div {
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      svg {
        width: 1rem;
        margin-left: 1rem;
      }
    }
  }

  td {
    vertical-align: middle;
    padding-left: 1rem !important ;
    &:focus-visible {
      background: white;
    }
  }
  tbody {
    overflow: scroll;
    max-height: 40rem;
    button {
      margin: 0.5rem 0;
    }
    svg {
      width: 1rem;
    }
  }
 .audio {
   height: 2rem;
   width: 20rem;
   
 } 

th  {
  border-top: none !important;
  border-bottom: none !important;
  padding-bottom: 0.5rem;
  padding-left: 1rem !important ;

}

.spaceDate {
  padding-left: unset !important ;
  min-width: 8.6rem;
}

.spaceCheckbox {
    text-align: center;
    padding-left: 1rem !important ;
}

.icon {
  margin: unset !important
}
`

export default VoicemailTable
