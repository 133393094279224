import React from 'react'

import { Col } from 'reactstrap'

import SeoImg from '../../assets/images/landingPage/SEO_SVG.svg'
import MobileImg from '../../assets/images/landingPage/Mobile_notification_SVG.svg'
import { ContentWork, SecondContentWork } from '../../assets/styles/landingPage/HowItWorks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { stylesLandingPage } from '../../constants/styles.constants'
import { useIntl } from 'react-intl'

const HowItWorks: React.FC<{}> = () => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <Col md='12' style={{ textAlign: 'center' }}>
          <ContentWork>
            <h4>{f('ourGoal')}</h4>
            <p className='para-desc'>
              {f('ourGoalPart1')}
              <span className='text-primary'> Phacil, </span>
              {f('ourGoalPart2')}.
            </p>
          </ContentWork>
        </Col>
      </div>

      <div className='row align-items-center'>
        <div className='col-lg-5 col-md-6 mt-4 pt-2'>
          <img src={SeoImg} alt='SeoImg' />

        </div>
        <div className='col-lg-7 col-md-6 mt-4 pt-2 col'>
          <SecondContentWork>
            <h4>{f('workTitlePart1')}</h4>
            <p>
              {f('workTextPart1')}
            </p>
            <ul className='list-unstyled text-muted'>
              <li className='mb-2'>
                <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                <span className='text-primary h5 me-2' /> {f('workItem1')}
              </li>
              <li className='mb-2'>
                <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                <span className='text-primary h5 me-2' /> {f('workItem2')}
              </li>

              <li className='mb-2'>
                <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                <span className='text-primary h5 me-2' /> {f('workItem3')}
              </li>
            </ul>

            <a href='https://phacil.delivery/pharmacies' target='_blank' className='btn btn-primary mt-2 me-2' rel='noreferrer'>{f('readMore')}</a>

          </SecondContentWork>
        </div>
      </div>

      <div className='row align-items-center'>
        <Col md='6'>
          <ContentWork>
            <h4>{f('workTitlePart2')}
              <span className='text-primary'> Phacil </span>
            </h4>
            <p>
              {f('workTextPart2')}
            </p>
            <ul className='list-unstyled text-muted'>
              <li className='mb-2'>
                <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                <span className='text-primary h5 me-2' /> {f('workItem4')}
              </li>
              <li className='mb-2'>
                <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                <span className='text-primary h5 me-2' /> {f('workItem5')}
              </li>
              <li className='mb-2'>
                <FontAwesomeIcon icon={faCheck} color={stylesLandingPage.color_phacil} />
                <span className='text-primary h5 me-2' /> {f('workItem6')}
              </li>
            </ul>
            <a href='https://phacil.delivery/pharmacies' target='_blank' className='btn btn-primary mt-2 me-2' rel='noreferrer'>{f('readMore')}</a>

          </ContentWork>
        </Col>

        <Col md='6'>
          <div style={{ padding: '1.5rem' }}>
            <img src={MobileImg} alt='SeoImg' />
          </div>
        </Col>
      </div>
    </div>

  )
}
export default HowItWorks
