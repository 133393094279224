import InputCell from 'components/StockTable/InputCell'
import { RenderCellStock } from 'models/table.model'

const StockTable = ({ row, rowIndex, column, onCellChange }: RenderCellStock): JSX.Element => {
  let classNameValue = ''
  if (column.accessor !== 'name') {
    classNameValue = 'space'
  }

  if (column.editable) {
    if ((row.category1 === 'médicaments' && column.accessor !== 'reduction') || (row.category1 !== 'médicaments')) {
      return (
        <td key={`td${rowIndex}_${column.accessor}`} className={classNameValue}>
          <InputCell
            cellId={`cell${rowIndex}_${column.accessor}`}
            inputType={column.type || 'text'}
            startValue={String(row[column.accessor]) || ''}
            onCellChange={(newValue) =>
              (onCellChange != null) && onCellChange({
                accessor: column.accessor,
                rowIndex: rowIndex,
                newValue
              })}
          />
        </td>
      )
    } else if (row.category1 === 'médicaments' && column.accessor === 'reduction') {
      return (
        <td
          key={`td${rowIndex}_${column.accessor}`}
          className={classNameValue}
          style={{ paddingLeft: '2rem' }}
        >
          {row[column.accessor]}
        </td>
      )
    }
  }

  return (
    <td key={`td${rowIndex}_${column.accessor}`} className={classNameValue}>{row[column.accessor]}</td>
  )
}

export default StockTable
