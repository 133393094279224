import styled from 'styled-components'

export const StyledContainer = styled.div`
  .CreateUserWrapper {
    .row {
      padding: 2rem;
      max-width: 80%;
      margin-left: 15px;
      // margin: auto;
      background-color: #fff;
      border: 1px solid rgba(63, 63, 68, 0.005);
      box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
      border-radius: 4px;
      .form-group {
        position: relative;
        label {
          position: absolute;
          top: -8px;
          left: 25px;
          padding: 0px 6px;
          background-color: #fff;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #4f4f4f;
        }
      }
      input,
      select {
        height: 40px;
        background: transparent;
      }
      textarea {
        height: 100px;
        background: transparent;
      }
      input::placeholder,
      textarea::placeholder {
        opacity: 0.5;
      }
      button {
        width: 152px;
        height: 40px;
      }
      .AddUserHeader {
        padding-bottom: 30px;
      }
      .AddUserButton {
        padding-top: 30px;
      }
    }
  }
`
