import styled from 'styled-components'

export const HeaderContainer = styled.div`

img{
    margin-left: 1rem;
    margin-top: 1.3rem;
    
}

@media (max-width: 767px){
  text-align : center;
  img{
    margin-left: unset;
    margin-top: unset;
    padding: 2rem;
  }
 
}
`
