import axios from 'axios'
import config from '../config/keys'

const keys = config as {
  baseUrl: string
  apiKey: string
}

const token = localStorage.getItem('token')
// Handle get data
const axiosInstance = axios.create({
  baseURL: `${keys.baseUrl}/api`,
  headers: {
    Authorization: `${token}`,
    token: keys.apiKey
  },

  timeout: 10000
})

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    return await Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
)

const getData = async (url: any) => {
  try {
    const result = await axiosInstance.get(url)
    return result
  } catch (e: any) {
    throw e
  }
}
const postData = async (url: any, data: any) => {
  try {
    const result = await axiosInstance.post(url, data)
    return result
  } catch (e: any) {
    throw e
  }
}
const PutData = async (url: any, data: any) => {
  try {
    const result = await axiosInstance.put(url, data)
    return result
  } catch (e: any) {
    throw e
  }
}
const DeleteData = async (url: any, data: any) => {
  try {
    const result = await axiosInstance.delete(url, data)
    return result
  } catch (e: any) {
    throw e
  }
}

export { axiosInstance, getData, postData, PutData, DeleteData }
