import React from 'react'
import styled from 'styled-components'

const Button = (props: any) => {
  const StyledButton =
    props.primary === true
      ? styled.button`
          border: solid 1px #ffb064;
          box-shadow: none;
          border-radius: 3px;
          padding: 5px;
          background-image: linear-gradient(to bottom right, #ff625b, #ffb064);
          color: white;
          transform: perspective(1px) translateZ(0);
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          min-width: 15rem;
          min-height: 3rem;

          &:disabled {
            background-image: linear-gradient(to bottom right, #ffaaa6, #ffd1a6);
            cursor: not-allowed;
          }
        `
      : styled.button`
          background: white;
          border: solid 1px #ffb064;
          color: #ffb064;
          box-shadow: none;
          border-radius: 3px;
          padding: 5px;
          width: 15rem;
          min-height: 3rem;
        `

  return (
    <StyledButton onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </StyledButton>
  )
}

export default Button
