import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Col } from 'reactstrap'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn } from '@fortawesome/free-solid-svg-icons'

import { StyledTable } from 'styles'

import { Column, Row } from 'models/table.model'
import { RootState } from 'models/redux.model'

import { alertSuccess, alertError } from 'utils/notifications'

import API from 'utils/API'
import { AutoIncomeProps } from 'models/income.model'
import RenderCell from 'components/RenderCell'
import moment from 'moment'
import PdfIncome from './PdfIncome'
import { useReactToPrint } from 'react-to-print'
import { OrdersRow, parseOrders } from 'utils/ordersHandler'
const loadingIcon = 'images/loading-gif-phacil.svg'

const AutoIncome = ({ handleUpdatePharmacy, isAutoPayment, pharmacyId, data, isLoading, setData, getData, setIsloading }: AutoIncomeProps): JSX.Element => {
  const { formatMessage, locale } = useIntl()
  const f = (id: any): string => formatMessage({ id })
  const isMultipharmaAccount = useSelector((state: RootState) => state.user.isMultipharmaAccount)
  const componentRef = useRef(null)
  const [orders, setOrders] = useState<OrdersRow[]>([]) // Array of examples
  const [timestampStart, setTimestampStart] = useState('')
  const [timestampEnd, setTimestampEnd] = useState('')
  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )
  const columns: Column[] = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: f('month').replace('m', 'M'),
      accessor: 'formattedMonth'
    },
    {
      Header: f('amount'),
      accessor: 'formattedCost'
    },
    {
      Header: f('Validation'),
      accessor: 'validation'
    },
    {
      Header: f('Paiement'),
      accessor: 'payment_confirmed'
    },
    {
      Header: 'Détail',
      accessor: 'détail'
    },
    {
      Header: 'Téléchargement',
      accessor: 'Téléchargement'
    },
    {
      Header: 'Facturation',
      accessor: 'facturation'
    }
  ]

  const columnsOrder: Column[] = [
    {
      Header: 'N° Commande',
      accessor: 'orderId'
    },
    {
      Header: 'Type de commande',
      accessor: 'type'
    },
    {
      Header: 'Date de coomande',
      accessor: 'date'
    },
    {
      Header: 'Prix',
      accessor: 'costsDetails'
    },
    {
      Header: 'Type de livraison',
      accessor: 'deliveryMode'
    },
    {
      Header: 'Client',
      accessor: 'customerName'
    }
  ]

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const handleUpdateValidation = (validation: boolean, rowIndex: number, date: string): void => {
    if (isMultipharmaAccount === true) {
      const updateData = {
        validation: !validation,
        pharmacyId,
        date
      }

      API.post('/pharmacy/fees/insert', updateData)
        .then(result => {
          const newData = [...data]
          newData[rowIndex].validation = !validation
          setData(newData)

          alertSuccess(f('validIncome'))
        }).catch(err => {
          console.log(err)
          alertError(f('ErrorIncome'))
        })
    }
  }

  const handleUpdatePaymentConfirmation = (paymentConfirmed: boolean, rowIndex: number, date: string): void => {
    if (isMultipharmaAccount === true) {
      const updateData = {
        payment_confirmed: !paymentConfirmed,
        pharmacyId,
        date
      }

      API.post('/pharmacy/fees/insert', updateData)
        .then(result => {
          const newData = [...data]
          newData[rowIndex].payment_confirmed = !paymentConfirmed
          setData(newData)

          alertSuccess(f('validPaiement'))
        }).catch(err => {
          console.log(err)
          alertError(f('ErrorIncomePaiement'))
        })
    }
  }

  const getTimestampPerMonth = (month: number, year: number): { timestampStart: number, timestampEnd: number } => {
    const daysInMonth = new Date(year, month, 0).getDate()
    let newMonth = ''
    if (month < 10) {
      newMonth = `0${month}`
    }
    const timestampStart = moment(`${year}-${newMonth !== '' ? newMonth : month}-01`).startOf('date').unix()
    const timestampEnd = moment(`${year}-${newMonth !== '' ? newMonth : month}-${daysInMonth}`).endOf('date').unix()

    return {
      timestampStart,
      timestampEnd
    }
  }

  const transferPharmacyFees = async (month: number, year: number): Promise<void> => {
    try {
      const times = getTimestampPerMonth(month, year)

      const startDate = new Date(times.timestampStart * 1000)
      const endDate = new Date(times.timestampEnd * 1000)

      const res = await API.post(`/pharmacy/fees/transfer/${pharmacyId}`, {
        startDate,
        endDate
      })
      if (res.data.success === true) {
        setIsloading(false)
        await getData()
        alertSuccess('Virement effectué avec succès')
      }
    } catch (err: any) {
      alertError(`Erreur: ${err.response.data.err}`)
      console.log(err)
    }
  }

  const renderHeader = (column: Column) => {
    if ((isMultipharmaAccount === true && column.Header === 'Facturation') || (column.Header !== 'Facturation')) {
      return (
        <th key={'head_' + column.accessor}>
          <div>
            <span>{column.Header}</span>
          </div>
        </th>
      )
    }
  }
  const getDataPerMonth = async (pharmaId: number, start: string, end: string): Promise<void> => {
    try {
      if (pharmaId === -1) return

      const url = `/pharmacist/pharmacy-orders/${pharmaId}?start=${start}&end=${end}`

      const examplesResponse = await API.get(url)

      const orders = parseOrders(
        examplesResponse.data.responsePara,
        examplesResponse.data.responsePrescription,
        examplesResponse.data.responseParaAndPrescription,
        locale
      )
      let results = orders.filter(el => el.state > 1 && el.state < 6)

      results = results.sort((orderA, orderB) => orderB.orderId - orderA.orderId)

      setOrders(results)
      handlePrint()
    } catch (e: any) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (selectedPharma.pharmacy_id !== -1) {
      if (timestampStart !== '' && timestampEnd !== '') {
        getDataPerMonth(selectedPharma.pharmacy_id, timestampStart, timestampEnd).catch(err => console.log(err))
      }
    }
  }, [timestampStart, timestampEnd])

  return (
    <>
      <Col xs='3'>
        <h5 style={{ marginLeft: '-1rem' }}>
          {f('automaticPayment')}
        </h5>
      </Col>
      <Col xs='1'>
        <FontAwesomeIcon
          style={{
            cursor: 'pointer'
          }}
          icon={faToggleOn}
          size='2x'
          onClick={() => {
            handleUpdatePharmacy(!isAutoPayment).catch(err => console.log(err))
          }}
        />
      </Col>
      <Col xs='8'>
        {f('toDisablePayment')}
      </Col>

      {isLoading
        ? <StyledIncome>
          <StyledTable responsive>
            <thead>
              <tr>
                {columns.map((column) => {
                  return renderHeader(column)
                }
                )}
              </tr>
            </thead>
            <tbody>
              {data.map((row: any, i: number) => (
                <tr key={`body_tr_${i}`} style={{ backgroundColor: 'white' }}>
                  {columns.map((column) => {
                    return (
                      <RenderCell
                        key={i}
                        row={row}
                        rowIndex={i}
                        column={column}
                        page='autoIncome'
                        isMultipharmaAccount={isMultipharmaAccount}
                        handleUpdateValidation={handleUpdateValidation}
                        handleUpdatePaymentConfirmation={handleUpdatePaymentConfirmation}
                        getTimestampPerMonth={getTimestampPerMonth}
                        transferPharmacyFees={transferPharmacyFees}
                        handlePrint={handlePrint}
                        setTimestampStart={setTimestampStart}
                        setTimestampEnd={setTimestampEnd}

                      />
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </StyledTable>
          </StyledIncome>
        : <StyledLoader>
          <img src={loadingIcon} alt='loading' width='35' />
          </StyledLoader>}

      <div style={{ display: 'none' }}>
        <PdfIncome
          ref={componentRef}
          data={orders}
          columns={columnsOrder}
          parent='autoIncome'
        />
      </div>
    </>
  )
}

const StyledIncome = styled.div`
  width: 100%;
  margin: auto;
  border: none;
  margin-top: 1rem;
`

const StyledLoader = styled.div`
  height: 66vh;
  width: 100%;
  text-align: center;
  position: relative;

  img{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

`

export default AutoIncome
