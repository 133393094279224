import React from 'react'
import { RenderCell } from 'models/income.model'

const PdfIncome = ({ row, rowIndex, column, f }: any): JSX.Element => {
  if (column.accessor === 'validation') {
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
      >
        {row[column.accessor] === true ? f('yes') : f('waiting')}
      </td>
    )
  }

  if (column.accessor === 'payment_confirmed') {
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
      >
        {row[column.accessor] === true ? f('yes') : f('no')}
      </td>
    )
  }

  if (column.accessor === 'type') {
    return (
      <td
        key={`td${rowIndex}_${column.accessor}`}
      >
        {row[column.accessor] === 1 ? 'Ordonnance' : 'Para'}
      </td>
    )
  }

  return (
    <td key={`td${rowIndex}_${column.accessor}`} style={{ minWidth: '10.5rem' }}>
      {row[column.accessor]}
    </td>
  )
}

export default PdfIncome
