import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import classnames from 'classnames'
import { history } from 'store'
import moment from 'moment'
import { useBarcode } from 'react-barcodes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router'
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Table } from 'reactstrap'

import API from 'utils/API'
import { alertError, alertSuccess } from 'utils/notifications'

import DetailUserForm from '../../components/DetailUserForm'
import SideMenu from '../../components/SideMenu'
import { useSelector } from 'react-redux'
import { RootState } from 'models/redux.model'

import { StyledContainer } from './UserManagementStyledComponents'
import { Helmet } from 'react-helmet'

const UserDetail = (props: any): React.ReactElement => {
  const [activeTab, setActiveTab] = useState('1')
  const toggle = (tab: any): void => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const { id }: any = useParams()

  const [user, setUser] = useState<any>(null)

  const [orders, setOrders] = useState<any>([])

  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma) // the current pharmacy selected

  useEffect(() => {
    const getUserDetail = async (userId: number): Promise<void> => {
      API.get(`mobile/admin/user/${selectedPharma.pharmacy_id}/detail/${userId}`)
        .then((res: any) => {
          setUser(res.data.data)
        }).catch(err => {
          console.log('err: ', err)
          if (err?.response?.status === 404) {
            alertError(f('errorGetPatient'))
          }
          if (err?.response?.status === 403) {
            alertError(f('errorGetPatient'))
            history.push('/manage')
          }
        })
    }

    const getHistoryOrder = async (): Promise<void> => {
      API.post('/mobile/admin/order/history', { user_id: id })
        .then((res: any) => {
          setOrders(res.data.data)
        }).catch(err => {
          console.log('err: ', err)
          if (err.status === 404) {
            alertError(f('errorGetHistoryPatient'))
          }
        })
    }

    getUserDetail(id).catch(err => console.log(err))
    getHistoryOrder().catch(err => console.log(err))
  }, [id])

  const updateUserHandle = async (fields: {
    firstname: string | any
    lastname: string | any
    email: string | any
    phone_number: string | any
    birthday: string | any
    notes: string | any
    address: string | any
    code: string | any
    pharmacy_id: number | any
  }): Promise<void> => {
    const body = {
      ...fields
    }

    try {
      body.pharmacy_id = selectedPharma.pharmacy_id
      API.put(`mobile/admin/user/update/${id}`, body)
        .then((response) => {
          alertSuccess(f('updateUser'))
          history.push('/manage')
        })
        .catch((e) => {
          if (e.response.status === 400) {
            alertError(f('pleaseCodeUpdate'))
          } else if (e.response.status === 409) {
            alertError(f('pleaseEmailUpdate'))
          }
        })
    } catch (e: any) {
      console.error(e)
    }
  }
  const { inputRef } = useBarcode({
    value: user?.user_cards[0]?.card_id,
    options: {
      background: '#FFFFFF'
    }
  })
  return (
    <StyledContainer>
      <Helmet>
        <title>{f('manageDetailUserMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('manageDetailUsertMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>

      <div className='UserMangagementWrapper dataContainer'>
        <div className='bodyWrapper'>
          <SideMenu parent='User' />
          <div className='containerWrapper'>
            <Container fluid>
              <Row className='headerTitle'>
                <Col>
                  <h2>{f('User Management')}</h2>
                  <p>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      size='lg'
                      className='back-button'
                      onClick={() => {
                        history.push('/manage')
                      }}
                    />
                    {f('User Detail')}
                  </p>
                </Col>
              </Row>
              <Container>
                <div className='tab-wrapper'>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggle('1')
                        }}
                      >
                        {f('customerInfo')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggle('2')
                        }}
                      >
                        {f('fidelityInfo')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId='1'>
                      <DetailUserForm onSubmitUpdate={updateUserHandle} userInfo={user} />
                    </TabPane>
                    <TabPane tabId='2'>
                      <Row className='info-top'>
                        <Col md={6}>
                          <div className='block-info'>
                            <img ref={inputRef} alt='barcode' />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='block-info'>
                            <Col>
                              <Row>
                                <div className='col-4'>{f('number')}</div>
                                <div className='col-6'>{user?.user_cards[0]?.card_id}</div>
                              </Row>
                              <Row>
                                <div className='col-4'>{f('points')}</div>
                                <div className='col-6'>{user?.user_cards[0]?.point != null ? user?.user_cards[0]?.point : 0}</div>
                              </Row>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <Row className='info-bottom'>
                        <h6>{f('purchaseHistory')}</h6>
                        <Table size='sm' className='main-table'>
                          <thead>
                            <tr>
                              <th className='text-center'>{f('Cart ID')}</th>
                              <th className='text-center'>{f('Product In Cart')}</th>
                              <th className='text-center'>{f('Total Price')}</th>
                              <th className='text-center'>{f('Use Coupon')}</th>
                              <th className='text-center'>{f('PointsUsed')}</th>
                              <th className='text-center'>{f('Date')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              orders?.map((item: any, loop: number) => {
                                return (
                                  <tr key={`orderfid${loop}`}>
                                    <td className='text-center'>{item.order_id}</td>
                                    <td className='text-center'>
                                      {
                                        item?.product_ids?.map((item1: any, index: number) => {
                                          return (
                                            <span key={`productfid${index}`}>
                                              {item1.product?.name}
                                              <br />
                                            </span>
                                          )
                                        })
                                      }
                                    </td>
                                    <td className='text-center'>{item.total_price}</td>
                                    <td className='text-center'>
                                      {item?.use_coupon != null ? f('yes') : f('no')}
                                    </td>
                                    <td className='text-center'>
                                      {item.current_point - item.last_point > 0 ? (
                                        <span className='increase'>
                                          {item.current_point - item.last_point}
                                        </span>
                                      ) : (
                                        <span className='decrease'>
                                          {item.last_point - item.current_point}
                                        </span>
                                      )}
                                    </td>
                                    <td className='text-center'>
                                      {moment(item.createdAt).format('DD-MM-YYYY')}
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </Container>
            </Container>
          </div>
        </div>
      </div>
    </StyledContainer>
  )
}

export default UserDetail
