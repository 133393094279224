import React, { useState, useEffect, useRef } from 'react'
import Button from '../../components/common/Button'
import InputMain from '../../components/common/InputMain'
import _ from 'lodash'

import { useIntl } from 'react-intl'

import { StyledContainer } from './OrderManagementStyledComponents'

const OrderManagemetStepOne = ({
  nextStepOne,
  onSubmitBarcode,
  error,
  dataCreateOrder
}) => {
  const inputRef = useRef(null)

  const [numberBarcode, setNumberBarcode] = useState('')

  const onHandleNumberBarcode = e => {
    setNumberBarcode(e.target.value)
  }

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  useEffect(() => {
    const handleKeyDown = () => {
      inputRef.current.focus()
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <StyledContainer>
      <div className='block-btn-main block-btn-main--end'>
        <Button
          onClick={nextStepOne}
          disabled={_.isUndefined(dataCreateOrder?.user_id)}
          className='btn-next'
          children={f('next')}
        />
      </div>
      <div className='block-form-add'>
        <form
          className='block-form-add-input-btn'
          onSubmit={async e => {
            e.preventDefault()
            await onSubmitBarcode(numberBarcode)
          }}
        >
          <InputMain
            onChange={onHandleNumberBarcode}
            className='input-main'
            label={f('userCode')}
            error={error}
            msg={error ? 'An error occurred' : ''}
            autofocus
            innerRef={inputRef}
          />
          <Button
            type='submit'
            className='btn-add btn-text-main'
            children={f('valid')}
          />
        </form>
      </div>
    </StyledContainer>
  )
}

export default OrderManagemetStepOne
