import moment from 'moment'
import { AppointmentState, Event } from '../models/appointment.model'

export const parseEvents = (data: AppointmentState[]) => {
  const tabEvents: Event[] = []

  for (const el of data) {
    const start_year: number = parseInt(
      moment(el.appointment_start).format('YYYY')
    )
    const start_month: number = parseInt(
      moment(el.appointment_start).format('MM')
    )
    const start_day: number = parseInt(
      moment(el.appointment_start).format('DD')
    )
    const start_hour: number = parseInt(
      moment(el.appointment_start).format('H')
    )
    const start_minutes: number = parseInt(
      moment(el.appointment_start).format('mm')
    )

    const end_year: number = parseInt(
      moment(el.appointment_end).format('YYYY')
    )
    const end_month: number = parseInt(moment(el.appointment_end).format('MM'))
    const end_day: number = parseInt(moment(el.appointment_end).format('DD'))
    const end_hour: number = parseInt(moment(el.appointment_end).format('H'))
    const end_minutes: number = parseInt(
      moment(el.appointment_end).format('mm')
    )

    const firstname: string | undefined = (el.user != null)
      ? (el.user.firstname as string)
      : ''
    const lastname: string | undefined = (el.user != null)
      ? (el.user.lastname as string)
      : ''

    // U can  customize your event
    const e: Event = {
      id: el.id,
      title: `${el.motif} ${firstname} ${lastname}`,
      allDay: false,
      start: new Date(
        start_year,
        start_month - 1,
        start_day,
        start_hour,
        start_minutes
      ), // care the month starts at 0 and ends at 11
      end: new Date(end_year, end_month - 1, end_day, end_hour, end_minutes), // care the month starts at 0 and ends at 11
      user: el.user,
      motif: el.motif
    }

    tabEvents.push(e)
  }

  return tabEvents
}
