import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../actions'

import { useIntl } from 'react-intl'
import API from 'utils/API'

import { Article } from 'models/LandingPage/articles.model'
import { RootState } from 'models/redux.model'

import AuthForm from '../components/LandingPage/Authentification/AuthForm'
import Articles from 'components/LandingPage/Articles'
import HowItWorks from 'components/LandingPage/HowItWorks'
import Partners from 'components/LandingPage/Partners'
import Rates from 'components/LandingPage/Rates'
import Questions from 'components/LandingPage/Questions'
import Footer from 'components/LandingPage/Footer'
import Header from 'components/LandingPage/Header'
import AuthFormMobile from 'components/LandingPage/Authentification/AuthFormMobile'
import Helmet from 'react-helmet'
import { AppWrapper } from 'assets/styles/AppWrapper'

const SectionWrapper = styled.section`
  padding-bottom: 11vh;
`

const LandingPage: React.FC = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated)
  const obj = {
    createdAt: '',
    id: '',
    img: '',
    img_older: '',
    link: '',
    title: '',
    updateAt: ''
  }

  const [articles, setArticles] = useState<Article[]>([obj])

  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
    setIsMobile(isMobile)
  })

  useEffect(() => {
    // if (isAuthenticated) history.push('/orders')
    getArticleImages().catch(err => console.log(err))
  }, [isAuthenticated])

  const getArticleImages = useCallback(async (): Promise<void> => {
    try {
      const res = await API.get('/article/images/fr')

      if (res.data.success === true) {
        setArticles(res.data.data)
      }
    } catch (err) {
    }
  }, [])

  const getPharmacyIdByToken = useCallback(async (token: string): Promise<number | undefined> => {
    try {
      const res = await API.get(`/pharmacyTokens/byToken/${token}`)
      if (res.data.success === true) {
        return res.data.pharmacy_id
      }
    } catch (err) {
    }
  }, [])

  const getPharmacistByPharmaId = useCallback(async (pharmacyId: number): Promise<any[] | undefined> => {
    try {
      const res = await API.get(`/pharmacist/all/bypharma/${pharmacyId}`)
      if (res.data.success === true) {
        return res.data.data
      }
    } catch (err) {
    }
  }, [])
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    if (token != null) {
      getPharmacyIdByToken(token).then(pharmacyId => {
        if (pharmacyId != null) {
          getPharmacistByPharmaId(pharmacyId).then(data => {
            if ((data != null) && data?.length > 0) {
              dispatch(login({ email: null, password: null, tokenPharmacy: token }, f))
            }
          }).catch(err => console.log(err))
        }
      }).catch(err => console.log(err))
    }
  }, [])

  return (
    <AppWrapper property='landingPage'>
      <Helmet>
        <title>{f('landingPageMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('landingPageMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <Header />

      {/* Authentification Section  */}
      <SectionWrapper id='authentification'>
        {isMobile ? <AuthFormMobile onLogin={credentials => dispatch(login(credentials, f))} />
          : <AuthForm onLogin={credentials => dispatch(login(credentials, f))} />}
      </SectionWrapper>

      {/* Creativity Section  1 */}
      {/* <SectionWrapper>
      </SectionWrapper> */}

      {/* Articles Section 2 */}
      <SectionWrapper id='articles'>
        <Articles data={articles} />
      </SectionWrapper>

      {/* Work Section 3 */}
      <SectionWrapper id='work'>
        <HowItWorks />
      </SectionWrapper>

      {/* Partners Section 4 */}
      <SectionWrapper id='partners'>
        <Partners />
      </SectionWrapper>

      {/* Rates Section 5 */}
      <SectionWrapper id='rates' style={{ paddingTop: 'unset' }}>
        <Rates />
      </SectionWrapper>

      {/* Questions Section 6 */}
      <SectionWrapper id='questions'>
        <Questions />
      </SectionWrapper>

      {/* Footer Section 7 */}
      <SectionWrapper id='footer' style={{ backgroundColor: '#202942', paddingBottom: 'unset', paddingTop: '11vh' }}>
        <Footer />
      </SectionWrapper>

    </AppWrapper>
  )
}

export default LandingPage
