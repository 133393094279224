import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Row, Col } from 'reactstrap'
import * as Yup from 'yup'
import { UpdatableUser } from 'models/user.model'

import styled from 'styled-components'
import { useIntl } from 'react-intl'

const FormWrapper = styled(Form)`
  color: var(--texte-table);
  font-weight: 600;
  font-size: 1rem;
  input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }
`
interface UserFormProps {
  onSubmitUpdate: (fields: {
    email: string | null | undefined
    firstname: string | null | undefined
    lastname: string | null | undefined
    phone_number: string | null | undefined
  }) => any
  userInfo: UpdatableUser
}

const UserForm: React.FC<UserFormProps> = ({ onSubmitUpdate, userInfo }) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  return (
    <Formik
      initialValues={{
        email: userInfo.email,
        firstname: userInfo.firstname,
        lastname: userInfo.lastname,
        phone_number: userInfo.phone_number
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(f('validEmail'))
          .required(f('pleaseEmail')),
        firstname: Yup.string().required(f('pleaseFirstName')),
        lastname: Yup.string().required(f('pleaseLastName')),
        phone_number: Yup.number().required(f('pleasePhone')).typeError(f('invalidPhone'))

      })}
      onSubmit={fields => onSubmitUpdate(fields)}
    >
      {({ errors, status, touched }) => (
        <FormWrapper>
          <Row>
            <Col xs='12' sm='6'>
              <div className='form-group'>
                <label htmlFor='firstname'>{f('firstName')} :</label>
                <Field
                  name='firstname'
                  type='firstname'
                  className={
                    'form-control' +
                    (errors.firstname && touched.firstname ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage
                  name='firstname'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
            </Col>
            <Col xs='12' sm='6'>
              <div className='form-group'>
                <label htmlFor='lastname'>{f('lastName')} :</label>
                <Field
                  name='lastname'
                  type='lastname'
                  className={
                    'form-control' +
                    (errors.lastname && touched.lastname ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage
                  name='lastname'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
            </Col>
          </Row>
          <div className='form-group'>
            <label htmlFor='email'>{f('mail')} :</label>
            <Field
              name='email'
              type='text'
              className={
                'form-control' +
                (errors.email && touched.email ? ' is-invalid' : '')
              }
            />
            <ErrorMessage
              name='email'
              component='div'
              className='invalid-feedback'
            />
          </div>
          <Row>
            <Col xs='6' sm='3'>
              <div className='form-group'>
                <label htmlFor='phone_number'>{f('phone')} :</label>
                <Field
                  name='phone_number'
                  type='phone_number'
                  className={
                    'form-control' +
                    (errors.phone_number && touched.phone_number
                      ? ' is-invalid'
                      : '')
                  }
                />
                <ErrorMessage
                  name='phone_number'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
            </Col>
          </Row>
          <div className='form-group'>
            <button type='submit' className='btn btnPrimary mr-2'>
              {f('save')}
            </button>
            {/* <button type='reset' className='btn btnSecondary'>
              {f('cancel')}
                </button> */}
          </div>
        </FormWrapper>
      )}
    </Formik>
  )
}

export default UserForm
