import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import { XCircle as CrossIcon } from 'react-feather';
import {
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Navbar,
  NavbarToggler
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserCircle,
  faChartPie,
  faTable,
  faCalendarDay,
  faQrcode,
  faClock,
  faFileInvoice,
  faPhoneAlt,
  faBell
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

// import { verifyStripeStatus } from '../actions'

import { history } from '../store'
import { useIntl } from 'react-intl'
import { RootState } from 'models/redux.model'

interface MenuProps {
  toggleMenu?: () => any
  show?: boolean
  parent: string
}

const Menu: React.FC<MenuProps> = ({ toggleMenu, show, parent }) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const isMultipharmaAccount = useSelector((state: RootState) => state.user.isMultipharmaAccount)

  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )

  const [isOpen1, setIsOpen1] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [menuClicked] = useState<number>(
    history.location.pathname === '/orders'
      ? 1
      : history.location.pathname === '/stock'
        ? 2
        : 3
  )
  const toggle1 = () => {
    setIsOpen1(!isOpen1)
    history.push('/orders', { isComeFromMenu: true })
  }

  const toggle2 = () => {
    setIsOpen2(!isOpen2)
  }

  const [collapsed, setCollapsed] = useState(false)

  const toggleNavbar = () => setCollapsed(!collapsed)

  return (
    <MenuWrapper>
      {/* <MenuToggle onClick={() => toggleMenu(true)} show={show} >>></MenuToggle> */}
      <Navbar color='faded' light>
        <h4 className='d-xl-none d-md-none'>{parent}</h4>
        <NavbarToggler onClick={toggleNavbar} className='mr-2' />

        <Collapse isOpen={!collapsed} navbar>
          <div className='menuHeader'>
            <h5>{f('menu')}</h5>
          </div>
          <NavWrapper vertical>
            {(selectedPharma.pharmacy.pharmacy_menu?.orders !== 2) &&
              <NavItem>
                <NavLink
                  href='#'
                  onClick={toggle1}
                  className={
                    history.location.pathname === '/orders'
                      ? 'nav-link mainLink subMenuActif'
                      : 'nav-link'
                  }
                >
                  <FontAwesomeIcon icon={faChartPie} />
                  {f('orders')}
                </NavLink>
              </NavItem>}
            {(selectedPharma.pharmacy.pharmacy_menu?.stock !== 2) &&
              <NavItem>
                <NavLink
                  href='#'
                  onClick={toggle2}
                  className={
                    isOpen2
                      ? 'mainLink subMenuActif menuActif hasSubMenu'
                      : menuClicked === 2
                        ? 'mainLink subMenuActif hasSubMenu actif'
                        : 'mainLink hasSubMenu'
                  }
                >
                  <FontAwesomeIcon icon={faTable} />
                  {f('stockManagement')}
                </NavLink>
                <Collapse
                  id='submenu-2'
                  isOpen={isOpen2}
                  className={isOpen2 ? 'subMenuActif submenu' : 'submenu'}
                >
                  <Nav vertical>
                    <NavItem>
                      <span
                        className='nav-link'
                        onClick={() => {
                          toggle2()
                          history.push('/stock')
                        }}
                      >
                        {f('myStockManagement')}
                      </span>
                    </NavItem>
                    {(selectedPharma.pharmacy.pharmacy_menu?.csv_import !== 2) &&
                      <NavItem>
                        <span
                          className={
                            history.location.pathname === '/import-stock'
                              ? 'nav-link actif'
                              : 'nav-link'
                          }
                          onClick={() => {
                            history.push('/import-stock')
                          }}
                        >
                          {f('importCSV')}
                        </span>
                      </NavItem>}

                  </Nav>
                </Collapse>
              </NavItem>}
            {(selectedPharma.pharmacy.pharmacy_menu?.calendar !== 2) &&
              <NavItem>
                <span
                  className={
                    history.location.pathname === '/calendrier'
                      ? 'nav-link mainLink subMenuActif'
                      : 'nav-link'
                  }
                  onClick={() => {
                    history.push('/calendrier')
                  }}
                >
                  <FontAwesomeIcon icon={faCalendarDay} />
                  {f('calendar')}
                </span>
              </NavItem>}

            <NavItem>
              <span
                className={
                  history.location.pathname === '/schedule'
                    ? 'nav-link mainLink subMenuActif'
                    : 'nav-link'
                }
                onClick={() => {
                  history.push('/schedule')
                }}
              >
                <FontAwesomeIcon icon={faClock} />
                {f('schedule')}
              </span>
            </NavItem>
            {/* <NavItem>
                <span
                    className={
                      history.location.pathname === "/messages"
                          ? "nav-link mainLink subMenuActif"
                          : "nav-link"
                    }
                    onClick={() => {
                      history.push("/messages");
                    }}
                >
                  <FontAwesomeIcon icon={faEnvelopeOpen} />
                  Messages
                </span>
              </NavItem> */}
            {/* <NavItem>
              <NavLink
                onClick={() => dispatch(verifyStripeStatus())}
                className='mainLink'
              >
                <FontAwesomeIcon icon={faUserCircle} />
                {f('verifyAccount')}
              </NavLink>
            </NavItem> */}
            <NavItem>
              <span
                className={
                  history.location.pathname === '/qrcodes'
                    ? 'nav-link mainLink subMenuActif'
                    : 'nav-link'
                }
                onClick={() => {
                  history.push('/qrcodes')
                }}
              >
                <FontAwesomeIcon icon={faQrcode} />
                {f('qrCode')}
              </span>
            </NavItem>
            {selectedPharma.pharmacy.fidelity && (
              <NavItem>
                <span
                  className={
                    history.location.pathname === '/manage'
                      ? 'nav-link mainLink subMenuActif'
                      : 'nav-link'
                  }
                  onClick={() => {
                    history.push('/manage')
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} />
                  {f('userManagement')}
                </span>
              </NavItem>
            )}
            {selectedPharma.pharmacy.fidelity && (
              <NavItem>
                <span
                  className={
                    history.location.pathname === '/order-management'
                      ? 'nav-link mainLink subMenuActif'
                      : 'nav-link'
                  }
                  onClick={() => {
                    history.push('/order-management')
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} />
                  {f('orderManagement')}
                </span>
              </NavItem>
            )}
            <NavItem>
              <span
                className={
                  history.location.pathname === '/revenus'
                    ? 'nav-link mainLink subMenuActif'
                    : 'nav-link'
                }
                onClick={() => {
                  history.push('/revenus')
                }}
              >
                <FontAwesomeIcon icon={faFileInvoice} />
                {f('revenus')}
              </span>
            </NavItem>
            {selectedPharma.pharmacy.standard && <NavItem>
              <span
                className={
                  history.location.pathname.includes('/voicemail')
                    ? 'nav-link mainLink subMenuActif'
                    : 'nav-link'
                }
                onClick={() => {
                  history.push('/voicemail')
                }}
              >
                <FontAwesomeIcon icon={faPhoneAlt} />
                {f('voicemail')}
              </span>
                                                 </NavItem>}

            {isMultipharmaAccount === true && (
              <>
                <NavItem>
                  <span
                    className={
                      history.location.pathname === '/notifications'
                        ? 'nav-link mainLink subMenuActif'
                        : 'nav-link'
                    }
                    onClick={() => {
                      history.push('/notifications')
                    }}
                  >
                    <FontAwesomeIcon icon={faBell} />
                    {f('notifications')}
                  </span>
                </NavItem>
                <NavItem>
                  <span
                    className={
                      history.location.pathname === '/kpis'
                        ? 'nav-link mainLink subMenuActif'
                        : 'nav-link'
                    }
                    onClick={() => {
                      history.push('/kpis')
                    }}
                  >
                    <FontAwesomeIcon icon={faTable} />
                    KPIs
                  </span>
                </NavItem>
              </>

            )}

          </NavWrapper>
        </Collapse>
      </Navbar>
    </MenuWrapper>
  )
}

const MenuWrapper = styled.div`
  height: 100%;
  position: fixed;
  left: 0;
  width: 230px;
  color: var(--texte-side-menu);
  background-color: white;
  padding: 1rem 0;
  z-index: 900;
  overflow-x: hidden;
 
  @media (max-width: 960px) {
    padding: 1rem 0;
  }
  @media (max-width: 769px) {
    width: 100%;
    position: relative;
  }
  .menuHeader {
    color: var(--texte-MENU-side-menu);
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    h5 {
      font-size: 0.9rem;
    }
  }
  div {
    width: 100%;
    font-weight: bold;
  }
  .mainLink {
    padding: 0.8rem 1rem;
  }

  // Ajout Raph new sideMenu façon maquette

  h4 {
    color: white;
  }
  .navbar-toggler {
    background-color: white;
  }
  @media (min-width: 769px) {
    .navbar .navbar-toggler {
      display: none;
      background-color: red;
    }
    .navbar-collapse {
      display: block !important;
    }
  }
`

const NavWrapper = styled(Nav)`
  color: var(--liens-side-menu);
  .hasSubMenu::after {
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    right: 30px;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    transition: transform 0.35s ease, opacity 0.35s ease;
    opacity: 1;
  }

  a,
  li {
    font-size: 0.9rem;
    color: var(--liens-side-menu);
    font-weight: normal;
  }
  a:hover,
  span:hover {
    filter: brightness(80%);
    svg,
    ::after {
      color: var(--liens-side-menu);
    }
  }
  .actif {
    color: white;
    svg,
    ::after {
      color: var(--liens-side-menu);
    }
  }
  .nav-link {
    font-family: 'Circular Std Book', -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 13px;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease;
    cursor: pointer;
  }
  svg {
    margin-right: 0.5rem;
    margin-bottom: 4px;
  }
  .menuActif {
    color: white;
    svg,
    ::after {
      color: var(--liens-side-menu);
    }
  }
  .submenu {
    padding: 0 0.5rem;
    span.nav-link {
      padding-left: 1.2rem;
    }
  }
  .menuActif::after {
    transform: rotate(90deg);
    transition: transform 0.35s ease, opacity 0.35s ease;
    opacity: 1;
  }
  .subMenuActif {
    color: #007bff;
    font-weight: 600;
  }
`

export default Menu
