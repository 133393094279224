import React from 'react'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Row, Col } from 'reactstrap'
import * as Yup from 'yup'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { UpdatableUser } from 'models/user.model'
import API from 'utils/API'
import {
  alertError,
  alertLoading,
  alertSuccessModifCompte
} from 'utils/notifications'

const FormWrapper = styled(Form)`
  color: var(--texte-table);
  font-weight: 600;
  font-size: 1rem;
  input {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }
`

type UpdateFunction = (fields: {
  street: string | null | undefined
  city: string | null | undefined
  postal_code: string | null | undefined
  phone: string | null | undefined
}) => any

interface PharmaFormPorps {
  userInfo: UpdatableUser
  onSubmitUpdate: (userData: UpdatableUser, formatMessage: (id: any) => string) => any
}

const PharmacyForm = ({ userInfo, onSubmitUpdate }: PharmaFormPorps) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const handleUpdatePharmacy: UpdateFunction = async fields => {
    alertLoading(f('pharmacyModif'))

    if (userInfo.pharmacies == null) {
      alertError(f('errorUpdatePharmacy'))
      return
    }

    try {
      const res = await API.put(
        `/pharmacies/${userInfo.pharmacies[0].pharmacy_id}`,
        fields
      )

      if (res.data.success) {
        alertSuccessModifCompte(f('pharmacyUpdated'))
        userInfo.pharmacies[0].pharmacy.city = fields.city ?? userInfo.pharmacies[0].pharmacy.city
        userInfo.pharmacies[0].pharmacy.street = fields.street ?? userInfo.pharmacies[0].pharmacy.street
        userInfo.pharmacies[0].pharmacy.postal_code = fields.postal_code ?? userInfo.pharmacies[0].pharmacy.postal_code
        userInfo.pharmacies[0].pharmacy.phone = fields.phone ?? userInfo.pharmacies[0].pharmacy.phone
        onSubmitUpdate(userInfo, f)
      } else alertError(f('errorUpdatePharmacy'))
    } catch (err: any) {
      console.log(err)
      alertError(f('errorUpdatePharmacy'))
    }
  }

  return (
    <Formik
      initialValues={{
        street:
          userInfo.pharmacies != null
            ? userInfo.pharmacies[0].pharmacy.street
            : null,
        city:
          userInfo.pharmacies != null
            ? userInfo.pharmacies[0].pharmacy.city
            : null,
        postal_code:
          userInfo.pharmacies != null
            ? userInfo.pharmacies[0].pharmacy.postal_code
            : null,
        phone:
          userInfo.pharmacies != null
            ? userInfo.pharmacies[0].pharmacy.phone
            : null
      }}
      validationSchema={Yup.object().shape({
        street: Yup.string().required(f('pleasePharmaAddress')),
        city: Yup.string().required(f('pleaseCity')),
        postal_code: Yup.number().required(f('pleasePostalCode')).typeError(f('invalidPostalCode')),
        phone: Yup.number().required(f('pleasePhone')).typeError(f('invalidPhone'))

      })}
      onSubmit={fields => handleUpdatePharmacy(fields)}
    >
      {({ errors, status, touched }) => (
        <FormWrapper>
          <div className='form-group'>
            <label htmlFor='street'>{f('pharmaAddress')}</label>
            <Field
              name='street'
              type='street'
              className={
                'form-control' +
                (errors.street && touched.street ? ' is-invalid' : '')
              }
            />
            <ErrorMessage
              name='street'
              component='div'
              className='invalid-feedback'
            />
          </div>
          <Row>
            <Col xs='12' sm='4'>
              <div className='form-group'>
                <label htmlFor='city'>{f('city')}</label>
                <Field
                  name='city'
                  type='city'
                  className={
                    'form-control' +
                    (errors.city && touched.city ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage
                  name='city'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
            </Col>
            <Col xs='12' sm='4'>
              <div className='form-group'>
                <label htmlFor='postal_code'>{f('postalCode')}</label>
                <Field
                  name='postal_code'
                  type='code_postal'
                  className={
                    'form-control' +
                    (errors.postal_code && touched.postal_code
                      ? ' is-invalid'
                      : '')
                  }
                />
                <ErrorMessage
                  name='postal_code'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
            </Col>
            <Col xs='12' sm='4'>
              <div className='form-group'>
                <label htmlFor='phone'>{f('phone')}</label>
                <Field
                  name='phone'
                  type='phone'
                  className={
                    'form-control' +
                    (errors.phone && touched.phone
                      ? ' is-invalid'
                      : '')
                  }
                />
                <ErrorMessage
                  name='phone'
                  component='div'
                  className='invalid-feedback'
                />
              </div>
            </Col>
          </Row>
          <div className='form-group'>
            <button type='submit' className='btn btnPrimary mr-2'>
              {f('save')}
            </button>
            {/* <button type='reset' className='btn btnSecondary'>
              {f('cancel')}
                </button> */}
          </div>
        </FormWrapper>
      )}
    </Formik>
  )
}

export default PharmacyForm
