import { CompanyContainer, CopyRight, FooterContainer, NewsLetterContainer } from 'assets/styles/landingPage/Footer'
import React from 'react'

import { Col, Container, Row } from 'reactstrap'
import PhacilImg from '../../assets/images/landingPage/logo-Phacil-115.png'
import { useIntl } from 'react-intl'

const Footer: React.FC<{}> = () => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  return (
    <>
      <div className='container'>
        <div className='row'>
          <Col md='12'>
            <FooterContainer className='footer-py-60'>
              <div className='row'>
                <div className='col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2'>
                  <a href='#' className='logo-footer'>
                    <img src={PhacilImg} height='34' alt='logo' />
                  </a>
                  <p className='mt-4'>{f('footerTitle')}</p>
                  <ul className='list-unstyled social-icon foot-social-icon mb-0 mt-4'>
                    <li className='list-inline-item'><a href='https://www.facebook.com/MaViePhacil/' target='_blank' className='rounded' rel='noreferrer'><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' className='feather feather-facebook fea icon-sm fea-social'><path d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z' /></svg></a></li>
                    <li className='list-inline-item'><a href='https://www.instagram.com/phacil.delivery/' target='_blank' className='rounded' rel='noreferrer'><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' className='feather feather-instagram fea icon-sm fea-social'><rect x='2' y='2' width='20' height='20' rx='5' ry='5' /><path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z' /><line x1='17.5' y1='6.5' x2='17.51' y2='6.5' /></svg></a></li>
                    <li className='list-inline-item'><a href='https://www.linkedin.com/company/phacil/' target='_blank' className='rounded' rel='noreferrer'><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' className='feather feather-linkedin fea icon-sm fea-social'><path d='M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z' /><rect x='2' y='9' width='4' height='12' /><circle cx='4' cy='4' r='2' /></svg></a></li>
                  </ul>
                </div>

                <CompanyContainer className='col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0'>
                  <h5 className='footer-head'>{f('footerCat1')}</h5>
                  <ul className='list-unstyled footer-list mt-4'>
                    <li><a href='#work' rel='noreferrer'>{f('footerItem1')}</a></li>
                    <li><a href='https://phacil.delivery' target='_blank' rel='noreferrer'>{f('footerItem2')}</a></li>
                    <li><a href='#rates'> {f('footerItem3')}</a></li>
                    <li><a href='https://phacil.delivery/recruitment' target='_blank' rel='noreferrer'>{f('footerItem4')}</a></li>
                    <li><a href='https://phacil.delivery/faq' target='_blank' rel='noreferrer'>{f('footerItem5')}</a></li>

                  </ul>
                </CompanyContainer>

                <CompanyContainer className='col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0'>
                  <h5 className='footer-head'>{f('footerCat2')}</h5>
                  <ul className='list-unstyled footer-list mt-4'>
                    <li><a href='https://phacil.delivery/confidentiality' target='_blank' rel='noreferrer'>{f('footerItem6')}</a></li>
                    {/* <li><a href='https://phacil.delivery/privacy' target='_blank'>Politique de confidentialité</a></li> */}
                    <li><a href='https://phacil.delivery/cgu' target='_blank' rel='noreferrer'> {f('footerItem7')}</a></li>
                  </ul>
                </CompanyContainer>

                <NewsLetterContainer className='col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0'>
                  <h5 className='footer-head'>{f('footerCat3')}</h5>
                  <p className='mt-4'>{f('footerItem8')}</p>
                  <form action='https://phacil.delivery/pharmacies' target='_blank'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='d-grid'>
                          <input type='submit' id='submitsubscribe' name='send' className='btn btn-soft-primary' value={f('signUp')} />
                        </div>
                      </div>
                    </div>
                  </form>
                </NewsLetterContainer>
              </div>
            </FooterContainer>
          </Col>
        </div>
      </div>

      <div style={{ borderTop: '1px solid #283453' }}>
        <div className='container'>
          <CopyRight className='footer-py-30 footer-bar'>
            <div className='row align-items-center'>
              <div className='col-sm-6'>
                <div className='text-sm-start'>
                  <p className='mb-0'>© <script>document.write(new Date().getFullYear())</script>2022 Phacil</p>
                </div>
              </div>

            </div>
          </CopyRight>
        </div>
      </div>

    </>
  )
}
export default Footer
