import React from 'react'
import cogoToast from 'cogo-toast'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import { X } from 'react-feather'

const CloseIcon = styled(X)`
  width: 15px;
  margin-right: 5px;
  cursor: pointer;
`

const options = {
  hideAfter: 3.5,
  position: 'bottom-right'
}

export const alertSuccess = message => {
  const { hide } = cogoToast.success(
    <Container>
      <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <Col xs='10'>{message}</Col>
        <Col xs='2' style={{ textAlign: 'right' }}>
          <CloseIcon onClick={() => hide()} />
        </Col>
      </Row>
    </Container>,
    options
  )
}

export const alertInfo = message => {
  const { hide } = cogoToast.info(
    <Container>
      <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <Col xs='10'>{message}</Col>
        <Col xs='2' style={{ textAlign: 'right' }}>
          <CloseIcon onClick={() => hide()} />
        </Col>
      </Row>
    </Container>,
    options
  )
}

export const alertLoading = message => {
  const { hide } = cogoToast.loading(
    <Container>
      <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <Col xs='10'>{message}</Col>
        <Col xs='2' style={{ textAlign: 'right' }}>
          <CloseIcon onClick={() => hide()} />
        </Col>
      </Row>
    </Container>,
    options
  )
}

export const alertWarn = message => {
  const { hide } = cogoToast.warn(
    <Container>
      <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <Col xs='10'>{message}</Col>
        <Col xs='2' style={{ textAlign: 'right' }}>
          <CloseIcon onClick={() => hide()} />
        </Col>
      </Row>
    </Container>,
    options
  )
}

export const alertError = message => {
  const { hide } = cogoToast.error(
    <Container>
      <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <Col xs='10'>{message}</Col>
        <Col xs='2' style={{ textAlign: 'right' }}>
          <CloseIcon onClick={() => hide()} />
        </Col>
      </Row>
    </Container>,
    {
      hideAfter: 3.5,
      role: 'alertError',
      position: 'bottom-right'
    }
  )
}

export const alertSuccessConnexionCompte = message => {
  const { hide } = cogoToast.success(
    <Container>
      <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <Col xs='10'>{message}</Col>
        <Col xs='2' style={{ textAlign: 'right' }}>
          <CloseIcon onClick={() => hide()} />
        </Col>
      </Row>
    </Container>,
    {
      hideAfter: 3.5,
      position: 'bottom-right',
      role: 'connexionSuccess',
      onClick: () => {
        hide()
      }
    }
  )
}

export const alertSuccessModifCompte = message => {
  const { hide } = cogoToast.success(
    <Container>
      <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <Col xs='10'>{message}</Col>
        <Col xs='2' style={{ textAlign: 'right' }}>
          <CloseIcon onClick={() => hide()} />
        </Col>
      </Row>
    </Container>,
    {
      hideAfter: 3.5,
      position: 'bottom-right',
      role: 'alertSuccessModifCompte',
      onClick: () => {
        hide()
      }
    }
  )
}
