import styled from 'styled-components'
import { Table } from 'reactstrap'

const StyledTable = styled(Table)`
  width: 100%;
  thead {
    font-size: 0.9rem;
    color: var(--texte-bleu-fonce);
    th div {
      display: flex;
      align-items: center;
    }
  }

  tbody,
  tbody,input, .textDetail {
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
  }

  tr {
    width: 100%;
    div {
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      svg {
        width: 1rem;
        margin-left: 1rem;
      }
    }
  }

  td {
    vertical-align: middle;
    padding-left: 1rem !important ;
    &:focus-visible {
      background: white;
    }
  }
  tbody {
    overflow: scroll;
    max-height: 40rem;
    button {
      margin: 0.5rem 0;
    }
    svg {
      width: 1rem;
    }
  }
 .audio {
   height: 2rem;
   width: 20rem;
   
 } 

th  {
  border-top: none !important;
  border-bottom: none !important;
  padding-bottom: 0.5rem;
  padding-left: 1rem !important ;

}

.spaceDate {
  padding-left: unset !important ;
  min-width: 8.6rem;
}

.spaceCheckbox {
    text-align: center;
    padding-left: 1rem !important ;
}

.icon {
  margin: unset !important
}
`

export { StyledTable }
