import styled from 'styled-components'
import { stylesLandingPage } from '../../../constants/styles.constants'

export const StyledContainer = styled.div`
@media (max-width: 769px){
  h1{
      font-size: 30px !important;
  }
  h2{
    font-size: 24px !important;
  }

}

  margin-left: auto;
  max-width: 600px;
  margin-top: 2rem;
  

    h1{
        color: #161c2d
    }

  .heading:{
    font-size: 45px!important;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }

  .para-desc{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  p{
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  img{
    vertical-align: middle
  }
  span{
    color: ${stylesLandingPage.color_phacil} !important;
  }

  .btn{
    padding: 8px 20px !important;
    outline: 0 !important;
    text-decoration: none !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    color: white;
    margin-right: 1rem;
}    

.btn-outline-primary{
  color: ${stylesLandingPage.color_phacil} !important;
  border-color: ${stylesLandingPage.color_phacil} !important;

}


.btn:hover{
    color: white !important;
    background-color: #4631e2
}

`

export const ButtonContainer = styled.div`

@media (max-width: 768px){
  text-align: center; 
}
`
export const formContainer = styled.div`

`
