import React, { useState } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap'

import ModalProduits from './ModalProduits'
import ModalOrdonnance from './ModalOrdonnance'
import styled from 'styled-components'

const ModalCarousel = (props: any) => {
  // Carousel products/ordonnance
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === 1 ? 0 : 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? 1 : 0
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: number) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      {[
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={0}
        >
          <ModalProduits products={props.products} />
        </CarouselItem>,
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={1}
        >
          <ModalOrdonnance />
        </CarouselItem>
      ]}

      <CarouselControlStyled
        direction='prev'
        directionText='Previous'
        onClickHandler={previous}
      />
      <CarouselControlStyled
        direction='next'
        directionText='Next'
        onClickHandler={next}
      />
    </Carousel>
  )
}

const CarouselControlStyled = styled(CarouselControl)`
  background-color: red;
`

export default ModalCarousel
