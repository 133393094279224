import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col, Button } from 'reactstrap'
import { PlusCircle as AddIcon } from 'react-feather'
import styled from 'styled-components'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import API from '../utils/API'

import SideMenu from '../components/SideMenu'
import Breadcrum from '../components/ui/Breadcrum'
import { RootState } from 'models/redux.model'
import { Event, Messages, AvailableDate } from 'models/appointment.model'
import { parseEvents } from 'utils/eventsHandler'

import CalendarModal from '../components/CalendarModal'
import BookedModal from '../components/CalendarModal/BookedModal'
import BookedPatientModal from '../components/CalendarModal/BookedPatientModal'
import BlockModal from '../components/CalendarModal/BlockModal'

import SelectPharma from '../components/SelectPharma'
import { useIntl } from 'react-intl'

import socket from 'utils/socket'
import InfosViewDisabledModal from 'components/InfosViewDisabledModal'
import { Helmet } from 'react-helmet'
import { StockWrapper } from 'assets/styles/StockStyledComponents'
import { CardWrapper } from 'assets/styles/CardWrapper'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'
const blurCalendar = 'images/blurCalendar.png'

const Appointment: React.FC = () => {
  // Pharmacy list from Redux
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)
  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const [events, setEvents] = useState<Event[]>([])
  const localizer = momentLocalizer(moment)

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenBookedPatient, setIsOpenBookedPatient] = useState(false)
  const [clickButtonRdv, setClickButtonRdv] = useState(false)
  const [OpenBookedModal, setOpenBookedModal] = useState(false)
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false)

  const [selectedEvent, setSelectedEvent] = useState<Event>({})
  const [slotInfoDate, setSlotInfoDate] = useState<string | Date>()
  const [roomBookedModal] = useState('blocage créneaux')
  const [roomPatientModal] = useState('rdv')
  const [availableDates, setAvailableDates] = useState<AvailableDate[]>([])
  const minTime = new Date()
  minTime.setHours(8, 0, 0)

  const maxTime = new Date()
  maxTime.setHours(20, 0, 0)

  const [openModalDisabled, setOpenModalDisabled] = useState(true)
  const toggle = () => setOpenModalDisabled(!openModalDisabled)
  const isMultipharmaAccount = useSelector((state: RootState) => state.user.isMultipharmaAccount)

  /** CRUD FUNCTIONS */
  const getData = async (pharmaId: number) => {
    try {
      if (pharmaId == null && selectedPharma != null) { pharmaId = selectedPharma.pharmacy_id }

      const response = await API.get(`/appointment/${pharmaId}`)

      const events = parseEvents(response.data.appointments)
      setEvents(events)
    } catch (e: any) {
      console.log(e)
    }
  }

  const getAvailableDateAppointment = () => {
    API.post('unlockedAppointment/getAvailableDateAppointment', {
      pharmacy_id: selectedPharma.pharmacy_id
    })
      .then((res) => {
        if (res.data.success) {
          setAvailableDates(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (!selectedPharma) return
    getData(selectedPharma.pharmacy_id)
    getAvailableDateAppointment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPharma])

  // Socket discnonnection when the component is unMount
  useEffect(() => {
    return () => {
      socket.emit('quit_room', roomBookedModal)
      socket.emit('quit_room', roomPatientModal)
      socket.disconnect()
    }
  }, [])

  const messages: Messages = {
    allDay: f('day'),
    previous: f('before'),
    next: f('next'),
    today: f('today'),
    month: f('month'),
    week: f('week'),
    day: f('day2'),
    agenda: f('agenda'),
    date: f('date2'),
    time: f('time'),
    event: f('event') // Or anything you want
  }

  // Custom style Calendar
  const calendarStyle = (date: Date) => {
    let available = false
    let backgroundColor = '#fff'
    const momentDate: string = moment(date).format('YYYY-MM-DD')
    for (const availableDate of availableDates) {
      const startDate: string = moment(availableDate.start_date).format('YYYY-MM-DD')
      const endDate: string = moment(availableDate.end_date).format('YYYY-MM-DD')
      if (momentDate >= startDate && momentDate <= endDate) {
        available = true
      }
    }
    if (!available) {
      backgroundColor = '#E6E6E6' // color disable
    } else {
      if (momentDate === moment(new Date()).format('YYYY-MM-DD')) {
        backgroundColor = '#eaf6ff' // current date color if available
      }
    }
    return {
      style: {
        backgroundColor
      }
    }
  }

  // Define if the cell can be clickable
  const isClickable = (date: Date) => {
    let isClickable = false
    const now = moment(date).format('YYYY-MM-DD')
    for (const availableDate of availableDates) {
      const startDate: string = moment(availableDate.start_date).format('YYYY-MM-DD')
      const endDate: string = moment(availableDate.end_date).format('YYYY-MM-DD')
      if (now >= startDate && now <= endDate) { isClickable = true }
    }
    return isClickable
  }

  return (
    <>
      <StockWrapper property='appointment' className='dataContainer'>
        <Helmet>
          <title>{f('appointmentMetaTitle')}</title>
          <link rel='canonical' href='https://www.phacil.app/' />
          <meta name='description' content={f('appointmentMetaDescription')} />
          <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
        </Helmet>
        <SideMenu parent='Mon stock' />
        <CalendarModal
          roomBookedModal={roomBookedModal}
          roomPatientModal={roomPatientModal}
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          data={selectedEvent}
          backdrop
          pharmaId={selectedPharma && selectedPharma.pharmacy_id}
          getData={() => { selectedPharma != null && getData(selectedPharma.pharmacy_id) }}
        />
        {selectedPharma != null && (
          <BookedModal
            roomBookedModal={roomBookedModal}
            isOpen={OpenBookedModal}
            closeModal={() => setOpenBookedModal(false)}
            backdrop
            pharmaId={selectedPharma.pharmacy_id}
            getAvailableDateAppointment={() =>
              selectedPharma && getAvailableDateAppointment()}
          />
        )}

        {selectedPharma && (
          <BookedPatientModal
            roomPatientModal={roomPatientModal}
            isOpen={isOpenBookedPatient}
            closeModal={() => setIsOpenBookedPatient(false)}
            backdrop
            pharmaId={selectedPharma.pharmacy_id}
            pharmacyName={selectedPharma.pharmacy.name}
            getData={async () => { selectedPharma !== null && getData(selectedPharma.pharmacy_id) }}
            slotInfoDate={clickButtonRdv ? null : slotInfoDate}
          />
        )}

        {selectedPharma && (
          <BlockModal
            isOpen={isOpenBlockModal}
            closeModal={() => setIsOpenBlockModal(false)}
            backdrop
            pharmaId={selectedPharma.pharmacy_id}
            getAvailableDateAppointment={() =>
              selectedPharma && getAvailableDateAppointment()}
          />
        )}

        <ContainerWrapper>
          <Container fluid className={(selectedPharma && selectedPharma.pharmacy.pharmacy_menu?.calendar === 1 && !isMultipharmaAccount) ? 'blur' : ''}>
            {(selectedPharma && selectedPharma.pharmacy.pharmacy_menu?.calendar === 1 && !isMultipharmaAccount) ? <img src={blurCalendar} alt='' />
              : <>
                <Row className='headerTitle'>
                  <Col>
                    <h2>{f('event2')}</h2>
                    <Breadcrum />
                  </Col>
                </Row>
                {selectedPharma ? (
                  <Row className='bodyHeader'>
                    <Col xs='auto'>
                      <SelectPharma pharmacies={pharmacies} />
                    </Col>
                    <Col xs='auto' className='bookedButton'>
                      <Button
                        color='success'
                        onClick={() => setOpenBookedModal(true)}
                      >
                        <AddIcon />
                        <span>{f('blockAppointment')}</span>
                      </Button>
                    </Col>
                    <Col xs='auto' className='bookedButton'>
                      <Button
                        color='success'
                        onClick={() => {
                          setIsOpenBlockModal(true)
                        }}
                      >
                        <AddIcon />
                        <span>{f('blockSlots')}</span>
                      </Button>
                    </Col>

                    <Col xs='auto' className='bookedButton'>
                      <Button
                        color='success'
                        onClick={() => {
                          setClickButtonRdv(true)
                          setIsOpenBookedPatient(true)
                        }}
                      >
                        <AddIcon />
                        <span>{f('rdvPatient')}</span>
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col xs='12'>
                    <CardWrapper appointment>
                      <Calendar
                        selectable
                        localizer={localizer}
                        events={events}
                        timeslots={1}
                        startAccessor='start'
                        endAccessor='end'
                        step={15}
                        min={minTime}
                        max={maxTime}
                        messages={messages}
                        style={{ height: 750 }}
                        dayPropGetter={(date: Date) => calendarStyle(date)}
                        onSelectSlot={(slotInfo) => {
                          const now = new Date()
                          const start = new Date(slotInfo.start)
                          start.setHours(now.getHours())
                          start.setMinutes(now.getMinutes())
                          start.setSeconds(now.getSeconds())

                          if (moment(start).format('YYYY-MM-DD') >= moment(now).format('YYYY-MM-DD')) {
                            if (isClickable(new Date(slotInfo.start))) {
                              setClickButtonRdv(false)
                              setSlotInfoDate(slotInfo.start)
                              setIsOpenBookedPatient(true)
                            }
                          }
                        }}
                        onSelectEvent={(event) => {
                          setIsOpen(true)
                          setSelectedEvent(event)
                        }}
                      />
                    </CardWrapper>
                  </Col>
                </Row>
                </>}

          </Container>
        </ContainerWrapper>
        {(selectedPharma && selectedPharma.pharmacy.pharmacy_menu?.calendar === 1 && !isMultipharmaAccount) &&
          <InfosViewDisabledModal
            isOpen={openModalDisabled}
            toggle={toggle}
          />}
      </StockWrapper>
    </>
  )
}

export default Appointment
