import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Table, Input } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { RootState } from 'models/redux.model'
import { alertSuccess, alertError } from 'utils/notifications'

import API from '../utils/API'
import Button from './ui/Button'

const StyledRow = styled.tr<{ disabled?: boolean }>`
  background-color: ${(props) => (props.disabled === true ? '#eee' : 'white')};
`

const TimeInput = styled(Input).attrs(() => ({
  type: 'time'
}))`
  &:disabled {
    cursor: not-allowed;
    background-color: #e0e0e0;
  }
`

const StyledButton = styled(Button).attrs(() => ({
  primary: true
}))`
  width: 15rem;
  margin: 20rem !important;
  padding: 20rem !important;
`

const Checkbox = styled(Input).attrs(() => ({
  type: 'checkbox'
}))`
  margin-left: 1rem;
  margin-top: -0.2rem;
`

const StyledTable = styled(Table)`
  background-color: white;
  thead th {
    padding: 0.75rem !important;
  }

  th:first-child {
    padding-left:  0.75rem !important;
  }

  td {
    padding: 0.75rem !important;
  }
 
`

const ScheduleManager: React.FC = () => {
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const selectedPharma = useSelector((state: RootState) => state.user.selectedPharma)

  const [enabled, setEnabled] = useState<boolean[]>([true, true, true, true, true, true])
  const [breaks, setBreaks] = useState<boolean[]>([true, true, true, true, true, true])

  const [schedule, setSchedule] = useState(selectedPharma.pharmacy.schedule ?? [])
  // const [schedule, setSchedule] = useState(JSON.parse(selectedPharma.pharmacy.schedule) || {})

  const indexToKey = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']

  useEffect(() => {
    setSchedule(selectedPharma.pharmacy.schedule ?? [])

    const weekDays = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']

    const newBreaks = [true, true, true, true, true, true, true]
    const newEnabled = [true, true, true, true, true, true, true]

    for (let i = 0; i < 7; i++) {
      const day = selectedPharma.pharmacy.schedule != null ? selectedPharma.pharmacy.schedule[weekDays[i]] : null

      if (day == null || (Object.keys(day).length === 0 && day.constructor === Object)) {
        newEnabled[i] = false
      } else if (day.noon == null && day.afternoon == null) {
        newBreaks[i] = false
      }
    }

    setBreaks(newBreaks)
    setEnabled(newEnabled)
  }, [selectedPharma])

  const handleToggleBreak = (index: number): void => {
    const newArray = breaks
    newArray[index] = !breaks[index]

    setBreaks([...newArray])
  }

  const handleToggleEnabled = (index: number): void => {
    const newArray = enabled
    newArray[index] = !enabled[index]

    setEnabled([...newArray])
  }

  const handleChangeTime = (day: string, value: string, time: string): void => {
    const res = schedule

    if (res[day] == null) res[day] = {}

    res[day][time] = valueToInt(value)

    if (Object.keys(res[day]).length === 0 && res[day].constructor === Object) { delete res[day] }

    setSchedule({ ...res })
  }

  const handleSend = async (): Promise<void> => {
    try {
      const data: { [key: string]: any } = {}

      for (let i = 0; i < 7; i++) {
        if (!enabled[i]) continue

        const newData: { [key: string]: any } = {}

        const day = indexToKey[i]

        // Skip empty and undefined days
        if (
          schedule[day] == null ||
          (Object.keys(schedule[day]).length === 0 && schedule[day].constructor === Object)
        ) { continue }

        if (schedule[day]?.morning != null) newData.morning = schedule[day].morning
        if (schedule[day]?.evening != null) newData.evening = schedule[day].evening

        if (breaks[i]) {
          if (schedule[day]?.noon != null) newData.noon = schedule[day].noon
          if (schedule[day]?.afternoon != null) newData.afternoon = schedule[day].afternoon
        }

        data[day] = newData
      }

      const res = await API.put(`/pharmacies/schedule/${selectedPharma.pharmacy_id}`, {
        data: data
      })

      if (res.data.success === true) {
        alertSuccess(f('updateScheduleSuccess'))
      }
    } catch (err: any) {
      alertError(f('updateScheduleError'))
      console.error(err)
    }
  }

  const days = [
    f('monday'),
    f('tuesday'),
    f('wednesday'),
    f('thursday'),
    f('friday'),
    f('saturday'),
    f('sunday')
  ]

  const intToValue = (int: number | null): string => {
    if (int == null) return ''

    let str = int.toString()

    if (str.length === 1) {
      str = '000' + str
    }

    if (str.length === 2) {
      str = '00' + str
    }

    if (str.length === 3) {
      str = '0' + str
    }

    return str.substring(0, 2) + ':' + str.substring(2, 4)
  }

  const valueToInt = (value: string): number | null => {
    if (value == null) return null

    let int = value.replace(/:/g, '')
    if (int[0] === '0') {
      int = int.substring(1, 4)
    }

    return parseInt(int)
  }

  const tableBody: React.FC = () => {
    const res = []

    for (let i = 0; i < 7; i++) {
      res.push(
        <StyledRow disabled={!enabled[i]} key={`schedule-row-${i}`}>
          <th scope='row'>{days[i]}</th>
          <td>
            <Checkbox
              checked={enabled[i]}
              onChange={() => handleToggleEnabled(i)}
              id={`checkbox-enabled-${i}`}
            />
          </td>
          <td>
            <Checkbox
              checked={breaks[i]}
              onChange={() => handleToggleBreak(i)}
              id={`checkbox-breaks-${i}`}
            />
          </td>
          <td>
            <TimeInput
              disabled={!enabled[i]}
              onChange={(event: any) => {
                event.preventDefault()
                handleChangeTime(indexToKey[i], event.target.value, 'morning')
              }}
              value={schedule && intToValue(schedule[indexToKey[i]]?.morning)}
            />
          </td>
          <td>
            <TimeInput
              disabled={!enabled[i] || !breaks[i]}
              onChange={(event: any) => {
                event.preventDefault()
                handleChangeTime(indexToKey[i], event.target.value, 'noon')
              }}
              value={schedule && intToValue(schedule[indexToKey[i]]?.noon)}
            />
          </td>
          <td>
            <TimeInput
              disabled={!enabled[i] || !breaks[i]}
              onChange={(event: any) => {
                event.preventDefault()
                handleChangeTime(indexToKey[i], event.target.value, 'afternoon')
              }}
              value={schedule && intToValue(schedule[indexToKey[i]]?.afternoon)}
            />
          </td>
          <td>
            <TimeInput
              disabled={!enabled[i]}
              onChange={(event: any) => {
                event.preventDefault()
                handleChangeTime(indexToKey[i], event.target.value, 'evening')
              }}
              value={schedule && intToValue(schedule[indexToKey[i]]?.evening)}
            />
          </td>
        </StyledRow>
      )
    }

    return <tbody>{res}</tbody>
  }

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <th>#</th>
            <th>{f('open')}</th>
            <th>{f('breaks')}</th>
            <th>{f('morning')}</th>
            <th>{f('noon')}</th>
            <th>{f('afternoon')}</th>
            <th>{f('evening')}</th>
          </tr>
        </thead>
        {tableBody({})}
      </StyledTable>
      <StyledButton primary onClick={handleSend}>
        {f('validChanges')}
      </StyledButton>
    </>
  )
}

export default ScheduleManager
