import React from 'react'
import { RenderCellTable } from 'models/table.model'
import moment from 'moment'

const RenderBlockTable = ({ row, rowIndex, column }: RenderCellTable): JSX.Element => {
  return (
    <td
      key={`td${rowIndex}_${column.accessor}`}
      onClick={() => {
      }}
    >

      {column.accessor !== 'motif' ? moment(row[column.accessor]).format('DD-MM-YYYY HH:mm') : row[column.accessor]}
    </td>
  )
}

export default RenderBlockTable
