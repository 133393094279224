import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { CouponState } from 'models/product.model'

import Coupon from './Coupon'

interface CouponsListProps {
  total: number
  points: number
  checkCoupon: number
  newTotalPrice: number
  newTotalPoints: number
  listCoupon: CouponState[]
  setCheckCoupon: (id: number) => any
}

const CouponsList = ({ total, points, checkCoupon, newTotalPrice, newTotalPoints, listCoupon, setCheckCoupon }: CouponsListProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const f = (id: string): string => formatMessage({ id })

  useEffect(() => {
    setCheckCoupon(0)
  }, [])

  return (
    <div className='block-total-price'>
      <div
        className='block-total-price__title'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {total !== null && (
          <span>
            {f('Total Price')}: {total.toFixed(2)} €
          </span>
        )}
        <ResetButton onClick={() => setCheckCoupon(0)}>{f('reset')}</ResetButton>
      </div>
      <PointsInfo>{`${f('PointsNumber')}${points}`}</PointsInfo>
      {listCoupon
        .sort((a, b) => a.point - b.point)
        .map((item: CouponState) =>
          <Coupon
            key={`coupon_${item.id}`}
            points={points}
            total={total}
            item={item}
            checkCoupon={checkCoupon}
            setCheckCoupon={setCheckCoupon}
          />
        )}
      <hr className='block-total-price__line' />
      <p className='block-total-price__total-price'>
        {f('Total Price')}:
        <span>
          {newTotalPrice.toFixed(2)}€
        </span>
      </p>
      {checkCoupon !== 0 && (
        <PointsInfo>
          {f('NewPointsBalance')}
          {newTotalPoints}
        </PointsInfo>
      )}
    </div>
  )
}

const PointsInfo = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.996px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0px;
`

const ResetButton = styled.button`
  padding: 0;
  background: none;
  border: none;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.996px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 0px;

  &:hover {
    text-decoration: underline;
  }

  &:active,
  &:focus {
    border: none !important;
    outline: none;
    box-shadow: none !important;
  }
`

export default CouponsList
