const devKeys: {
  baseUrl: string
  apiKey: string
  pushVapidKey: string
  timeSlot: number
} = {
  baseUrl: 'https://preprod-shielded-dawn-73021.herokuapp.com',
  // baseUrl: 'https://phacil-back-beta.herokuapp.com',
  // baseUrl: 'http://lvh.me:5000',
  // baseUrl: 'http://localhost:5000',
  apiKey: '/jVdfUX+u/Kn3qPY4+ahjwQgyV5UhkM5cdh1i2xhozE=',
  pushVapidKey:
    'BEHTam4Ia1iRUvQU9NfRgqrwevWDTDkItCehmDVR6RuQ9gGcU-JJM2SR0g4ibtWz-V5PzM5cSmswJjtQuW82xeQ',
  timeSlot: 15
}

export default devKeys

// pré-prod : https://preprod-shielded-dawn-73021.herokuapp.com
// prod : https://shielded-dawn-73021.herokuapp.com
// beta : https://phacil-back-beta.herokuapp.com
// localhost : http://lvh.me:5000
// mobile api : https://mobile-api-phacil.herokuapp.com/
