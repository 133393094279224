
import styled from 'styled-components'

export const ContainerWrapper = styled.div`
  @media (min-width: 769px) {
    margin-left: 230px;
  }

  ${props => props.property === 'scheduleManagement' && `{
    .bodyHeader {
        margin-left: inherit !important; 
      }
  }`}

  .notifcations{
    padding: 1.5rem;
  }

`
