// import Cookies from "universal-cookie";
import CookieParser from 'cookie'

import { AppThunk } from 'models/redux.model'
import API from '../utils/API'
import { axiosInstance } from '../utils/APIs'
import {
  alertSuccess,
  alertLoading,
  alertError,
  alertSuccessConnexionCompte
} from '../utils/notifications'
import * as actionTypes from './actionTypes'
import * as actions from './index'

// const cookies = new Cookies();

/**
 *Start of the authentication process
 * @function
 * @return {Object} {type: actionTypes.AUTH_START}
 */

const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

/**
 *Start of the authentication process
 * @function
 * @return {Object}  {type: actionTypes.AUTH_SUCCESS, idToken: token
    };
 */
const authSuccess = (token: string) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token
  }
}

/**
 *Fail of the authentication process
 * @function
 * @return {Object} {type: actionTypes.AUTH_FAILER, error: error}
 */
const authFail = (error: Error) => {
  return {
    type: actionTypes.AUTH_FAILED,
    error: error
  }
}

/**
 * Redirect to path when authentication is validated
 * @function
 * @param {string} path path to the redirect page
 * @return {Object} {type: actionTypes.SET_AUTH_REDIRECT_PATH, payload: path}
 */
export const setAuthRedirectPath = (path: string) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    payload: path
  }
}

interface LoginProps {
  email?: string | null
  password?: string | null
  remember?: boolean
  tokenPharmacy?: string
}

/**
 * Login user
 * @async
 * @function
 * @param {String} email email of user
 * @param {String} password password of user (encrypted)
 * @param {Boolean} remember true if the user want to stay connected
 * @fires dispatch(authSuccess) /  dispatch(actions.setUser(authResponse.data.user))
 * @see authSuccess
 * @see setUser
 */
export const login: (data: LoginProps, formatMessage: any) => AppThunk = ({ email, password, remember, tokenPharmacy }, formatMessage) => {
  return async (dispatch) => {
    let authOK = false
    dispatch(authStart())
    alertLoading(formatMessage('Authentication'))
    try {
      const postData = { email, password, tokenPharmacy }
      const authResponse = await API.post('/pharmacist/authenticate', postData)

      const token = authResponse.data.token

      // Save token localStorage
      localStorage.setItem('token', token)
      alertSuccessConnexionCompte(formatMessage('successAuth'))

      // eslint-disable-next-line require-atomic-updates
      API.defaults.headers.common.Authorization = token
      axiosInstance.defaults.headers.common.Authorization = token

      if (authResponse.headers.sessionid != null) {
        const cookieParsed = CookieParser.parse(authResponse.headers.sessionid)
        API.defaults.headers.sessionid = cookieParsed['connect.sid']
        localStorage.setItem('sessionid', cookieParsed['connect.sid'])
      }

      dispatch(authSuccess(token))
      authOK = true

      dispatch(actions.getUser())
      dispatch(actions.setAuthStatus(true))
    } catch (e: any) {
      if (!authOK) {
        alertError(formatMessage('badPassword'))
        dispatch(authFail(e))
        dispatch(actions.setAuthStatus(false))
      }
      console.log(e.message)
    }
  }
}

interface PasswordProps {
  email: string
}

/**
 * Password forgotten
 * @async
 * @function
 * @param {String} email email of user
 * @fires dispatch(passwordReset)
 * @see authSuccess
 */
export const resetPassword: (data: PasswordProps, formatMessage: any) => AppThunk = ({ email }, formatMessage) => {
  return async (dispatch) => {
    try {
      const reset = await API.post('/users/reinit', { email: email })

      if (reset.data.success === true) alertSuccess(formatMessage('sendMdp'))
      else if (reset.data.err != null) alertError(reset.data.err)
      else alertError(formatMessage('errorResetPassword'))
    } catch (e: any) {
      alertError(formatMessage('errorResetPassword'))
      console.log(e)
    }
  }
}

/**
 * logout user
 * @async
 * @function
 * @return {Function} if success dispatch(logoutUser()) dispatch(actions.forgetUser())
 * @see logoutUser
 * @see forgetUser
 */
export const logout: (formatMessage: any) => AppThunk = (formatMessage: any) => {
  return async (dispatch) => {
    try {
      const logoutResponse = await API.get('/users/logout')
      if (logoutResponse.status === 200 && logoutResponse.data.success != null) {
        localStorage.removeItem('sessionid')

        if (logoutResponse.headers.sessionid != null) {
          const cookieParsed = CookieParser.parse(logoutResponse.headers.sessionid)
          API.defaults.headers.sessionid = cookieParsed['connect.sid']
        }

        alertSuccess(formatMessage('disconnect'))

        dispatch(actions.setAuthStatus(false))
        dispatch(actions.forgetUser())
      }
    } catch (e: any) {
      alertError(formatMessage('errorDisconnect'))
      console.log(e)
    }
  }
}

export const refreshSession: (formatMessage: any) => AppThunk = (formatMessage: any) => {
  return async (dispatch) => {
    let authOK = false
    try {
      API.defaults.headers.sessionid = localStorage.getItem('sessionid')
      const res = await API.get('/pharmacist/refresh-session')

      if (res.data.success === true) {
        if (res.headers.sessionid != null) {
          const cookieParsed = CookieParser.parse(res.headers.sessionid)
          API.defaults.headers.sessionid = cookieParsed['connect.sid']
          localStorage.setItem('sessionid', cookieParsed['connect.sid'])
        }

        API.defaults.headers.common.Authorization = res.data.token
        authOK = true

        dispatch(authSuccess(res.data.token))
        dispatch(actions.setAuthStatus(true))
        dispatch(actions.getUser())
      } else {
        dispatch(actions.setAuthStatus(false))
      }
    } catch (e: any) {
      if (!authOK) {
        alertError(formatMessage('errorGetData'))
        dispatch(actions.setAuthStatus(false))
      }
      console.log(e)
    }
  }
}

export const updatePassword = (password: string, formatMessage: any): AppThunk => {
  return async (dispatch) => {
    try {
      const res = await API.put('/users/updatePass', { password: password })

      if (res.data.success != null) alertSuccess(formatMessage('updatePassword'))
      else alertError(formatMessage('errorUpdatePassword'))
    } catch (e: any) {
      alertError(formatMessage('errorResetPassword'))
      console.log(e)
    }
  }
}
