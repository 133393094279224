import { QuestionsContainer, SecondContent } from 'assets/styles/landingPage/Questions'
import React from 'react'
import QuestionImg from '../../../src/assets/images/landingPage/question.svg'
import { useIntl } from 'react-intl'

const Questions: React.FC<{}> = () => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-6 col-12'>
          <QuestionsContainer className='d-flex'>
            <img src={QuestionImg} alt='Question' />

            <div className='flex-1'>
              <h5 className='mt-0'>{f('teamQuestion')} <span className='text-primary'>Phacil</span> {f('questionTitle1')} </h5>
              <p className='text-muted mb-0'>{f('questionText1')}</p>
            </div>
          </QuestionsContainer>
        </div>

        <div className='col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0'>
          <QuestionsContainer className='d-flex'>
            <img src={QuestionImg} alt='Question' />
            <div className='flex-1'>
              <h5 className='mt-0'>{f('questionTitle2')}</h5>
              <p className='text-muted mb-0'> {f('questionText2')}</p>
            </div>
          </QuestionsContainer>
        </div>

        <div className='col-md-6 col-12 mt-4 pt-2'>
          <QuestionsContainer className='d-flex'>
            <img src={QuestionImg} alt='Question' />
            <div className='flex-1'>
              <h5 className='mt-0'>{f('questionTitle3')}</h5>
              <p className='text-muted mb-0'>{f('questionText3')}</p>
            </div>
          </QuestionsContainer>
        </div>

        <div className='col-md-6 col-12 mt-4 pt-2'>
          <QuestionsContainer className='d-flex'>
            <img src={QuestionImg} alt='Question' className='text-primary me-2' />
            <div className='flex-1'>
              <h5 className='mt-0'> {f('questionTitle4')} <span className='text-primary'>Phacil</span> {f('questionTitle4.1')}</h5>
              <p className=' text-muted mb-0'>{f('questionContent4')}</p>
            </div>
          </QuestionsContainer>
        </div>
      </div>

      <div className='row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center'>
        <SecondContent className='col-12 text-center'>
          <div>
            <h4 className='title mb-4'>{f('questionTitle5')}</h4>
            <p className='text-muted para-desc mx-auto'>{f('questionContent5')} <span className='text-primary'>Phacil </span>  {f('questionContent5.1')}</p>
            <a href='https://share-eu1.hsforms.com/19Gqqz6YOSgK1GUNP1jKljgf2aid' target='_blank' className='btn btn-primary mt-4' rel='noreferrer'> {f('letsGo')}</a>
          </div>
        </SecondContent>
      </div>
    </div>

  )
}
export default Questions
