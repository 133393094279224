export const statutWording = (state: any, language: string) => {
  if (language === "es") {
    if (state <= 1) {
      return 'Pendiente'
    } else if (state > 1 && state < 6) {
      return 'Validado'
    } else if (state >= 6) {
      return 'Cancelado'
    }
  } else {
    if (state <= 1) {
      return 'En attente'
    } else if (state > 1 && state < 6) {
      return 'Validée'
    } else if (state >= 6) {
      return 'Annulée'
    }
  }
}

export const statutColor = (state: any) => {
  if (state <= 1) {
    return '#ffc791'
    // return "#ffff00";
  } else if (state > 1 && state < 6) {
    return '#deeda1'
    // return "#00e676";
  } else if (state >= 6) {
    return '#d9d9d9'
    // return "#383838";
  } else {
    return '#d9d9d9'
  }
}
