import React from 'react'

import styled from 'styled-components'
import { Col, NavItem, NavLink } from 'reactstrap'
import { FilterProps } from 'models/filter.model'

const BorderBottomCol = styled(Col)`
  border-bottom: 1px solid #dee2e6;
  padding: 0;
  max-width: 97%;
  a:hover,
  span:hover {
    filter: brightness(80%);
`

const Filters: React.FC<FilterProps> = ({ motifsByLanguage, motifs, activeTab, setActiveTab, referenceRows, setFilteredRows, setOffset }) => {
  return (
    <BorderBottomCol className='nav' lg='12'>
      {motifsByLanguage.map((motifByLanguage: string, index: number) => (
        // <NavWrapper>
        <NavItem key={index}>
          <NavLink
            className={activeTab === index ? 'active' : ''}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setActiveTab(index)

              let filterRowsByMotif = []
              if (referenceRows != null && referenceRows.length > 0) {
                if (motifs[index] !== 'Deleted') {
                  referenceRows = referenceRows.filter(el => el.deleted === false || el.deleted === null)
                }
                if (motifs[index] === 'Non Ecouté') {
                  filterRowsByMotif = referenceRows.filter(el => el.listened === false)
                } else if (motifs[index] === 'Ecouté') {
                  filterRowsByMotif = referenceRows.filter(el => el.listened === true)
                } else if (motifs[index] === 'Deleted') {
                  filterRowsByMotif = referenceRows.filter(el => el.deleted && el.deleted === true)
                } else {
                  filterRowsByMotif = referenceRows.filter(el => el.origin === motifs[index])
                }
              }
              if (motifs[index] === 'Tous') {
                setFilteredRows(referenceRows)
              } else {
                setFilteredRows(filterRowsByMotif)
              }
              setOffset?.(0)
            }}
          >
            {motifByLanguage}
          </NavLink>
        </NavItem>
      ))}
    </BorderBottomCol>
  )
}

export default Filters
