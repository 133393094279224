import { HeaderContainer } from 'assets/styles/landingPage/Header'
import React from 'react'
import LogoImg from '../../../src/assets/images/landingPage/logo-Phacil-115.png'

const Header: React.FC<{}> = () => {
  return (

    <HeaderContainer>
      <img src={LogoImg} alt='Login' />

    </HeaderContainer>

  )
}
export default Header
