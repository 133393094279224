import React from 'react'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { history } from '../store'
import { Col, Row } from 'reactstrap'
import { useIntl } from 'react-intl'

const PasswordForm: React.FC<{
  onSubmit: (fields: { password: string, passwordConfirmation: string }) => any
}> = ({ onSubmit }) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required(f('pleasePasswd')),
        passwordConfirmation: Yup.string()
          .oneOf(
            [Yup.ref('password')],
            f('passwdMatch')
          )
          .required(f('confirmPasswd'))
      })}
      onSubmit={(fields) => onSubmit(fields)}
    >
      {({ errors, touched }) => (
        <Form>
          <Row className='form-group'>
            <Col>
              <label htmlFor='password'>{f('newPasswd')}</label>
              <Field
                name='password'
                type='password'
                className={
                  'form-control' +
                  (errors.password && touched.password ? ' is-invalid' : '')
                }
              />
              <ErrorMessage
                name='password'
                component='div'
                className='invalid-feedback'
              />
            </Col>
            <Col>
              <label htmlFor='password'>{f('confirmPasswd2')}</label>
              <Field
                name='passwordConfirmation'
                type='password'
                className={
                  'form-control' +
                  (errors.passwordConfirmation && touched.passwordConfirmation
                    ? ' is-invalid'
                    : '')
                }
              />
              <ErrorMessage
                name='passwordConfirmation'
                component='div'
                className='invalid-feedback'
              />
            </Col>
          </Row>
          <div className='form-group'>
            <button type='submit' className='btn btnPrimary mr-2'>
              {f('valid')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PasswordForm
