import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPowerOff,
  faUser,
  faPhone
} from '@fortawesome/free-solid-svg-icons'
import { RootState } from 'models/redux.model'

import { logout } from '../actions/index'
import { history } from '../store'
import { useIntl } from 'react-intl'

const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  height: var(--navbar-height);
  width: 100%;
  z-index: 1000;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid var(--border-navbar);

  .logoPhacil {
    margin-left: 0.5rem;
  }
  .navMenu {
    display: flex;
    flex-grow: 1;
    a.linkMenuNavBar:first-of-type {
      margin-left: auto;
      border-right: 1px solid var(--border-navbar);
      text-decoration: none;
      font-size: 1.1rem;
      svg {
        padding-right: 0.2rem;
      }
    }
    a.linkMenuNavBar:hover {
      color: #ff407b;
    }
  }
  .pharmaName {
    font-size: 2rem;
    font-weight: 600;

    a {
      text-decoration: none;
    }
  }

  .linkMenuNavBar,
  .dropDownItem {
    color: var(--texte-menu-navbar);
    font-size: 16px;
    cursor: pointer;
  }
  .linkMenuNavBar {
    font-family: 'Circular Std Book', -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 1.1rem;
    margin: 0 0 0 1rem;
    padding-right: 1rem;
    border-right: 1px solid #e6e6f2;
  }
  .linkMenuNavBar:last-of-type {
    border: none;
  }
  .dropdown-menu {
    padding: 0;
    min-width: 230px;
    right: 0;
    left: auto !important;
    svg {
      margin-right: 0.4rem;
    }
  }
  .dropDownHeader {
    background-color: var(--texte-navbar-hover);
    color: white;
    padding: 0.7rem 1rem;
    h5 {
      font-size: 1.2rem;
      padding: 1rem 0;
      margin-bottom: 0;
    }
    span {
      font-size: 0.7rem;
      padding-left: 0.5rem;
    }
  }
  .dropDownItem {
    font-size: 1rem;
    padding: 0.6rem 2.5rem 0.6rem 1.5rem;
    display: flex;
    align-items: center;
    color: var(--texte-dropdown-navbar);
  }
  .dropDownItem:hover {
    color: var(--texte-navbar-hover);
    background-color: var(--gris-bleu);
  }

  @media (max-width: 769px) {
    .navMenu {
      display: none;
    }
  }
`

interface Link {
  route: string
  label: string
  dropdown?: string[]
  action?: () => any
}

const Navbar: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const userInfo = useSelector((state: RootState) => state.user)
  const { formatMessage } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  const dispatch = useDispatch()
  const links: Link[] = [
    {
      route: 'stock',
      label: f('myStock')
    },
    {
      route: 'orders',
      label: f('myOrders')
    },
    {
      route: 'calendrier',
      label: f('myEvents')
    },
    {
      route: 'voicemail',
      label: f('myVoicemail')
    },
    {
      route: 'user',
      label: f('myAcc'),
      dropdown: [f('info'), f('deco')]
    }
  ]

  const toggle = (): void => {
    setDropdownOpen(prevState => !prevState)
  }

  return (
    <NavbarWrapper>
      <a href='/orders'>
        <img
          className='logoPhacil'
          src='https://phacil.delivery/static/PNG/Assets-3/logo-Phacil-115.png'
          alt='Logo Phacil'
        />
      </a>
      <div className='navMenu'>
        <a
          className='linkMenuNavBar'
          href='tel:0176310595'
          title={f('callPhacilSupport')}
        >
          <FontAwesomeIcon icon={faPhone} />
          {f('help')}
        </a>
        {links.map((link, index) =>
          link.dropdown == null ? (
            <div
              key={`${link.route}${index}`}
              onClick={() => {
                if (typeof link.action === 'function') return link.action()
                history.push('/' + link.route)
              }}
              className='linkMenuNavBar'
            >
              {link.label}
            </div>
          ) : (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className='linkMenuNavBar'
              key='1'
            >
              <DropdownToggle
                tag='span'
                data-toggle='dropdown'
                aria-expanded={dropdownOpen}
              >
                {link.label}
              </DropdownToggle>
              <DropdownMenu>
                <div className='dropDownHeader'>
                  {userInfo.firstname != null && userInfo.lastname != null &&
                    <h5>{`${userInfo.firstname} ${userInfo.lastname}`}</h5>}
                </div>
                {link.dropdown.map((item, index) => (
                  <div
                    key={`${item}#${index}`}
                    onClick={() => {
                      if (index === 0) {
                        history.push('/' + link.route)
                        toggle()
                      } else if (index === 1) return dispatch(logout(f))
                    }}
                    className='dropDownItem'
                  >
                    {
                      index === 0 && (
                        <FontAwesomeIcon icon={faUser} />
                      )
                    }
                    {
                      index === 1 && (
                        <FontAwesomeIcon icon={faPowerOff} />
                      )
                    }
                    {item}
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown>
          )
        )}
      </div>
    </NavbarWrapper>
  )
}

export default Navbar
