import React, { useEffect, useState } from 'react'
import { Card, Row, Col, CardGroup, NavLink, CardImg } from 'reactstrap'
import styled from 'styled-components'

import API from 'utils/API'
import { useIntl } from 'react-intl'
import { LoaderWrapper } from 'assets/styles/LoaderWrapper'
import { openPopup } from 'utils/popup'

const loadingIcon = 'images/loading-gif-phacil.svg'
const rectoVitaleImg = 'images/vitale-recto-apercu.jpg'
const versoVitaleImg = 'images/vitale-verso-apercu.jpg'
const eOrdoImg = 'images/e-ordo.png'
const mutuelleImg = 'images/mutuelle2.jpg'

const ModalOrdonnance = (props: any) => {
  console.log('ordonnance')
  const [url, setUrl] = useState<string>('')
  const [urlVitaleRecto, setUrlVitaleRecto] = useState<string>('')
  const [urlVitaleVerso, setUrlVitaleVerso] = useState<string>('')
  const [urlMutuelle, setUrlMutuelle] = useState<string>('')
  const [urlAttestation, setUrlAttestation] = useState<string>('')
  const [isLoading, setIsLoading] = useState(true)
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  useEffect(() => {
    const prescription = API.post('/prescription/aws/prescription/download', {
      orderId: props.data.orderId,
      pharmacyOrderId: props.data.pharmacyOrderId
    })

    const vitale = API.post('/prescription/aws/get/vitale', {
      user_id: props.data.user_id
    })

    const mutuelle = API.post('/prescription/aws/get/mutuelle', {
      user_id: props.data.user_id
    })

    const attestation = API.post('/prescription/aws/get/attestation', {
      user_id: props.data.user_id
    })

    Promise.allSettled([prescription, vitale, mutuelle, attestation])
      .then((res) => {
        if (res[0].status === 'fulfilled') {
          setUrl(res[0].value.data.url)
        }
        if (res[1].status === 'fulfilled') {
          setUrlVitaleRecto(res[1].value.data.recto)
          setUrlVitaleVerso(res[1].value.data.verso)
        }
        if (res[2].status === 'fulfilled') {
          setUrlMutuelle(res[2].value.data.url)
        }
        if (res[3].status === 'fulfilled') {
          setUrlAttestation(res[3].value.data.url)
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }, [props.data.orderId, props.data.pharmacyOrderId, props.data.user_id])

  return (
    <div>
      <h4>{f('patientData')}</h4>
      {isLoading ? (
        <LoaderWrapper>
          <h5>{f('loadingData')}</h5>
          <img src={loadingIcon} alt='loading' width='35' />
        </LoaderWrapper>
      ) : (
        <Col>
          <Row>
            {!url && !urlVitaleRecto && !urlVitaleVerso && !urlMutuelle ? (
              <DocsSanteDiv>
                <p>{f('noDoc')}</p>
              </DocsSanteDiv>
            ) : (
              <CardGroup>
                <Col>
                  {url && (
                    <StyledCard>
                      <div className='eORdoBox'>
                        <StyledNavLink
                          rel='noopener noreferrer'
                          onClick={() => {
                            openPopup(url)
                          }}
                        >
                          <div>
                            {f('eOrdo')}
                          </div>
                          <CardImg top src={eOrdoImg} alt='E-ordonnance' />
                        </StyledNavLink>
                      </div>
                    </StyledCard>
                  )}
                </Col>
                <Col>
                  {(urlAttestation || urlVitaleRecto) && (
                    <StyledCard>
                      <div className='eORdoBox'>
                        <StyledNavLink
                          onClick={() => {
                            openPopup(urlAttestation ? urlAttestation : urlVitaleRecto)
                          }}
                          rel='noopener noreferrer'
                        >
                          <div>
                            {f('vitale')}
                          </div>
                          <CardImg
                            top
                            src={rectoVitaleImg}
                            alt='Recto carte vitale'
                            className='mutuelleImg'
                          />
                        </StyledNavLink>
                      </div>
                    </StyledCard>
                  )}
                </Col>
                <Col>
                  {urlMutuelle && (
                    <StyledCard>
                      <div className='eORdoBox'>
                        <StyledNavLink
                          rel='noopener noreferrer'
                          onClick={() => {
                            openPopup(urlMutuelle)
                          }}
                        >
                          <div>
                            {f('mutuelle')}
                          </div>
                          <CardImg top src={mutuelleImg} alt='Mutuelle' className='mutuelleImg' />
                        </StyledNavLink>
                      </div>
                    </StyledCard>
                  )}
                </Col>
              </CardGroup>
            )}
          </Row>
        </Col>
      )}
    </div>
  )
}

const DocsSanteDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledCard = styled(Card)`
  cursor: pointer;
  .eORdoBox {
   height: 100px;
   overflow: hidden;
  img {
    max-width: 100%;
  }
  .nav-link {
    padding: 0;
    }
  }
`

const StyledNavLink = styled.a`
  div{
   color: black;
   text-align: center;
  }
  :hover{
    div{
      color: #0000FF
    }
  }
`

export default ModalOrdonnance
