import API from './API'
// import fileDownload from "js-file-download";
import iconv from 'iconv-lite'
// 03844507C0,1   123456789012345         1           LUCE DELAHAYE                   6 RUE SUZANNE SAVALE            APPT 3   LES MYOSOTIS           76120     LE GRAND QUEVILLY          FRJ 0676703123                                                                                               PHARMACIE 1                     167, AVENUE HENRI BARBUSSE                                      78370     PLAISIR                    FRLUCIE2617@HOTMAIL.FR

export const createTntFile = (data: any) => {
  API.post('pharmacist/export-tnt', {
    orderId: data.orderId,
    pharmacyOrderId: data.pharmacyOrderId,
    type: data.type
  }).then(res => {
    const parsedData = res.data.result
    let newLine = ''

    // 1. N° compte TNT
    // Longueur = 8 Caractères
    // ----------------------------
    // Indiquer votre n° de compte TNT
    // Colonne = 1
    let accountNumber = parsedData.accountNumber
    const accountNumberLength = 8 - accountNumber?.length

    if (accountNumberLength < 0) { accountNumber = accountNumber.slice(0, accountNumberLength) }

    for (let i = 0; i < accountNumberLength; i++) accountNumber += ' '
    newLine += accountNumber

    // 2. Type (Type (C/S)
    // Longueur = 1 Caractère
    // ----------------------------
    //     Livraison en semaine: Mettre C
    // Livraison le Samedi: Mettre S
    // Zone obligatoire)
    // Colonne = 9
    newLine += parsedData.type

    // 3. Poids
    // Longueur = 6 Numérique
    // Format = XXXX.X
    // ----------------------------
    //     Poids du colis ou de l'expédition
    // Zone obligatoire pour EditAuto(0, 1)
    // Colonne = 10
    let weight = parsedData.weight
    const weightLength = 6 - weight.length
    for (let i = 0; i < weightLength; i++) weight += ' '
    newLine += weight

    // 4. Référence Client
    // Longueur = 15 Caractères
    // ----------------------------
    //  Votre référence (Commande, Facture, Bon, ...) qui apparaîtra sur le bon de transport et sur la facture TNT et qui vous permettra ensuite de faire un suivi colis via notre site web : www.tnt.fr
    // Colonne = 16
    let orderId = data.type === 1 ? `ORD-${parsedData.orderId}` : parsedData.orderId
    const orderIdLength = 15 - orderId.length
    for (let i = 0; i < orderIdLength; i++) orderId += ' '
    newLine += orderId

    // 5. Valeur Déclarée TOUJOURS VIDE
    // Longueur = 9 Numérique
    // Format = XXXXXXXXX
    // ----------------------------
    // Le montant de l'assurance supplémentaire de votre expédition. A ne remplir que si vous souhaitez une assurance complémentaire aux conditions générale de transport TNT.
    // Si pas d'assurance = zone vide
    // Colonne = 31
    newLine += '         '

    // 6. Nombre de colis TOUJOURS 1
    // Longueur = 2 Numérique
    // Format = XX
    // ----------------------------
    //     Le nombre de colis de votre expédition.Entre 1 et 99
    // Zone obligatoire pour EditAuto
    // Colonne = 40
    newLine += '1 '

    // 7. Le Code Destinataire TOUJOURS VIDE
    // Longueur = 10 Caractères
    // ----------------------------
    //     Le code informatique interne de votre Destinataire.
    // Colonne = 42
    newLine += '          '

    // 8. Nom du Destinataire
    // Longueur = 32 Caractères
    // ----------------------------
    // Le nom de votre Destinataire.
    // Zone obligatoire
    // Colonne = 52
    let receiverName = parsedData.receiverName ? parsedData.receiverName.toUpperCase() : ''
    const receiverNameLength = 32 - receiverName.length

    if (receiverNameLength < 0) { receiverName = receiverName.slice(0, receiverNameLength) }

    for (let i = 0; i < receiverNameLength; i++) receiverName += ' '
    newLine += receiverName

    // 9. Adresse du Destinataire
    // Longueur = 32 Caractères
    // ----------------------------
    // Première ligne d'adresse de votre Destinataire.
    // L'adresse OU le complément d'adresse est une Zone obligatoire
    // Colonne = 84
    let receiverAddress = parsedData.receiverAddress ? parsedData.receiverAddress.toUpperCase() : ''
    const receiverAddressLength = 32 - receiverAddress.length

    if (receiverAddressLength < 0) { receiverAddress = receiverAddress.slice(0, receiverAddressLength) }

    for (let i = 0; i < receiverAddressLength; i++) receiverAddress += ' '
    newLine += receiverAddress

    // 10. Complément d'Adresse du Destinataire
    // Longueur = 32 Caractères
    // ----------------------------
    // Deuxième ligne d'adresse de votre Destinataire.
    // L'adresse OU le complément d'adresse est une Zone obligatoire
    // Colonne = 116
    let receiverAddress2 = parsedData.receiverAddress2 ? parsedData.receiverAddress2.toUpperCase() : ''
    const receiverAddress2Length = 32 - receiverAddress2.length

    if (receiverAddress2Length < 0) { receiverAddress2 = receiverAddress2.slice(0, receiverAddress2Length) }

    for (let i = 0; i < receiverAddress2Length; i++) receiverAddress2 += ' '
    newLine += receiverAddress2

    // 11. Code Postal du Destinataire
    // Longueur = 10 Caractères
    // ----------------------------
    // Code Postal Français de votre Destinataire. Doit être entre 01000 et 98000.
    // Zone obligatoire
    // Colonne = 148
    let receiverPostalCode = parsedData.receiverPostalCode ? parsedData.receiverPostalCode.toUpperCase() : ''
    const receiverPostalCodeLength = 10 - receiverPostalCode.length

    if (receiverPostalCodeLength < 0) { receiverPostalCode = receiverPostalCode.slice(0, receiverPostalCodeLength) }

    for (let i = 0; i < receiverPostalCodeLength; i++) receiverPostalCode += ' '
    newLine += receiverPostalCode

    // 12. Ville du Destinataire
    // Longueur = 27 Caractères
    // ----------------------------
    // Ville de votre Destinataire
    // Zone obligatoire
    // Colonne = 158
    let receiverCity = parsedData.receiverCity ? parsedData.receiverCity.toUpperCase() : ''
    const receiverCityLength = 27 - receiverCity.length

    if (receiverCityLength < 0) { receiverCity = receiverCity.slice(0, receiverCityLength) }

    for (let i = 0; i < receiverCityLength; i++) receiverCity += ' '
    newLine += receiverCity

    // 13. Pays du Destinataire
    // Longueur = 2 Caractères
    // ----------------------------
    // Pays de votre Destinataire sur 2 caractères.
    // Metre FR pour la France
    // Zone obligatoire
    // Colonne = 185
    newLine += 'FR'

    // 14. Produit TNT de l'expédition
    // Longueur = 2 Caractères
    // ----------------------------
    // Mettre le code produit TNT correspondant à votre expédition.
    // Cadré à gauche.
    // Exemples :
    //  J  = 13.00 Express
    //  JD = 13.00 Express Relais Colis
    //  JP = 13.00 Express Retour de Paiement
    //  T  = 10.00 Express
    //  ......
    // Consultez votre interlocuteur commercial TNT
    // Zone obligatoire
    // Colonne = 187
    newLine += 'J '

    // 14. Instruction Particulière 1
    // Longueur = 30 Caractères
    // ----------------------------
    //     Information supplémentaire imprimée
    // sur le bon de transport TNT
    // Colonne = 189
    let instruction1 = parsedData.instruction1
    const instruction1Length = 30 - instruction1.length

    if (instruction1Length < 0) { instruction1 = instruction1.slice(0, instruction1Length) }

    for (let i = 0; i < instruction1Length; i++) instruction1 += ' '
    newLine += instruction1

    // 15. Instruction Particulière 2
    // Longueur = 30 Caractères
    // ----------------------------
    //     Information supplémentaire imprimée
    // sur le bon de transport TNT
    // Colonne = 219
    let instruction2 = parsedData.instruction2
    const instruction2Length = 30 - instruction2.length

    if (instruction2Length < 0) { instruction2 = instruction2.slice(0, instruction2Length) }

    for (let i = 0; i < instruction2Length; i++) instruction2 += ' '
    newLine += instruction2

    // 16. Instruction Particulière 3
    // Longueur = 25 Caractères
    // ----------------------------
    // Information supplémentaire imprimée
    // sur le bon de transport TNT
    // Colonne = 249
    let instruction3 = parsedData.instruction3
    const instruction3Length = 25 - instruction3.length

    if (instruction3Length < 0) { instruction3 = instruction3.slice(0, instruction3Length) }

    for (let i = 0; i < instruction3Length; i++) instruction3 += ' '
    newLine += instruction3

    // 17. Règlement du retour de paiement TOUJOURS VIDE
    // Longueur = 12 Numérique
    // Format = XXXXXXXXX.XX
    // ----------------------------
    //     Le montant du retour de paiement de votre expédition
    // Il faut que le 2ème caractères du code produit soit un P(exemple : JP)
    // Si pas de retour de paiement = zone vide
    // Colonne = 274
    newLine += '            '

    // 18. Date  de départ TNT Express France:
    // Longueur = 8 Numérique
    // Colonne = 286
    newLine += '        '

    // 19. Nom de l'Expéditeur
    // Longueur = 32 Caractères
    // ----------------------------
    // A remplir si vous souhaitez faire apparaître sur le bon de transport TNT une adresse différente de celle de votre compte TNT.
    // Dans ce cas, il faut remplir correctement TOUTES les zones concernant l'Expéditeur
    // Colonne = 294
    let senderName = parsedData.senderName
    const senderNameLength = 32 - senderName.length

    if (senderNameLength < 0) { senderName = senderName.slice(0, senderNameLength) }

    for (let i = 0; i < senderNameLength; i++) senderName += ' '
    newLine += senderName

    // 20. Adresse de l'Expéditeur
    // Longueur = 32 Caractères
    // ----------------------------
    // A remplir si vous souhaitez faire apparaître sur le bon de transport TNT une adresse différente de celle de votre compte TNT.
    // Dans ce cas, il faut remplir correctement TOUTES les zones concernant l'Expéditeur
    // Colonne = 326
    let senderAddress = parsedData.senderAddress
    const senderAddressLength = 32 - senderAddress.length

    if (senderAddressLength < 0) { senderAddress = senderAddress.slice(0, senderAddressLength) }

    for (let i = 0; i < senderAddressLength; i++) senderAddress += ' '
    newLine += senderAddress

    // 21. Complément d'adresse de l'Expéditeur
    // Longueur = 32 Caractères
    // ----------------------------
    // A remplir si vous souhaitez faire apparaître sur le bon de transport TNT une adresse différente de celle de votre compte TNT.
    // Dans ce cas, il faut remplir correctement TOUTES les zones concernant l'Expéditeur
    // Colonne = 358
    let senderAddress2 = parsedData.senderAddress2
    const senderAddress2Length = 32 - senderAddress2.length

    if (senderAddress2Length < 0) { senderAddress2 = senderAddress2.slice(0, senderAddress2Length) }

    for (let i = 0; i < senderAddress2Length; i++) senderAddress2 += ' '
    newLine += senderAddress2

    // 22. Code Postal de l'Expéditeur
    // Longueur = 10 Caractères
    // ----------------------------
    // A remplir si vous souhaitez faire apparaître sur le bon de transport TNT une adresse différente de celle de votre compte TNT.
    // Dans ce cas, il faut remplir correctement TOUTES les zones concernant l'Expéditeur
    // Colonne = 390
    let senderPostalCode = parsedData.senderPostalCode
    const senderPostalCodeLength = 10 - senderPostalCode.length

    if (senderPostalCodeLength < 0) { senderPostalCode = senderPostalCode.slice(0, senderPostalCodeLength) }

    for (let i = 0; i < senderPostalCodeLength; i++) senderPostalCode += ' '
    newLine += senderPostalCode

    // 21. Ville de l'Expéditeur
    // Longueur = 27 Caractères
    // ----------------------------
    // A remplir si vous souhaitez faire apparaître sur le bon de transport TNT une adresse différente de celle de votre compte TNT.
    // Dans ce cas, il faut remplir correctement TOUTES les zones concernant l'Expéditeur
    // Colonne = 400
    let senderCity = parsedData.senderCity
    const senderCityLength = 27 - senderCity.length

    if (senderCityLength < 0) { senderCity = senderCity.slice(0, senderCityLength) }

    for (let i = 0; i < senderCityLength; i++) senderCity += ' '
    newLine += senderCity

    // 22. Pays de l'Expéditeur
    // Longueur = 2 Caractères
    // ----------------------------
    // A remplir si vous souhaitez faire apparaître sur le bon de transport TNT une adresse différente de celle de votre compte TNT.
    // Dans ce cas, il faut remplir correctement TOUTES les zones concernant l'Expéditeur
    // Colonne = 427
    let senderCountry = parsedData.senderCountry
    const senderCountryLength = 2 - senderCountry.length
    for (let i = 0; i < senderCountryLength; i++) senderCountry += ' '
    newLine += senderCountry

    // 23. email (FRLUCIE2617@HOTMAIL.FR) - non obligatoire
    // 61 caractères
    // Colonne = 429
    let receiverMail = parsedData.receiverMail
    const receiverMailLength = 61 - receiverMail.length
    for (let i = 0; i < receiverMailLength; i++) receiverMail += ' '
    newLine += receiverMail

    // Creating the blob file and its url
    // fileDownload(newLine, 'madexpedl.txt');

    // Creating the blob file and its url
    // let url = "data:text/plain;charset=windows-1252," + iconv.encode(Buffer.from([newLine]), 'win1252');

    const bytes = iconv.encode(newLine, 'win1252')
    var blob = new Blob([bytes], { type: 'plain/text' })
    const url = window.URL.createObjectURL(blob)

    // Creating the hyperlink and auto click it to start the download
    const link = document.createElement('a')
    link.href = url
    link.download = 'Maexpedl.txt'
    link.click()
  }).catch(err => {
    console.log('err: ', err)
  })
}
