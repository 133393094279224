import React from 'react'
import QRCode from 'qrcode.react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { RootState } from 'models/redux.model'
import { useIntl } from 'react-intl'

const QRCodeDisplay = () => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )

  let pharmacyUrl = null

  if (
    selectedPharma.pharmacy.phacil_url &&
    selectedPharma.pharmacy.phacil_url !== ''
  ) {
    pharmacyUrl = selectedPharma.pharmacy.phacil_url
  } else {
    pharmacyUrl = `https://phacil.delivery/${
      selectedPharma.pharmacy_id
    }/${selectedPharma.pharmacy.name.replace(/\s+/g, '-')}`
  }

  const handleDownload = () => {
    const qrCodeCanvas = document.getElementById(
      'qrcodePharmacy'
    ) as HTMLCanvasElement

    const link = document.createElement('a')
    link.download = `qrCode-${selectedPharma.pharmacy_id}`
    link.href = qrCodeCanvas.toDataURL()

    link.click()
  }

  return (
    <>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '2rem'
        }}
      >
        <QRCode
          id='qrcodePharmacy'
          value={pharmacyUrl}
          size={256}
          renderAs='canvas'
          imageSettings={{
            src: '/assets/icons/phacil.png',
            height: 32,
            width: 32,
            excavate: true,
            x: undefined,
            y: undefined
          }}
        />
      </span>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '1rem'
        }}
      >
        <Button onClick={handleDownload}>{f('download')}</Button>
      </span>
    </>
  )
}

export default QRCodeDisplay
