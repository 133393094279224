import React from 'react'
import { Button, Input } from 'reactstrap'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { PaginationProps } from 'models/table.model'

const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--texte-table);
    font-weight: 600;
    font-size: 0.9rem;
    button {
      margin-right: 0.5rem;
    }
    span, div {
      display: flex;
      align-items: center;
      margin: 0.5rem;
    }
    select {
      max-width: 6.7rem;
    }
`

const Pagination: React.FC<PaginationProps> = ({ onGoToPage, onNavigate, offset, pageCount, isRowsPerPage, setRowsPerPage, currentRowsPerPage }) => {
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })
  const rowsPerPage = [5, 10, 20, 50]

  return (
    <PaginationWrapper className='pagination'>
      <Button onClick={() => onNavigate(-1)} disabled={offset <= 0}>
        {f('previousPagination')}
      </Button>{' '}
      <Button onClick={() => onNavigate(+1)} disabled={offset >= pageCount - 1}>
        {f('nextPagination')}
      </Button>{' '}
      {isRowsPerPage === true && setRowsPerPage && (
        <>
          <span>{f('rowPerPage')}</span>
          <Input
            type='select'
            name='select'
            id='rowsPerPageSelect'
            onChange={(event) => {
              setRowsPerPage(parseInt(event.target.value))
            }}
          >
            {rowsPerPage.map((number, index) => {
              return (
                <option key={index} value={number} selected={currentRowsPerPage === number && true}>{number}</option>
              )
            })}

          </Input>
        </>

      )}
      <span>
        <span>{f('page')}</span>
        <strong>
          {offset + 1}{f('of')}{pageCount}
        </strong>{' '}
      </span>
      <span>
        <span>{f('goToPage')}</span>
        <Input
          type='number'
          value={offset + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            if (page < 0 || page > pageCount - 1) return
            onGoToPage(page)
          }}
          style={{ width: '100px' }}
        />
      </span>{' '}
    </PaginationWrapper>
  )
}

export default Pagination
