import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, CardHeader, CardBody } from 'reactstrap'
import { updateUser, updatePassword } from '../actions'

import SideMenu from '../components/SideMenu'
import UserForm from '../components/UserForm'
import PharmacyForm from '../components/PharmacyForm'
import PasswordForm from '../components/PasswordForm'
import Breadcrum from '../components/ui/Breadcrum'
import { RootState } from 'models/redux.model'
import { useIntl } from 'react-intl'
import UploadForm from 'components/uploadForm'
import API from '../utils/API'

import Helmet from 'react-helmet'
import { CardWrapper } from 'assets/styles/CardWrapper'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'

const UserWrapper = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: var(--gris-bleu);
  .container-fluid {
    padding: 30px 20px 60px 20px;
  }
  @media (max-width: 769px) {
    flex-direction: column;
  }
  .headerTitle {
    margin-bottom: 2rem;
    h2 {
      color: var(--texte-bleu-fonce);
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
`

const User: React.FC = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector((state: RootState) => state.user)
  const { formatMessage } = useIntl()
  const f = (id: any) => formatMessage({ id })

  const uploadImagePharmacy = async (file: File | null) => {
    const formData = new FormData()
    if (file != null) {
      formData.append('file', file)
      formData.append(
        'pharmacyId',
        userInfo.selectedPharma.pharmacy_id.toString()
      )
      try {
        const res = await API.post('/aws/pharmacy/upload/image', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        if (res.status === 200) {
          userInfo.selectedPharma.pharmacy.img_link = res.data.data.Location
          dispatch(updateUser(userInfo, f))
        }
      } catch (err: any) {
        console.log(err)
      }
    }
  }

  return (
    <UserWrapper>
      <Helmet>
        <title>{f('userMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('userMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <SideMenu parent='Informations' />
      <ContainerWrapper>
        <Row>
          <Col xs='12'>
            <Container fluid>
              <Row className='headerTitle'>
                <Col>
                  <h2>{f('myInfo')}</h2>
                  <Breadcrum />
                </Col>
              </Row>
              <CardWrapper user>
                <CardHeader>
                  <h5>{f('myInfo2')}</h5>
                </CardHeader>
                <CardBody>
                  {
                    userInfo.email != null && (
                      <UserForm
                        userInfo={userInfo}
                        onSubmitUpdate={userInfo => dispatch(updateUser(userInfo, f))}
                      />
                    )
                  }
                </CardBody>
              </CardWrapper>
              <CardWrapper user>
                <CardHeader>
                  <h5>{f('myInfo3')}</h5>
                </CardHeader>
                <CardBody>
                  {userInfo?.pharmacies?.[0]?.pharmacy != null && <PharmacyForm userInfo={userInfo} onSubmitUpdate={() => { dispatch(updateUser) }} />}
                </CardBody>
              </CardWrapper>
              <CardWrapper user>
                <CardHeader>
                  <h5>{f('changePasswd')}</h5>
                </CardHeader>
                <CardBody>
                  <PasswordForm
                    onSubmit={fields =>
                      dispatch(updatePassword(fields.password, f))}
                  />
                </CardBody>
              </CardWrapper>
              <CardWrapper user>
                <CardHeader>
                  <h5>{f('changeImage')}</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    {userInfo?.selectedPharma?.pharmacy?.img_link != null ? (
                      <>
                        <Col md='6'>
                          <UploadForm
                            onSubmit={async fields =>
                              await uploadImagePharmacy(fields.file)}
                          />
                        </Col>
                        <Col md='6' className='columnImage'>
                          <img
                            className='imagePharmacy'
                            src={userInfo.selectedPharma.pharmacy.img_link}
                            alt='loading'
                            width='200'
                          />
                        </Col>
                      </>
                    ) : (
                      <Col md='12'>
                        <UploadForm
                          onSubmit={async fields => await uploadImagePharmacy(fields.file)}
                        />
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </CardWrapper>
            </Container>
          </Col>
        </Row>
      </ContainerWrapper>
    </UserWrapper>
  )
}

export default User
