import React from 'react'
import styled from 'styled-components'
import ReactAudioPlayer from 'react-audio-player'
import API from '../../utils/API'
import moment from 'moment'
import { Button, Input } from 'reactstrap'
import Tooltip from '@material-ui/core/Tooltip'
import {
  Edit2 as EditIcon,
  RefreshCw as ActiveIcon,
  Trash as DeleteIcon

} from 'react-feather'
import { RenderCellVoicemail } from 'models/table.model'

const Voicemail = ({
  row,
  rowIndex,
  column,
  f,
  renderInputCommentary,
  selectRowId,
  formatCommentary,
  setSelectRowId,
  activeTab,
  handleActions
}: RenderCellVoicemail): JSX.Element => {
  const classNameValue = ''

  if (column.accessor === 'voicemail') {
    if (row.hasVoicemail === 1) {
      return (
        <CenteredTd key={`td${rowIndex}_${column.accessor}`}>
          <ReactAudioPlayer
            src={row.voicemail}
            controls
            onEnded={() => {
              API.post('/aircall/calls', {
                callId: row.callId,
                fullListening: true
              }).catch(err => console.log(err))
            }}
            className='audio'
          />
        </CenteredTd>
      )
    } else {
      return (
        <CenteredTd key={`td${rowIndex}_${column.accessor}`} className={classNameValue}>
          {f('noVocal')}
        </CenteredTd>
      )
    }
  } else if (column.accessor === 'createdAt') {
    return (
      <td key={`td${rowIndex}_${column.accessor}`} className='spaceDate'>
        {moment(row.createdAt).format('ddd D MMM H:mm')}
      </td>
    )
  } else if (column.accessor === 'listened') {
    return (
      <td key={`td${row.id}_${column.accessor}`} className='spaceCheckbox'>
        <Input
          addon
          id={`checkbox-enabled-${row.id}`}
          type='checkbox'
          defaultChecked={row.listened}
          onChange={(e) => {
            API.post('/aircall/calls', {
              callId: row.callId,
              listened: e.target.checked
            }).catch(err => console.log(err))
          }}
        />
      </td>
    )
  } else if (column.accessor === 'commentary') {
    return (
      <MaxWidthTd key={`td${rowIndex}_${column.accessor}`} style={{ textAlign: 'center' }}>
        {row.commentary != null
          ? (
            <div style={{ display: selectRowId != null && selectRowId === row.id ? 'none' : '' }}>
              {row.commentary.length <= 10
                ? <span>{row.commentary}</span>
                : (
                  <Tooltip enterTouchDelay={100} title={<span style={{ fontSize: '12px' }}>{row.commentary}</span>}>
                    <span>{(formatCommentary != null) && formatCommentary(row.commentary)}</span>
                  </Tooltip>
                )}
              <EditIcon
                onClick={() => {
                  (setSelectRowId != null) && setSelectRowId(row.id)
                }}
              />
            </div>
          )
          : (renderInputCommentary != null) && renderInputCommentary(row.callId, row.commentary)}
        {
          row.commentary != null && selectRowId === row.id &&
          renderInputCommentary && renderInputCommentary(row.callId, row.commentary)
        }
      </MaxWidthTd>
    )
  } else if (column.accessor === 'actions') {
    return (
      <td key={`delete_${rowIndex}`}>
        {
          activeTab === 6
            ? (
              <StyledButton
                className='icon'
                onClick={() => {
                  (handleActions != null) && handleActions(row.callId, false)
                }}
              >
                <ActiveIcon />
              </StyledButton>
            )
            : (
              <StyledButton
                className='icon'
                onClick={() => {
                  (handleActions != null) && handleActions(row.callId, true)
                }}
              >
                <DeleteIcon />
              </StyledButton>
            )
        }
      </td>
    )
  } else if (column.accessor === 'motif') {
    let motif = ''

    switch (row[column.accessor]) {
      case 'Urgence':
        motif = f('emergency')
        break
      case 'Non communiqué':
        motif = f('notCommunicated')
        break
      case 'Commercial':
        motif = f('commercial')
        break
      case 'Ordonnance':
        motif = f('prescription')
        break
      default:
        motif = f('notCommunicated')
        break
    }
    return (
      <td key={`td${rowIndex}_${column.accessor}`} style={{ minWidth: '10.5rem' }}>
        {motif}
      </td>
    )
  } else if (column.accessor === 'phoneCalling') {
    let phone = row[column.accessor]
    if (phone === 'anonymous') phone = f('anonymous')

    return (
      <td key={`td${rowIndex}_${column.accessor}`} style={{ minWidth: '10.5rem' }}>
        {phone}
      </td>
    )
  } else {
    return (
      <td key={`td${rowIndex}_${column.accessor}`}>
        {row[column.accessor]}
      </td>
    )
  }
}

const CenteredTd = styled.td`
  min-width: 10rem;
  text-align: center;
`
const MaxWidthTd = styled.td`
  max-width: 10rem;
`

const StyledButton = styled(Button)`
  background: none;
  border: none;
  color: #71748d;
`
export default Voicemail
